import { AxiosError } from "axios";
import { axiosWithToken } from "./axiosService";
import { ErrorResponse } from "../../interfaces/error";
import { toast } from "react-toastify";

export const stateSubscription = async (idPartner: number ,data: any) => {
    try {
        const response = await axiosWithToken().patch(`api/superadmin/subscription/state/${idPartner}`, data)
        return response
    } catch (error) {
        const axiosError = error as AxiosError;
        const errorResponse = axiosError?.response?.data as ErrorResponse;
        toast.error(`${errorResponse?.message}`);
    }
};

export const getSubscription = async () => {
    try {
        const response = await axiosWithToken().get(`api/superadmin/subscription`)
        return response
    } catch (error) {
        const axiosError = error as AxiosError;
        const errorResponse = axiosError?.response?.data as ErrorResponse;
        toast.error(`${errorResponse?.message}`);
    }
}