import { axiosWithToken } from "./axiosService";

export const getPeriods = async () => {
    try {
        const response = await axiosWithToken().get(`api/periodtypes`);
        return response.data;
    } catch (err) {
        console.log(err);
        throw err;
    }
}