import Dropzone from "react-dropzone";
import { useSuperAdminCreatePartnerStore } from "../../../stores/superadmin/createPartner";

export const DropZoneMandate = () => {
  const { files, setFiles } = useSuperAdminCreatePartnerStore();

  const handleDrop = (acceptedFiles: File[]) => {
    setFiles(acceptedFiles);
  };

  return (
    <div className="flex flex-col justify-start items-center">
      <Dropzone onDrop={handleDrop}>
        {({ getRootProps, getInputProps }) => (
          <div
            {...getRootProps()}
            className="w-full m-auto bg-zinc-50 border-2 border-dashed	hover:bg-zinc-200 border-zinc-100 rounded-md h-7 cursor-pointer flex flex-col items-center justify-center"
          >
            <input {...getInputProps()} />

            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              viewBox="0 0 24 24"
              fill="none"
              stroke="gray"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="feather feather-upload-cloud"
            >
              <polyline points="16 16 12 12 8 16"></polyline>
              <line x1="12" y1="12" x2="12" y2="21"></line>
              <path d="M20.39 18.39A5 5 0 0 0 18 9h-1.26A8 8 0 1 0 3 16.3"></path>
              <polyline points="16 16 12 12 8 16"></polyline>
            </svg>
          </div>
        )}
      </Dropzone>
      {files.length > 0 ? <p>{files[0]?.name}</p> : null}
    </div>
  );
};
