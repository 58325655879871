import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useUtilsStore } from "../../../../stores/Utils/selects";
import { usePartnerMissionStore } from "../../../../stores/Cac/Partner/partnerMission";
import { usePartnerRequestsStore2 } from "../../../../stores/Cac/Partner/partnerRequests";
import { formatDateForSendRequest } from "../../../../services/Utils/functionService";
import { usePartnerRequestDetailsStore } from "../../../../stores/Cac/Partner/partnerRequestDetails";
import { useEffect } from "react";

const SelectDate = () => {
  const { startDate, setStartDate } = useUtilsStore();
  const { dataMission, setDataMission } = usePartnerMissionStore();
  const { dataRequest, setDataRequest, openDetails } =
    usePartnerRequestsStore2();
  const { request } = usePartnerRequestDetailsStore();
  const location = window.location.pathname;
  const locationMission = location.includes("/mission");
  const locationRequests = location.includes("/requests");

  useEffect(() => {
    if (request?.dueDate && !startDate && openDetails) {
      const dueDateAsDate = new Date(request.dueDate);
      setStartDate(dueDateAsDate);
    }
  }, [request?.dueDate, startDate, setStartDate]);

  return (
    <DatePicker
      selected={startDate}
      placeholderText={
        locationMission && !locationRequests ? "Fin de mission" : "Date limite"
      }
      onChange={(date: any) => {
        setStartDate(date);
        if (locationMission && !locationRequests) {
          setDataMission({
            ...dataMission,
            dueDate: date,
          });
        } else {
          setDataRequest({
            ...dataRequest,
            dueDate: formatDateForSendRequest(date),
          });
        }
      }}
      className="w-full h-10 p-1 border-[1px] border-gray-300 rounded-md mr-2 shadow-md font-BarlowCondensed"
      dateFormat={"dd/MM/yyyy"}
    />
  );
};

export default SelectDate;
