import { AssignUser } from "../../interfaces/modales";
import { getCustomerName, getPartnerAssociated } from "../Api/customerService";

// Get data client name and collaborator associate from a client for modification
export const getCustomerData = async (id: number) => {

    const clientNameResponse = await getCustomerName(id);
    const clientName = clientNameResponse;
  
    const collabsUserAssignResponse = await getPartnerAssociated(id);
    const collabsUserAssign = collabsUserAssignResponse;
  
    const mappedData: { [key: number]: AssignUser } = {};
    collabsUserAssign.forEach((value: any) => {
      mappedData[value.id] = value as AssignUser;
    });
    const collabsUserAssignList = Object.values(mappedData);
  
    const checkboxValues: { [key: number]: boolean } = {};
    collabsUserAssign.forEach((value: any) => {
      checkboxValues[value.id] = true;
    });
  
    return { clientName, collabsUserAssignList, checkboxValues };
  };