import { AxiosError } from "axios";
import { axiosWithToken } from "./axiosService";
import { ErrorResponse } from "../../interfaces/error";
import { toast } from "react-toastify";

export const resetPasswordByAdmin = async (idPartner: number, user :any, idUser :number) => {
    try {
        const response = await axiosWithToken().post(`api/partner/${idPartner}/admin/user/resetpasswordbyadmin/${idUser}`, user)
        return response
    } catch (error) {
        const axiosError = error as AxiosError;
        const errorResponse = axiosError?.response?.data as ErrorResponse;
        toast.error(`${errorResponse?.message}`);
    }
}

export const getAllCustomers = async (idPartner: number) => {
    try {
        const response = await axiosWithToken().get(`api/partner/${idPartner}/admin/customers`)
        return response.data
    } catch (err) {
        console.log(err)
    }
}

export const getAllPartners = async (idPartner: number) => {
    try {
        const response = await axiosWithToken().get(`api/partner/${idPartner}/admin/users/partner`)
        return response.data
    } catch (err) {
        console.log(err)
    }
}

export const reactivateCustomer = async (idPartner: number,idCustomer :number) => {
    try {
        const response = await axiosWithToken().post(`api/partner/${idPartner}/admin/customer/open/${idCustomer}`)
        return response.data
    } catch (err) {
        console.log(err)
    }
}

export const getAllMissions = async (idPartner: number) => {
    try {
        const response = await axiosWithToken().get(`api/partner/${idPartner}/admin/mission`)
        return response.data
    } catch (err) {
        console.log(err)
    }
}

export const updateClientFile = async (idPartner: number, idCustomer: number, clientFile: any) => {
    try {
        const response = await axiosWithToken().patch(`api/partner/${idPartner}/admin/customer/${idCustomer}`, clientFile)
        return response.data
    } catch (err) {
        console.log(err)
    }
}

export const reactiveMission = async (idPartner: number, id: number) => {
    try {
        const response = await axiosWithToken().post(`api/partner/${idPartner}/admin/mission/open/${id}`)
        return response.data
    } catch (err) {
        console.log(err)
    }}

    
    export const deleteCustomer = async (idPartner: number, id: number) => {
        try {
          await axiosWithToken().delete(`api/partner/${idPartner}/admin/customer/${id}`);
          return true;
        } catch (error) {
          console.log(error);
          return false;
        }
      }

      export const deleteMissionByAdmin = async (idPartner: number, id: number) => {
        try {
          await axiosWithToken().delete(`api/partner/${idPartner}/admin/mission/${id}`);
          return true;
        } catch (error) {
          console.log(error);
          return false;
        }
      }

export const getAllManagers = async (idPartner: number) => {
    try {
        const response =  await axiosWithToken().get(`api/partner/${idPartner}/admin/users/managers`);
        return response.data;
    } catch (error) {
        console.log(error);
        throw error;
    }
}

export const getCategoriesMissions = async (idPartner: number) => {
    try {
        const response =  await axiosWithToken().get(`api/partner/${idPartner}/admin/categorymission`);
        return response.data;
    } catch (error) {
        console.log(error);
        throw error;
    }
}

export const createUserPartner = async (idPartner: number, user: any) => {
    try {
        const response = await axiosWithToken().post(`api/partner/${idPartner}/admin/user`, user);
        return response;
    } catch (error) {
        console.log(error);
        throw error;
    }
}

export const updateUserPartner = async (idPartner: number, idUser: number, user: any) => {
    try {
        const response = await axiosWithToken().patch(`api/partner/${idPartner}/admin/user/${idUser}/partner`, user)
        return response
    } catch (error) {
        console.log(error)
        throw error;
    }
  }
  
  export const updateUserCustomer = async (idPartner: number, idUser: number, user: any) => {
    try {
        const response = await axiosWithToken().patch(`api/partner/${idPartner}/admin/user/${idUser}/customer`, user)
        return response
    } catch (error) {
        console.log(error)
        throw error;
    }
  }

export const renewableMission = async (idPartner: number, idCategoryMission: number, data : any) => {

    try {
        const response = await axiosWithToken().patch(`api/partner/${idPartner}/admin/categorymission/${idCategoryMission}/renewable`, data)
        return response.data
    } catch (err) {
        console.log(err)
        throw err;
    }
}

export const deleteUser = async (idPartner: number, idUser: number) => {
    try {
        const response = await axiosWithToken().delete(`api/partner/${idPartner}/admin/user/${idUser}`);
        return response;
    } catch (error) {
        console.log(error);
        throw error;
    }
}