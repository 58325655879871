import { useState, useCallback } from "react";

type SortDirection = "asc" | "desc";

interface SortingOptions<T> {
  field: keyof T;
  direction: SortDirection;
}

const colorOrder: { [key: string]: number } = {
  green: 1,
  lightgreen: 2,
  yellow: 3,
  red: 4,
};


function useSorting<T extends Record<string, any>>(data: T[]) {
  const [sortingOptions, setSortingOptions] =
    useState<SortingOptions<T>>({ field: "", direction: "desc" });

    const sortedData = !data || data.length === 0 ? data : [...data].sort((a, b) => {
      const direction = sortingOptions.direction === "asc" ? 1 : -1;
      const field = sortingOptions.field as keyof T;

      if (field === "color") {
        return (colorOrder[a[field] as string] - colorOrder[b[field] as string]) * direction;
      }

      return a[field] > b[field] ? direction : -direction;
    });

  const handleSort = useCallback(
    (field: keyof T) => {
      setSortingOptions((options) => {
        const isSameField = options.field === field;
        const direction = isSameField
          ? options.direction === "asc"
            ? "desc"
            : "asc"
          : "asc";

        return { field, direction };
      });
    },
    []
  );

  return [sortedData, handleSort] as const;
}

export default useSorting;
