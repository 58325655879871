import { axiosWithToken, axiosWithoutToken } from "./axiosService";

export const getUsersCustomer = async () => {
    try {
        const response = await axiosWithToken().get(`api/user/customers`)
        return response.data
    } catch (err) {
        console.log(err)
        throw err;
    }
}

export const sendBackMail = async (idUser: number | undefined) => {
    try {
        const response = await axiosWithoutToken().post(`api/user/${idUser}/sendemailagain/`)
        return response.data
    } catch (err) {
        console.log(err);
        throw err;
    }
}; 

export const getNotifications = async () => {
    try {
        const response = await axiosWithToken().get(`api/user/notifications`);
        return response.data;
    } catch (err) {
        console.log(err);
        throw err;
    }
}

export const watchDocRequest = async (idDocRequest: number) => {
    const data = JSON.stringify({
        idDocRequest : idDocRequest,
    });

    try {
        const response = await axiosWithToken().post(`api/user/watchdoc`, data);
        return response.data;
    } catch (err) {
        console.log(err);
        throw err;
    }
}

export const unwatchDocRequest = async (idDocRequest: number) => {
    const data = JSON.stringify({
        idDocRequest : idDocRequest,
    });

    try {
        const response = await axiosWithToken().post(`api/user/unwatchdoc`, data);
        return response.data;
    } catch (err) {
        console.log(err);
        throw err;
    }
}

export const verifyMfa = async (code: string, email: string, password: string) => {
    const data = JSON.stringify({
        token : code,
        eMail : email,
        password: password,
    });

    try{
        const response = await axiosWithToken().post(`api/user/verifytotp`, data);
        return response;
    } catch (err) {
        console.log(err);
        throw err;
    }
}

export const configMfa = async () => {
    try {
        const response = await axiosWithToken().patch(`api/user/2fatotp/set`);
        return response;
    } catch (err) {
        console.log(err);
        throw err;
    }
}

export const verifytotpForDesactivated = async (code : string) => {
    const data = JSON.stringify({
        token: code,
    });
    try {
        const response = await axiosWithToken().patch(`api/user/2fatotp/unset`, data);
        return response;
    } catch (err) {
        console.log(err);
        throw err;
    }
}

export const enableMfa = async (code: string) => {
    const data = JSON.stringify({
        token : code,
    });
    try {
        const response = await axiosWithToken().patch(`api/user/2fatotp/enable`, data);
        return response;
    } catch (err) {
        console.log(err);
        throw new Error;
    }
}

export const userClientAssignment = async () => {
    try {
        const response = await axiosWithToken().get(`/api/user/customers`);
        return response.data;
    } catch (err) {
        console.log(err);
        throw new Error;
    }
}

export const userPartnerAssignment = async () => {
    try {
        const response = await axiosWithToken().get(`/api/user/partners`);
        return response.data;
    } catch (err) {
        console.log(err);
        throw new Error;
    }
}

export const getUserDashboardDataCount = async () => {
    try {
        const response = await axiosWithToken().get(`/api/user/dashboard/datas`);
        return response.data;
    } catch (err) {
        console.log(err);
        throw new Error;
    }
}

export const getInfoDashboardNbOfRequest = async () => {
    try {
        const response = await axiosWithToken().get(`/api/user/dashboard/statistics`);
        return response.data;
    } catch (err) {
        console.log(err);
        throw new Error;
    }
}