import React from "react";
import useModal from "../../../hooks/useModal";
import Trash from "../../../assets/icons/trash.svg";
import DeleteUser from "../Modales/DeleteUser";
import Send from "../../../assets/icons/send.svg";
import { sendBackMail } from "../../../services/Api/userService";
import { useServiceStore } from "../../../stores/admin/service";
import TabsForModify from "../Layout/TabsForModify";
import { usePartnerStore } from "../../../stores/admin/users";
import { showToastSuccess } from "../../../services/Toasts/toasts";
import { dateFormat } from "../../../services/Utils/functionService";

const CustomerAdminTable = () => {
  // Store
  const { edit } = useServiceStore();
  const {
    usersCustomer,
    editingUserId,
    setEditingUserId,
    updatedUser,
    setUpdatedUser,
  } = usePartnerStore();

  // Hook
  const { modalContent, isModalOpen, openModal, closeModal } = useModal();

  // Function to send back mail to user
  const handleSendBack = (idUser: number) => {
    sendBackMail(idUser)
      .then(() => showToastSuccess("L'email a bien été envoyé à l'utilisateur"))
      .catch((err) => console.log(err));
  };

  return (
    <div className="h-full w-full rounded-md flex">
      {isModalOpen ? modalContent : null}
      <TabsForModify />
      <div className="flex flex-col w-full h-full font-BarlowCondensed">
        <div className="w-full m-auto bg-nxm-red  border-2 py-2 border-zinc-100 grid grid-cols-8 grid-rows-1 rounded-t-md font-BarlowCondensed text-xl text-white">
          <div className="col-start-1 col-end-3 pl-2">Nom</div>
          <div className="col-start-3 col-end-4">Prénom</div>
          <div className="col-start-4 col-end-6">Email</div>
          <div className="col-start-6 col-end-7">Titre</div>
          <div className="col-start-7 col-end-8">Dernière connexion</div>
          <div className="col-start-8 col-end-9"></div>
        </div>
        <div className="w-full h-full overflow-y-scroll bg-white">
          {usersCustomer?.map((user: any) => (
            <div
              key={user?.id}
              className={
                editingUserId === user?.id && edit
                  ? "w-full bg-gray-200 border-b-2 border-zinc-100 m-auto grid grid-cols-8 h-auto 2xl:text-xl text-md font-light py-2"
                  : "w-full hover:bg-nxm-lightBlue hover:bg-opacity-20 border-b-2 border-zinc-100 m-auto grid grid-cols-8 h-auto 2xl:text-xl text-md font-light py-2"
              }
            >
              <div
                className="col-start-1 col-end-3 flex items-center"
                onClick={() => edit && setEditingUserId(user?.id)}
              >
                {editingUserId === user?.id && edit ? (
                  <input
                    type="text"
                    className="w-11/12"
                    defaultValue={user?.lastName}
                    required
                    onChange={(e) => {
                      const newUser = {
                        ...updatedUser,
                        lastName: e.target.value,
                      };
                      setUpdatedUser(newUser);
                    }}
                  />
                ) : (
                  <p className="pl-2">{user?.lastName}</p>
                )}
              </div>
              <div
                className="col-start-3 col-end-4 flex items-center ml-2"
                onClick={() => edit && setEditingUserId(user?.id)}
              >
                {editingUserId === user?.id && edit ? (
                  <input
                    type="text"
                    className="w-11/12"
                    required
                    defaultValue={user?.firstName}
                    onChange={(e) => {
                      const newUser = {
                        ...updatedUser,
                        firstName: e.target.value,
                      };
                      setUpdatedUser(newUser);
                    }}
                  />
                ) : (
                  user?.firstName
                )}
              </div>
              <div
                className="col-start-4 col-end-6 flex items-center ml-2"
                onClick={() => edit && setEditingUserId(user?.id)}
              >
                {editingUserId === user?.id && edit ? (
                  <input
                    type="text"
                    className="w-11/12"
                    required
                    defaultValue={user?.eMail}
                    onChange={(e) => {
                      const newUser = { ...updatedUser, eMail: e.target.value };
                      setUpdatedUser(newUser);
                    }}
                  />
                ) : (
                  user?.eMail
                )}
              </div>
              <div
                className="col-start-6 col-end-7 flex items-center ml-3"
                onClick={() => edit && setEditingUserId(user?.id)}
              >
                {editingUserId === user?.id && edit ? (
                  <input
                    type="text"
                    className="w-11/12"
                    defaultValue={user?.titre}
                    onChange={(e) => {
                      const newUser = { ...updatedUser, titre: e.target.value };
                      setUpdatedUser(newUser);
                    }}
                  />
                ) : (
                  user?.titre
                )}
              </div>

              <div
                className="col-start-7 col-end-8 flex items-center ml-3"
                onClick={() => edit && setEditingUserId(user?.id)}
              >
                {user?.lastConnection === null
                  ? "Jamais connecté"
                  : dateFormat(user?.lastConnection)}
              </div>

              <div className="col-start-8 col-end-9 flex items-center pt-1">
                <div className="grid grid-cols-2 gap-4">
                  <button
                    className="mr-14"
                    onClick={() => handleSendBack(user?.id)}
                  >
                    <img src={Send} alt="Renvoi" className="block" />
                  </button>
                  <button
                    className="ml-5"
                    onClick={() =>
                      openModal(
                        <DeleteUser id={user?.id} closeModal={closeModal} />
                      )
                    }
                  >
                    <img src={Trash} alt="trash" />
                  </button>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default React.memo(CustomerAdminTable);
