import { useState } from "react";
import { useRecoveryFileStore } from "../../../../stores/Utils/recoveryFIle";
import { useParams } from "react-router-dom";
import {
  requestReminderLate,
  requestReminderWaiting,
} from "../../../../services/Api/customerService";
import {
  showToastError,
  showToastSuccess,
} from "../../../../services/Toasts/toasts";

const RelaunchPopup = ({ closeModal }: any) => {
  const {
    selectedIdsRequestDeadLine,
    setSelectedIdsRequestDeadLine,
    selectedIdsRequestInProgress,
    setSelectedIdsRequestInProgress,
    setSelectedAllDeadLine,
    setSelectedAllInProgress,
  } = useRecoveryFileStore();
  const [loading, setLoading] = useState<boolean>(false);
  const { idCustomer, idMission } = useParams();

  const handleRelaunchLate = () => {
    setLoading(true);
    if (idCustomer && idMission)
      requestReminderLate(
        parseInt(idCustomer),
        parseInt(idMission),
        selectedIdsRequestDeadLine
      )
        .then(() => {
          showToastSuccess("Relance envoyée avec succès");
          closeModal();
          setSelectedAllDeadLine(false);
          setSelectedAllInProgress(false);
          setLoading(false);
          setSelectedIdsRequestDeadLine([]);
        })
        .catch((error) => {
          showToastError("Erreur lors de l'envoi de la relance");
          setLoading(false);
          console.log(error);
        });
  };

  const handleRelaunchWaiting = () => {
    setLoading(true);
    if (idCustomer && idMission)
      requestReminderWaiting(
        parseInt(idCustomer),
        parseInt(idMission),
        selectedIdsRequestInProgress
      )
        .then(() => {
          setLoading(false);
          showToastSuccess("Relance envoyée avec succès");
          closeModal();
          setSelectedAllDeadLine(false);
          setSelectedAllInProgress(false);
          setSelectedIdsRequestInProgress([]);
        })
        .catch((error) => {
          showToastError("Erreur lors de l'envoi de la relance");
          setLoading(false);
          console.log(error);
        });
  };

  return (
    <>
      <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none min-w-full">
        <div className="relative w-auto md:w-1/4 my-6 mx-auto max-w-3xl min-w-[35%]">
          {/*content*/}
          <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
            {/*header*/}
            <div className="flex items-center justify-center p-5 border-b border-solid border-slate-200 rounded-t">
              <h3 className="text-base md:text-3xl text-center font-K2D font-bold">
                RELANCE
              </h3>
            </div>
            {/*body*/}
            <div className="text-sm md:text-lg text-center p-5 font-BarlowCondensed">
              Vous êtes sur le point d'envoyer un e-mail à chaque individu lié
              aux requêtes que vous avez sélectionnées. <br />
              Etes vous sur de vouloir continuer ?
            </div>
            {/*footer*/}
            <div className="flex items-center justify-end p-4 border-t border-solid border-slate-200 rounded-b font-BarlowCondensed">
              <button
                className="text-red-500 background-transparent font-bold uppercase px-6 py-2 text-xl outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                type="button"
                onClick={() => closeModal()}
              >
                Annuler
              </button>
              <button
                className={
                  !loading
                    ? "text-white bg-gradient-to-r from-teal-400 via-teal-500 to-teal-600 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-teal-300 dark:focus:ring-teal-800 font-medium rounded-lg text-lg px-5 py-2.5 text-center me-2 mb-2 uppercase  shadow hover:shadow-lg outline-none  ease-linear transition-all duration-150"
                    : `flex items-center justify-center w-32 pl-2 ml-2 py-2 text-sm font-medium text-gray-900 bg-white rounded-md border hover:bg-gray-100 hover:text-filter-green focus:z-10 focus:ring-2 focus:ring-filter-green dark:text-gray-400 border-filter-green`
                }
                type="button"
                onClick={() => {
                  if (selectedIdsRequestDeadLine.length > 0)
                    handleRelaunchLate();
                  if (selectedIdsRequestInProgress.length > 0)
                    handleRelaunchWaiting();
                }}
              >
                {!loading ? (
                  <p className="text-white">Valider</p>
                ) : (
                  <svg
                    aria-hidden="true"
                    role="status"
                    className="inline w-4 h-4 mr-3 text-gray-200 animate-spin dark:text-gray-600"
                    viewBox="0 0 100 101"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                      fill="currentColor"
                    />
                    <path
                      d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                      fill="#1C64F2"
                    />
                  </svg>
                )}
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
    </>
  );
};

export default RelaunchPopup;
