import { AxiosError } from "axios";
import {  axiosWithToken, axiosWithTokenForBlob } from "./axiosService";
import { ErrorResponse } from "../../interfaces/error";
import { toast } from "react-toastify";

export const getMandate = async (idPartnerSubscription: number) => {
    try {
        const response = await axiosWithTokenForBlob().get(`api/superadmin/partnersubscription/${idPartnerSubscription}/mandate`, { responseType: 'blob' })
        return response
    } catch (error) {
        const axiosError = error as AxiosError;
        const errorResponse = axiosError?.response?.data as ErrorResponse;
        toast.error(`${errorResponse?.message}`);
    }
}

export const postMandate = async (idPartnerSubscription: number, files: any) => {
    const uploaded = files.map((file: any) => ({ name: file.name, file }));
  
    const formData = new FormData();
  
    uploaded.forEach((file: any) => {
        formData.append('files', file.file);
      });

    try {
        const response = await axiosWithTokenForBlob().post(`api/superadmin/partnersubscription/${idPartnerSubscription}/mandate`, formData)
        return response
    } catch (error) {
        const axiosError = error as AxiosError;
        const errorResponse = axiosError?.response?.data as ErrorResponse;
        toast.error(`${errorResponse?.message}`);
    }
}

export const assignSubscription = async (data: any) => {
    try {
        const response = await axiosWithToken().post(`api/superadmin/partnersubscription`, data)
        return response
    } catch (error) {
        const axiosError = error as AxiosError;
        const errorResponse = axiosError?.response?.data as ErrorResponse;
        toast.error(`${errorResponse?.message}`);
    }
};

export const updateSubscription = async (idPartnerSubscription: number, data: any) => {
    try {
        const response = await axiosWithToken().patch(`api/superadmin/partnersubscription/${idPartnerSubscription}`, data)
        return response
    } catch (error) {
        const axiosError = error as AxiosError;
        const errorResponse = axiosError?.response?.data as ErrorResponse;
    }
}