import React, { useState } from "react";
import { resetPasswordRequest } from "../../services/Api/loginService";
import { useConnexionStore } from "../../stores/Layout/connexion";

const ModalForgotPassword = () => {
  // Store
  const { showModalForgotPassword, setShowModalForgotPassword } =
    useConnexionStore();
  const [email, setEmail] = useState("");
  const handleKeyDown = (event: React.KeyboardEvent) => {
    if (event.key === "Enter") {
      handleSubmit(event);
    }
  };

  const handleSubmit = async (event: any) => {
    event.preventDefault();

    resetPasswordRequest(email)
      .then(() => setShowModalForgotPassword(false))
      .catch((error) => console.log(error));
  };

  return (
    <>
      {showModalForgotPassword ? (
        <>
          <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none text-nxm-darkGray">
            <div className="relative w-auto md:w-4/12 my-6 mx-auto max-w-3xl xl:min-w-[25%]">
              {/*content*/}
              <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                {/*header*/}
                <div className="flex items-center justify-center p-4  border-b border-solid border-slate-200 rounded-t">
                  <h3 className="text-xl 2xl:text-3xl font-semibold text-center text-nxm-darkGray font-K2D">
                    Mot de passe oublié ?
                  </h3>
                </div>
                {/*body*/}
                <div className="w-10/12 m-auto text-base 2xl:text-xl p-5 text-nxm-darkGray font-BarlowCondensed flex flex-col items-start justify-center">
                  <label
                    htmlFor="email"
                    className="leading-relaxed font-BarlowCondensed"
                  >
                    Veuillez inscrire votre adresse mail :
                  </label>
                  <input
                    type="email"
                    className="border-b-[1px] border-nxm-blueDark w-80 h-10 p-1 focus:outline-none"
                    pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$"
                    onChange={(e) => setEmail(e.target.value)}
                    minLength={10}
                    maxLength={200}
                    name="email"
                    id="email"
                    placeholder="votre-email@exemple.com"
                    onKeyDown={handleKeyDown}
                    required
                  />
                </div>
                {/*footer*/}
                <div className="flex items-center justify-end 2xl:p-4 p-2 border-t border-solid border-slate-200 rounded-b font-BarlowCondensed">
                  <button
                    className="text-red-500 background-transparent font-bold uppercase px-6 py-2 2xl:text-lg text-base  outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
                    type="button"
                    onClick={() => setShowModalForgotPassword(false)}
                  >
                    ANNULER
                  </button>
                  <button
                    className="text-white bg-gradient-to-r from-teal-400 via-teal-500 to-teal-600 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-teal-300 dark:focus:ring-teal-800 font-medium rounded-lg 2xl:text-lg text-base  px-5 py-2.5 text-center me-2 uppercase  shadow hover:shadow-lg outline-none  ease-linear transition-all duration-150"
                    type="submit"
                    onClick={(
                      event: React.MouseEvent<HTMLButtonElement, MouseEvent>
                    ) => {
                      handleSubmit(event);
                    }}
                  >
                    ENVOYER
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
        </>
      ) : null}
    </>
  );
};

export default ModalForgotPassword;
