import { useLocation, useParams } from "react-router-dom";
import {
  deleteCustomer,
  deleteMissionByAdmin,
} from "../../../services/Api/adminService";
import { useServiceStore } from "../../../stores/admin/service";
import { showToastError } from "../../../services/Toasts/toasts";

const DeleteClientFile = ({ id, closeModal }: any) => {
  // Hook
  const location = useLocation();
  const { idPartner } = useParams();
  // Store
  const { update, setUpdate } = useServiceStore();

  const handleDeleteClientFile = async (id: number) => {
    const success = location.pathname.includes("clientfile")
      ? await deleteCustomer(Number(idPartner), id)
      : await deleteMissionByAdmin(Number(idPartner), id);

    if (success) {
      setUpdate(!update);
      closeModal();
    } else {
      closeModal();
      showToastError("La suppression a échoué !");
    }
  };

  return (
    <>
      <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
        <div className="relative w-auto md:w-1/4 my-6 mx-auto max-w-3xl min-w-[35%]">
          {/*content*/}
          <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
            {/*header*/}
            <div className="flex p-5 border-b border-solid border-slate-200 rounded-t">
              <h3 className="text-3xl w-full font-semibold text-center text-nxm-darkGray font-K2D">
                Suppression
              </h3>
            </div>
            {/*body*/}
            <div className="text-center font-light p-5 text-nxm-darkGray font-BarlowCondensed">
              Cette action supprimera définitivement le dossier client.La
              supression est instantané et irreversible. <br></br> Etes vous sûr
              de vouloir continuer ?
            </div>
            {/*footer*/}
            <div className="flex items-center justify-end p-4 border-t border-solid border-slate-200 rounded-b">
              <button
                className="text-red-500 background-transparent font-bold uppercase px-6 py-2 outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                type="button"
                onClick={() => closeModal()}
              >
                Annuler
              </button>
              <button
                className="text-white bg-gradient-to-r from-teal-400 via-teal-500 to-teal-600 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-teal-300 dark:focus:ring-teal-800 font-medium rounded-lg px-5 py-2.5 text-center me-2 mb-2 uppercase  shadow hover:shadow-lg outline-none  ease-linear transition-all duration-150"
                type="button"
                onClick={() => {
                  handleDeleteClientFile(id);
                  setTimeout(() => {
                    closeModal();
                  }, 2300);
                }}
              >
                Supprimer
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
    </>
  );
};

export default DeleteClientFile;
