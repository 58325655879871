import { useSuperAdminCreatePartnerStore } from "../../../stores/superadmin/createPartner";
import { useSuperAdminPartnerStore } from "../../../stores/superadmin/superadminpartner";
import {
  createNewPartner,
  getPartner,
  updatePartnerAddress,
  updatePartnerWithoutInvoiceAddress,
} from "../../../services/Api/superadminService";
import { DropZoneMandate } from "../services/DropZoneMandate";
import { getSubscription } from "../../../services/Api/subscriptionService";
import chevron from "../../../assets/icons/chevron-right.svg";
import { useEffect } from "react";
import { postMandate } from "../../../services/Api/partnerSuscriptionService";
import {
  showToastError,
  showToastSuccess,
} from "../../../services/Toasts/toasts";

const CreatePartner = () => {
  // Store
  const {
    name,
    setName,
    adress,
    setAdress,
    socialReason,
    setSocialReason,
    isSubscriptionEnabled,
    setIsSubscriptionEnabled,
    startDate,
    setStartDate,
    discountAmount,
    setDiscountAmount,
    discountExpirationDate,
    setDiscountExpirationDate,
    isSubscription,
    setIsSubscription,
    selectedSubscription,
    setSelectedSubscription,
    firstName,
    setFirstName,
    lastName,
    setLastName,
    email,
    setEmail,
    companyName,
    setCompanyName,
    addressLine1,
    setAddressLine1,
    addressLine2,
    setAddressLine2,
    city,
    setCity,
    postalCode,
    setPostalCode,
    stateProvinceRegion,
    setStateProvinceRegion,
    country,
    setCountry,
    openDetails,
    setOpenDetails,
    reset,
    displayDiv,
    setDisplayDiv,
    animationClass,
    setAnimationClass,
    partnerData,
    setPartnerData,
    files,
    setFiles,
  } = useSuperAdminCreatePartnerStore();

  const {
    modePartner,
    partnerId,
    setPartnerId,
    sendData,
    setSendData,
    setModePartner,
  } = useSuperAdminPartnerStore();

  const handleCreatePartner = async () => {
    createNewPartner(partnerData)
      .then((res) => {
        // Vérifier le statut de la réponse
        if (res?.status === 200) {
          // La logique pour gérer une création réussie
          if (files.length === 0) {
            setDisplayDiv(false);
            setOpenDetails(false);
            setSendData(!sendData);
            showToastSuccess("Le partenaire a bien été créé");
          } else if (
            files.length > 0 &&
            res?.data.idPartnerSubscription !== 0
          ) {
            postMandate(res?.data.idPartnerSubscription, files)
              .then(() => {
                setSendData(!sendData);
                setDisplayDiv(false);
                setOpenDetails(false);
                setSendData(!sendData);
                showToastSuccess("Le partenaire a bien été créé");
                setFiles([]);
              })
              .catch((err) => console.log(err));
          }
          reset();
        } else {
          // Gérer les cas où le statut n'est pas 200
          console.error(
            "La création du partenaire a échoué avec le statut: ",
            res?.status
          );
        }
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    if (openDetails === true) {
      setDisplayDiv(true);
      setAnimationClass("slideInTableFromRight 0.3s forwards");
    } else {
      setAnimationClass("slideOutTableToRight 0.3s forwards");
      setTimeout(() => {
        setDisplayDiv(false);
      }, 300);
    }
  }, [openDetails]);

  useEffect(() => {
    getSubscription()
      .then((res) => {
        const formattedData = res?.data.map((sub: any) => {
          return {
            value: sub.id,
            label: sub.missionLimit,
          };
        });
        setIsSubscription(formattedData);
      })
      .catch((err) => console.log(err));
  }, [openDetails]);

  // **************** EDIT MODE **************** //
  useEffect(() => {
    if (modePartner === "edit") {
      getPartner(partnerId)
        .then((res) => {
          const data = res?.data;
          setName(data.name);
          setAdress(data.address);
          setSocialReason(data.socialReason);
          setIsSubscriptionEnabled(
            data.PartnerSubscriptions[0].isSubscriptionEnabled
          );
          setDiscountAmount(data.PartnerSubscriptions[0].discountAmount);
          const selectedSubObj = isSubscription?.find(
            (sub) => sub?.value === data.PartnerSubscriptions[0].idSubscription
          );
          setSelectedSubscription(selectedSubObj?.value || 0);
          if (data.PartnerSubscriptions[0].startDate) {
            setStartDate(new Date(data.PartnerSubscriptions[0].startDate));
          } else {
            setStartDate(null);
          }
          if (data.PartnerSubscriptions[0].discountExpirationDate) {
            setDiscountExpirationDate(
              new Date(data.PartnerSubscriptions[0].discountExpirationDate)
            );
          } else {
            setDiscountExpirationDate(null);
          }
          setFirstName(data.InvoiceAddresses[0].firstName);
          setLastName(data.InvoiceAddresses[0].lastName);
          setEmail(data.InvoiceAddresses[0].email);
          setCompanyName(data.InvoiceAddresses[0].companyName);
          setAddressLine1(data.InvoiceAddresses[0].addressLine1);
          setAddressLine2(data.InvoiceAddresses[0].addressLine2);
          setCity(data.InvoiceAddresses[0].city);
          setPostalCode(data.InvoiceAddresses[0].postalCode);
          setStateProvinceRegion(data.InvoiceAddresses[0].stateProvinceRegion);
          setCountry(data.InvoiceAddresses[0].country);
        })
        .catch((err) => console.log(err));
    }
  }, [openDetails, isSubscription]);

  const handleUpdatePartner = () => {
    const dataWithoutAddressInvoice = {
      name,
      address: adress,
      socialReason,
      isSubscriptionEnabled,
      idSubscription: selectedSubscription,
      startDate,
      discountAmount,
      discountExpirationDate,
    };
    const dataWithAddressInvoice = {
      firstName,
      lastName,
      email,
      companyName,
      addressLine1,
      addressLine2,
      city,
      postalCode,
      stateProvinceRegion,
      country,
    };
    updatePartnerWithoutInvoiceAddress(partnerId, dataWithoutAddressInvoice)
      .then(() => {
        setDisplayDiv(false);
        setOpenDetails(false);
        updatePartnerAddress(partnerId, dataWithAddressInvoice)
          .then(() => {
            setPartnerId(0);
            setSendData(!sendData);
            showToastSuccess("Le partenaire a bien été modifié");
            reset();
          })
          .catch((err) => {
            console.log(err);
            showToastError(
              "Une erreur est survenue lors de la modification du partenaire"
            );
            reset();
          });
      })
      .catch((err) => console.log(err));
    if (files.length > 0 && partnerId !== 0) {
      postMandate(partnerId, files)
        .then(() => {
          setSendData(!sendData);
          setDisplayDiv(false);
          setOpenDetails(false);
          setSendData(!sendData);
          setFiles([]);
        })
        .catch((err) => console.log(err));
    }
  };

  return (
    <>
      {displayDiv && (
        <div
          className={`absolute top-0 right-0 w-full h-full bg-zinc-100 z-20 overflow-x-hidden`}
          style={{
            animation: animationClass,
          }}
        >
          <div className="flex w-full h-full overflow-x-hidden">
            <div className="w-10 h-full bg-zinc-300 flex justify-center items-start pt-4">
              <button
                className="text-white"
                onClick={() => setOpenDetails(false)}
              >
                <img src={chevron} alt="" />
              </button>
            </div>
            <div className="w-full h-full flex flex-col ">
              <form
                className="w-11/12 h-full m-auto mt-4"
                onSubmit={(e) => e.preventDefault()}
              >
                <div className="grid grid-cols-5">
                  <div className="col-start-1 col-end-2 flex flex-col text-nexum-blue">
                    <label htmlFor="" className="pr-4 pt-2">
                      Nom :{" "}
                    </label>
                    <input
                      type="text"
                      name="name"
                      id="name"
                      required
                      value={name}
                      onChange={(e) => {
                        setName(e.target.value);
                        setPartnerData("name", e.target.value);
                      }}
                      className="text-black border-[1px] rounded-md p-1"
                    />
                  </div>
                  <div className="col-start-3 col-end-4 flex flex-col text-nexum-blue">
                    <label htmlFor="" className="pr-4 pt-2">
                      Raison sociale :{" "}
                    </label>
                    <input
                      type="text"
                      id="socialReason"
                      name="socialReason"
                      required
                      value={socialReason}
                      onChange={(e) => {
                        setSocialReason(e.target.value);
                        setPartnerData("socialReason", e.target.value);
                      }}
                      className="text-black border-[1px] rounded-md p-1"
                    />
                  </div>
                  <div className="col-start-5 col-end-6 flex flex-col text-nexum-blue">
                    <label htmlFor="" className="pr-4 pt-2">
                      Adresse :{" "}
                    </label>
                    <input
                      type="text"
                      name="address"
                      id="address"
                      required
                      value={adress}
                      onChange={(e) => {
                        setAdress(e.target.value);
                        setPartnerData("address", e.target.value);
                      }}
                      className="text-black border-[1px] rounded-md p-1"
                    />
                  </div>
                  <div className="col-start-1 col-end-6 border-nexum-or my-4 border-b-[1px] "></div>
                  <div className="col-start-1 col-end-2 flex text-nexum-blue justify-start items-center">
                    <label htmlFor="isSubscriptionEnabled" className="pr-4">
                      Actif :{" "}
                    </label>
                    <input
                      type="checkbox"
                      id="isSubscriptionEnabled"
                      name="isSubscriptionEnabled"
                      className="text-black border-[1px] rounded-md p-1"
                      checked={isSubscriptionEnabled}
                      onChange={(e) => {
                        const newValue = e.target.checked;
                        setIsSubscriptionEnabled(newValue);
                        setPartnerData("isSubscriptionEnabled", newValue);
                      }}
                    />
                  </div>
                  <div className="col-start-3 col-end-4 flex flex-col text-nexum-blue">
                    <label htmlFor="" className="pr-4 pt-2">
                      Plan :{" "}
                    </label>
                    <select
                      name="plan"
                      id="plan"
                      required
                      value={selectedSubscription}
                      onChange={(e) => {
                        setSelectedSubscription(parseInt(e.target.value));
                        setPartnerData(
                          "idSubscription",
                          parseInt(e.target.value)
                        );
                      }}
                      className="text-black border-[1px] rounded-md p-1"
                    >
                      <option value="0">Choisir un plan</option>
                      {isSubscription?.map((sub) => (
                        <option key={sub.id} value={sub.value}>
                          Plan {sub.value} missions limites : {sub.label}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div className="col-start-5 col-end-6 flex flex-col text-nexum-blue">
                    <label htmlFor="" className="pr-4 pt-2">
                      Date de début :{" "}
                    </label>
                    <input
                      type="date"
                      id="startDate"
                      name="startDate"
                      required
                      defaultValue={
                        startDate instanceof Date
                          ? startDate.toISOString().substring(0, 10)
                          : ""
                      }
                      onChange={(e) => {
                        setStartDate(new Date(e.target.value));
                        setPartnerData("startDate", new Date(e.target.value));
                      }}
                      className="text-black border-[1px] rounded-md p-1"
                    />
                  </div>
                  <div className="col-start-1 col-end-2 flex flex-col text-nexum-blue mt-5">
                    <label htmlFor="" className="pr-4 pt-2">
                      Remise :{" "}
                    </label>
                    <input
                      type="number"
                      id="discountAmount"
                      name="discountAmount"
                      value={discountAmount}
                      onChange={(e) => {
                        setDiscountAmount(parseInt(e.target.value));
                        setPartnerData(
                          "discountAmount",
                          parseInt(e.target.value)
                        );
                      }}
                      className="text-black border-[1px] rounded-md p-1"
                    />
                  </div>
                  <div className="col-start-3 col-end-4 flex flex-col  text-nexum-blue mt-5">
                    <label htmlFor="" className="pr-4 pt-2">
                      Date Expiration :{" "}
                    </label>
                    <input
                      type="date"
                      id="discountExpirationDate"
                      name="discountExpirationDate"
                      defaultValue={
                        discountExpirationDate instanceof Date
                          ? discountExpirationDate
                              .toISOString()
                              .substring(0, 10)
                          : ""
                      }
                      onChange={(e) => {
                        setDiscountExpirationDate(new Date(e.target.value));
                        setPartnerData(
                          "discountExpirationDate",
                          new Date(e.target.value)
                        );
                      }}
                      className="text-black border-[1px] rounded-md p-1"
                    />
                  </div>
                  <div className="col-start-5 col-end-6 flex flex-col  text-nexum-blue mt-5">
                    <label htmlFor="" className="pr-4 pt-2">
                      Mandat :{" "}
                    </label>
                    <DropZoneMandate />
                  </div>
                  <div className="col-start-1 col-end-6 border-nexum-or my-4 border-b-[1px] "></div>
                  <div className="col-start-1 col-end-2 flex flex-col text-nexum-blue ">
                    <label htmlFor="" className="pr-4 ">
                      Prénom :{" "}
                    </label>
                    <input
                      type="text"
                      id="firstName"
                      name="firstName"
                      required
                      value={firstName}
                      onChange={(e) => {
                        setFirstName(e.target.value);
                        setPartnerData("firstName", e.target.value);
                      }}
                      className="text-black border-[1px] rounded-md p-1"
                    />
                  </div>
                  <div className="col-start-3 col-end-4 flex flex-col text-nexum-blue ">
                    <label htmlFor="" className="pr-4 ">
                      Nom :{" "}
                    </label>
                    <input
                      type="text"
                      id="lastName"
                      name="lastName"
                      required
                      value={lastName}
                      onChange={(e) => {
                        setLastName(e.target.value);
                        setPartnerData("lastName", e.target.value);
                      }}
                      className="text-black border-[1px] rounded-md p-1"
                    />
                  </div>
                  <div className="col-start-5 col-end-6 flex flex-col text-nexum-blue">
                    <label htmlFor="" className="pr-4 ">
                      Email :{" "}
                    </label>
                    <input
                      type="email"
                      id="email"
                      name="email"
                      required
                      value={email}
                      onChange={(e) => {
                        setEmail(e.target.value);
                        setPartnerData("email", e.target.value);
                      }}
                      className="text-black border-[1px] rounded-md p-1 "
                    />
                  </div>
                  <div className="col-start-1 col-end-6 border-nexum-or my-4 border-b-[1px] "></div>
                  <div className="col-start-1 col-end-6 my-2 flex text-nexum-blue justify-start items-center">
                    Adresse de facturation :{" "}
                  </div>
                  <div className="col-start-1 col-end-2 flex flex-col text-nexum-blue ">
                    <label htmlFor="" className="pr-4 pt-2">
                      Company :{" "}
                    </label>
                    <input
                      type="text"
                      id="companyName"
                      name="companyName"
                      value={companyName}
                      onChange={(e) => {
                        setCompanyName(e.target.value);
                        setPartnerData("companyName", e.target.value);
                      }}
                      className="text-black border-[1px] rounded-md p-1"
                    />
                  </div>
                  <div className="col-start-3 col-end-6 flex flex-col text-nexum-blue ">
                    <label htmlFor="" className="pr-4 pt-2">
                      Adresse 1 :{" "}
                    </label>
                    <input
                      type="text"
                      id="addressLine1"
                      name="addressLine1"
                      required
                      value={addressLine1}
                      onChange={(e) => {
                        setAddressLine1(e.target.value);
                        setPartnerData("addressLine1", e.target.value);
                      }}
                      className="text-black border-[1px] rounded-md p-1"
                    />
                  </div>
                  <div className="col-start-1 col-end-2 flex flex-col text-nexum-blue mt-2 ">
                    <label htmlFor="" className="pr-4 pt-2">
                      Ville :{" "}
                    </label>
                    <input
                      type="text"
                      id="city"
                      name="city"
                      required
                      value={city}
                      onChange={(e) => {
                        setCity(e.target.value);
                        setPartnerData("city", e.target.value);
                      }}
                      className="text-black border-[1px] rounded-md p-1"
                    />
                  </div>
                  <div className="col-start-3 col-end-6 flex flex-col text-nexum-blue mt-2 ">
                    <label htmlFor="" className="pr-4 pt-2">
                      Adresse 2 :{" "}
                    </label>
                    <input
                      type="text"
                      id="addressLine2"
                      name="addressLine2"
                      value={addressLine2}
                      onChange={(e) => {
                        setAddressLine2(e.target.value);
                        setPartnerData("addressLine2", e.target.value);
                      }}
                      className="text-black border-[1px] rounded-md p-1"
                    />
                  </div>
                  <div className="col-start-1 col-end-2 flex flex-col text-nexum-blue mt-2 ">
                    <label htmlFor="" className="pr-4 pt-2">
                      Code Postal :{" "}
                    </label>
                    <input
                      type="number"
                      id="postalCode"
                      name="postalCode"
                      required
                      value={postalCode}
                      onChange={(e) => {
                        setPostalCode(e.target.value);
                        setPartnerData("postalCode", e.target.value);
                      }}
                      className="text-black border-[1px] rounded-md p-1"
                    />
                  </div>
                  <div className="col-start-3 col-end-4 flex flex-col text-nexum-blue mt-2 ">
                    <label htmlFor="" className="pr-4 pt-2">
                      Région :{" "}
                    </label>
                    <input
                      type="text"
                      id="stateProvinceRegion"
                      name="stateProvinceRegion"
                      value={stateProvinceRegion}
                      onChange={(e) => {
                        setStateProvinceRegion(e.target.value);
                        setPartnerData("stateProvinceRegion", e.target.value);
                      }}
                      className="text-black border-[1px] rounded-md p-1"
                    />
                  </div>
                  <div className="col-start-5 col-end-6 flex flex-col text-nexum-blue mt-2 ">
                    <label htmlFor="" className="pr-4 pt-2">
                      Pays :{" "}
                    </label>
                    <input
                      type="text"
                      id="country"
                      name="country"
                      required
                      value={country}
                      onChange={(e) => {
                        setCountry(e.target.value);
                        setPartnerData("country", e.target.value);
                      }}
                      className="text-black border-[1px] rounded-md p-1"
                    />
                  </div>
                </div>

                <div className="w-full flex justify-end items-end mt-5">
                  <button
                    className="text-red-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                    type="button"
                    style={{
                      animation: animationClass,
                    }}
                    onClick={() => {
                      reset();
                      setModePartner("create");
                      setOpenDetails(false);
                    }}
                  >
                    Annuler
                  </button>
                  <button
                    className="text-white bg-gradient-to-r from-teal-400 via-teal-500 to-teal-600 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-teal-300 dark:focus:ring-teal-800 font-medium rounded-lg text-sm lg:text-lg px-5 py-2 text-center me-2  uppercase  shadow hover:shadow-lg outline-none  ease-linear transition-all duration-150"
                    type="submit"
                    onClick={() =>
                      modePartner === "create"
                        ? handleCreatePartner()
                        : handleUpdatePartner()
                    }
                  >
                    {modePartner === "create" ? "Créer" : "Modifier"}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default CreatePartner;
