import { create } from "zustand";
import { downloadLogs } from "../../services/Api/superadminService";

type SuperAdminLogsStore = {
    logs: any[];
    setLogs: (logs: any[]) => void;
    handleDownloadLogs: () => void;
}

export const useSuperAdminLogsStore = create<SuperAdminLogsStore>((set) => ({
    logs: [],
    setLogs: (logs: any[]) => set({ logs }),

    handleDownloadLogs: () => {
        downloadLogs()
          .then((response) => {
            if (response) {
              const url = window.URL.createObjectURL(new Blob([response.data]));
              const link = document.createElement("a");
              link.href = url;
              link.setAttribute("download", "logs.log");
              document.body.appendChild(link);
              link.click();
              link.remove();
              window.URL.revokeObjectURL(url);
            } else {
              console.error("La réponse est undefined");
            }
          })
          .catch((err) => console.log(err));
      }
}));