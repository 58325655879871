import { useState } from "react";

interface TooltipState {
  content: string;
  position: { x: number; y: number };
}

const useTooltip = (): [
  TooltipState,
  (content: string, event: React.MouseEvent) => void,
  () => void
] => {
  const [tooltipContent, setTooltipContent] = useState<string>("");
  const [tooltipPosition, setTooltipPosition] = useState({ x: 0, y: 0 });

  const handleMouseEnter = (content: string, event: React.MouseEvent) => {
    setTooltipContent(content);
    setTooltipPosition({ x: event.clientX, y: event.clientY });
  };

  const handleMouseLeave = () => {
    setTooltipContent("");
    setTooltipPosition({ x: 0, y: 0 });
  };

  return [
    { content: tooltipContent, position: tooltipPosition },
    handleMouseEnter,
    handleMouseLeave,
  ];
};

export default useTooltip;
