import axios from "axios";
import { showToastError } from "../Toasts/toasts";
import { logout } from "./loginService";

const backendUrl = import.meta.env.VITE_BACKEND_URL;

// This fonction is used to call the backend without token
const axiosWithoutToken = () => {
  const instance = axios.create({
    baseURL: backendUrl,
    headers: {
      "Content-Type": "application/json",
      "Access-Control-Allow-Credentials": true,
    },
  });

  instance.interceptors.request.use(
    (config) => {
      config.headers["Content-Type"] = "application/json";
      config.headers["Access-Control-Allow-Credentials"] = true;
      config.withCredentials = true;
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );

  return instance;
};

// This fonction is used to call the backend with token
const axiosWithToken = () => {
  const instance = axios.create({
    baseURL: backendUrl,
  });

    instance.interceptors.request.use(
      (config) => {
        config.headers["Content-Type"] = "application/json";
        config.headers["Access-Control-Allow-Credentials"] = true;
        config.withCredentials = true;
        return config;
      },
      (error) => {
        return Promise.reject(error);
      }
    );
  
    instance.interceptors.response.use(
      (response) => {
        return response;
      },
      (error) => {
        if (error?.response && error?.response?.status === 401 ) {
          localStorage.clear();
          window.location.href = '/';
          logout();
          showToastError("Votre session a expiré, veuillez vous reconnecter.");
        }
        return Promise.reject(error);
      }
    );

  return instance;
};

// This fonction is used to call the backend with token for blob (for download file)
const axiosWithTokenForBlob = () => {
  const instance = axios.create({
    baseURL: backendUrl,
    responseType: "blob",
  });
  
  // ... Configuration de l'intercepteur
  instance.interceptors.request.use(
    (config) => {
      config.headers["Content-Type"] = 'multipart/form-data; charset=utf-8';
      config.headers["Access-Control-Allow-Credentials"] = true;
      config.withCredentials = true;
      return config;
    },
    (error) => {
      if (error?.response && error?.response?.status === 401 ) {
        localStorage.clear();
        window.location.href = '/';
        showToastError("Votre session a expiré, veuillez vous reconnecter.");
      }
      return Promise.reject(error);
    }
  );
  return instance;
};

const axiosWithTokenForFile = () => {
  const instance = axios.create({
    baseURL: backendUrl,
  });
  
  // ... Configuration de l'intercepteur
  instance.interceptors.request.use(
    (config) => {
      config.headers["Content-Type"] = 'multipart/form-data; charset=utf-8';
      config.headers["Access-Control-Allow-Credentials"] = true;
      config.withCredentials = true;
      return config;
    },
    (error) => {
      if (error?.response && error?.response?.status === 401 ) {
        localStorage.clear();
        window.location.href = '/';
        showToastError("Votre session a expiré, veuillez vous reconnecter.");
      }
      return Promise.reject(error);
    }
  );
  return instance;
}

export { axiosWithToken, axiosWithoutToken, axiosWithTokenForBlob, axiosWithTokenForFile };
