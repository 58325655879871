import Dropzone from "react-dropzone";
import {
  showToastError,
  showToastSuccess,
} from "../../../../services/Toasts/toasts";
import { useParams } from "react-router-dom";
import { usePartnerWorkingFolderStore } from "../../../../stores/Cac/Partner/partnerWorkingFolder";
import {
  deleteFileWorkingFolder,
  postAccessWorkingFolder,
  postWorkingFolderFile,
} from "../../../../services/Api/customerService";
import upload from "../../../../assets/icons/upload-cloud.svg";
import { nameFileWithoutPath } from "../../../../services/Utils/functionService";
import x from "../../../../assets/icons/x.svg";
import verify from "../../../../assets/icons/verifie.png";
import { useLoaderFile } from "../../../../stores/Utils/loaderFile";
import LoadingUploadFile from "../Utils/LoadingUploadFile";

const TableAndUploadFile = () => {
  const { idCustomer, idWorkingFolder } = useParams();
  const { updateWorkingFolder, setUpdateWorkingFolder, workingFolderDetails } =
    usePartnerWorkingFolderStore();
  const { setPercent, isLoading, setIsLoading } = useLoaderFile();

  const handleUploadClick = async (files: File[]) => {
    setIsLoading(true);
    try {
      await postWorkingFolderFile(
        Number(idCustomer),
        Number(idWorkingFolder),
        files,
        (progressEvent) => {
          const percentCompleted = Math.round(
            (progressEvent.loaded * 100) / progressEvent.total
          );
          setPercent(percentCompleted);
        }
      );
      setUpdateWorkingFolder(!updateWorkingFolder);
      setIsLoading(false);
      showToastSuccess("Fichier téléchargé avec succès");
      setPercent(0);
    } catch (error) {
      showToastError("Format accepté : zip");
      console.log(error);
    }
  };

  const handleDrop = (acceptedFiles: File[]) => {
    handleUploadClick(acceptedFiles);
  };

  const handleDeleteFile = () => {
    deleteFileWorkingFolder(Number(idCustomer), Number(idWorkingFolder))
      .then(() => {
        setUpdateWorkingFolder(!updateWorkingFolder);
        showToastSuccess("Fichier supprimé avec succès");
      })
      .catch(() => showToastError("Erreur lors de la suppression du fichier"));
  };

  const handleSendAccess = () => {
    postAccessWorkingFolder(Number(idCustomer), Number(idWorkingFolder))
      .then(() => {
        setUpdateWorkingFolder(!updateWorkingFolder);
        showToastSuccess("Accès envoyé avec succès");
      })
      .catch(() => showToastError("Erreur lors de l'envoi de l'accès"));
  };

  return (
    <div className="w-full bg-white rounded-md shadow-md h-full mb-20 pt-10 flex flex-col justify-center items-center">
      {workingFolderDetails?.workingFolder?.filePath && !isLoading ? (
        <div className="h-3/5 flex justify-center items-center">
          <img src={verify} alt="verify" className="w-44 h-44" />
        </div>
      ) : !workingFolderDetails?.workingFolder?.filePath && !isLoading ? (
        <Dropzone onDrop={handleDrop} multiple={false}>
          {({ getRootProps, getInputProps }) => (
            <div
              {...getRootProps()}
              className="w-10/12 m-auto bg-zinc-300 border-2 border-dashed hover:bg-zinc-200 border-zinc-100 rounded-md h-4/5 mb-5 cursor-pointer flex flex-col items-center justify-center"
            >
              <input {...getInputProps()} />
              <p className="text-center text-xl text-gray-500 hover:text-gray-800 font-BarlowCondensed">
                Glissez et déposez un fichier ici, <br></br> ou cliquez pour
                sélectionner un fichier
              </p>
              <img src={upload} alt="upload" className="w-44 h-44 mt-10" />
            </div>
          )}
        </Dropzone>
      ) : !workingFolderDetails?.workingFolder?.filePath && isLoading ? (
        <div className="flex justify-center items-center w-10/12 m-auto">
          <LoadingUploadFile />
        </div>
      ) : null}

      <div className="w-10/12 m-auto font-BarlowCondensed text-lg text-nxm-darkGray mb-5 flex flex-col justify-start items-center">
        <div className="flex justify-start items-center mb-5">
          <p className="font-bold mr-4">Fichier : </p>{" "}
          {workingFolderDetails?.workingFolder?.filePath
            ? nameFileWithoutPath(workingFolderDetails?.workingFolder?.filePath)
            : "Aucun fichier n`a été téléchargé"}
          {workingFolderDetails?.workingFolder?.filePath ? (
            <button
              className="font-bold ml-4 h-5 w-5 "
              onClick={handleDeleteFile}
            >
              <img src={x} alt="croix" />
            </button>
          ) : null}
        </div>
        <p className="font-thin ">
          En cliquant sur "Envoyer", un email sera envoyé à chaque utilisateur
          de ce dossier de travail pour leur donner accès au fichier.
        </p>
      </div>
      <div className="w-10/12 m-auto flex justify-end items-center">
        {workingFolderDetails?.workingFolder?.filePath ? (
          <button
            onClick={handleSendAccess}
            className="text-white bg-gradient-to-r from-teal-400 via-teal-500 to-teal-600 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-teal-300 dark:focus:ring-teal-800 font-medium rounded-lg text-lg px-5 py-2.5 text-center me-2 mb-2 uppercase  shadow hover:shadow-lg outline-none  ease-linear transition-all duration-150"
          >
            Envoyer
          </button>
        ) : null}
      </div>
    </div>
  );
};

export default TableAndUploadFile;
