import { useEffect } from "react";
import { useTokenContext } from "../../../context/tokenContext";
import { useNavigate, useParams } from "react-router-dom";
import {
  getMissions,
  getWorkingFolders,
} from "../../../services/Api/customerService";
import PartnerMissionTable from "../../../components/Cac/Partner/Mission/PartnerMissionTable";
import StatusRequest from "../../../components/Cac/Customer/Request/StatusRequest";
import Return from "../../../assets/icons/return.svg";
import folder from "../../../assets/icons/folderBlue.svg";
import { usePartnerMissionStore } from "../../../stores/Cac/Partner/partnerMission";
import PartnerModalCreateUpdateMission from "../../../components/Cac/Partner/Mission/PartnerModalCreateUpdateMission";
import PartnerModalArchiveMission from "../../../components/Cac/Partner/Mission/PartnerModalArchiveMission";
import PopupLimitMission from "../../../components/Cac/Partner/Mission/PopupLimitMission";
import { renameFileWithoutUnderscore } from "../../../services/Utils/functionService";
import { ToastContainer } from "react-toastify";
import { Notifications } from "react-push-notification";
import SideBar from "../../../components/Layout/SideBar";
import { usePartnerWorkingFolderStore } from "../../../stores/Cac/Partner/partnerWorkingFolder";
import CreateWorkingFolder from "../../../components/Cac/Partner/WorkingFolder/CreateWorkingFolder";

const PartnerMissions = () => {
  // Context
  const { roleId } = useTokenContext();

  // Hooks
  const navigate = useNavigate();
  const { idPartner, idCustomer } = useParams();

  // Store
  const {
    missions,
    setMissions,
    choiceModal,
    setChoiceModal,
    errorLimitMission,
    updateMission,
  } = usePartnerMissionStore();
  const { choiceModalWF, setChoiceModalWF, workingFolder, setWorkingFolder } =
    usePartnerWorkingFolderStore();

  // Function for get missions
  useEffect(() => {
    getMissions(Number(idCustomer))
      .then((data) => setMissions(data.missions))
      .catch(() => console.log("error"));
    getWorkingFolders(Number(idCustomer))
      .then((data) => setWorkingFolder(data[0]))
      .catch(() => console.log("error"));
  }, [idCustomer, choiceModal, updateMission]);

  return (
    <div className="w-screen h-screen bg-background bg-no-repeat bg-cover bg-center bg-fixed bg-opacity-50 flex justify-start items-start">
      <ToastContainer position="top-center" />
      <SideBar />
      <Notifications />
      <div className="flex flex-col justify-start items-center w-10/12 h-screen">
        <div className="w-11/12 flex justify-start sm:justify-between items-center my-5">
          <h2 className="my-2 text-nxm-darkGray font-light 2xl:text-3xl text-xl mr-5 ml-1 font-K2D ">
            Missions :{" "}
            <span className="font-bold">
              {missions?.length > 0
                ? renameFileWithoutUnderscore(missions[0].name)
                : ""}
            </span>
          </h2>
          <div className="flex justify-center items-center">
            {!workingFolder && (
              <button
                type="button"
                className={
                  roleId === 2
                    ? "hidden"
                    : "hidden xl:w-52 xl:h-10 xl:flex mt-5 mr-2 items-center justify-center rounded-md border-[1px] border-gray-300 p-2 hover:bg-nxm-darkBlue hover:text-white transition-all duration-500 bg-white font-K2D text-md"
                }
                onClick={() => setChoiceModalWF("create")}
              >
                + Créer un dossier travail
              </button>
            )}
            <button
              type="button"
              className={
                roleId === 2
                  ? "hidden"
                  : "hidden xl:w-44 xl:h-10 xl:flex mt-5 items-center justify-center rounded-md border-[1px] border-gray-300 p-2 hover:bg-nxm-darkBlue hover:text-white transition-all duration-500 bg-white font-K2D text-md"
              }
              onClick={() => setChoiceModal("create")}
            >
              {" "}
              + Ajouter une mission{" "}
            </button>
          </div>
          {choiceModal === "create" || choiceModal === "update" ? (
            <PartnerModalCreateUpdateMission />
          ) : choiceModalWF === "create" ? (
            <CreateWorkingFolder />
          ) : null}
          {errorLimitMission ? <PopupLimitMission /> : null}
          {choiceModal === "archive" ? <PartnerModalArchiveMission /> : null}
        </div>
        <div className="hidden md:flex justify-between items-center w-11/12 pt-2 mb-10">
          <div className="flex justify-start items-center w-auto">
            <button
              type="button"
              className="flex"
              onClick={() => navigate(-1)}
              title="Retour"
            >
              <img src={Return} alt="return" />
            </button>
            {workingFolder !== undefined ? (
              <button
                className="bg-white rounded-md w-auto p-2 shadow-lg ml-5 flex justify-start items-center"
                onClick={() =>
                  navigate(
                    `/partner/${idPartner}/customers/${idCustomer}/workingfolder/${workingFolder?.id}`
                  )
                }
              >
                <img src={folder} alt="dossier" className="w-5 h-5 mr-2" />
                <p className="text-nxm-darkGray text-sm lg:text-md">
                  Dossier de travail :{" "}
                  <span className="font-bold">
                    {workingFolder?.CategoryMission?.name} -{" "}
                    {workingFolder?.exercice}
                  </span>
                </p>
              </button>
            ) : null}
          </div>
          <div className="flex sm:flex-row flex-col items-center justify-center">
            <StatusRequest />
          </div>
        </div>
        <div className="w-11/12 border-2 border-zinc-100 h-[90%] md:h-[75%] rounded-md bg-white mb-20">
          <PartnerMissionTable />
        </div>
      </div>
    </div>
  );
};

export default PartnerMissions;
