import { ChangeEvent, useEffect, useRef, useState } from "react";
import { handleUploadFiles } from "../../../../services/Api/requestService";
import Delete from "../../../../assets/icons/x.svg";
import { usePartnerRequestsStore2 } from "../../../../stores/Cac/Partner/partnerRequests";
import SelectCategories from "../Utils/SelectCategories";
import SelectDate from "../Utils/SelectDate";
import { useUtilsStore } from "../../../../stores/Utils/selects";
import {
  createRequest,
  getUsersCustomerToMission,
} from "../../../../services/Api/customerService";
import { useParams } from "react-router-dom";
import {
  showToastError,
  showToastSuccess,
} from "../../../../services/Toasts/toasts";

const ModalCreateRequest = () => {
  // Store
  const {
    choiceModal,
    setChoiceModal,
    usersMissionAssign,
    setUsersMissionAssign,
    checkboxValues,
    setCheckboxValues,
    dataRequest,
    setDataRequest,
    comment,
    setComment,
    updateRequest,
    setUpdateRequest,
    resetRequest,
  } = usePartnerRequestsStore2();
  const { idCustomer, idMission } = useParams();
  const { setSelectedCategory, startDate, setStartDate } = useUtilsStore();

  // States
  const [compteur, setCompteur] = useState(255);
  const [uploadedFiles, setUploadedFiles] = useState<any>([]);

  useEffect(() => {
    if (idCustomer) {
      getUsersCustomerToMission(parseInt(idCustomer), idMission)
        .then((users) => {
          setDataRequest({
            ...dataRequest,
          });
          setUsersMissionAssign(users);
          if (users?.length === 0) {
            return;
          }

          const clientCheckboxValues: { [key: number]: boolean } = {};
          users.forEach((user: any) => {
            clientCheckboxValues[user.id] = true;
          });
          setCheckboxValues(clientCheckboxValues);
        })
        .catch((error) => console.log(error));
    }
  }, [idMission]);

  const textAreaRef = useRef(null);

  // This is for block commentary input when compteur is 0
  const handleChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
    let nouveauTexte = e.target.value;
    if (nouveauTexte.length > 255) {
      nouveauTexte = comment;
    }
    const nouveauCompteur = 255 - nouveauTexte.length;
    setComment(nouveauTexte);
    setCompteur(nouveauCompteur);
    setDataRequest({
      ...dataRequest,
      description: nouveauTexte,
    });
  };

  const handleReset = () => {
    setComment("");
    setChoiceModal("");
    setCompteur(255);
    setCheckboxValues({});
    setSelectedCategory(0);
    setStartDate(null);
    setDataRequest({
      ...dataRequest,
      description: "",
      categoryId: 0,
    });
  };

  const handleCreateRequest = async () => {
    try {
      if (startDate === null)
        return showToastError("Veuillez sélectionner une date");
      if (dataRequest.categoryId === 0)
        return showToastError("Veuillez sélectionner une catégorie");
      const dataForSend = {
        description: dataRequest.description,
        categoryId: dataRequest.categoryId,
        dueDate: dataRequest.dueDate,
        customerUsers: Object.entries(checkboxValues)
          .filter(([_, value]) => value)
          .map(([key]) => Number(key)),
      };
      // Crée la requête
      const docRequestResponse = await createRequest(
        Number(idCustomer),
        Number(idMission),
        dataForSend
      );
      let requestId: number;
      if (docRequestResponse) {
        requestId = parseInt(docRequestResponse);

        // Upload les fichiers une fois que requestId est disponible
        if (uploadedFiles && uploadedFiles.length > 0) {
          await handleUploadFiles(requestId, uploadedFiles);
        }
      }
      if (docRequestResponse) {
        resetRequest();
        setUpdateRequest(!updateRequest);
        handleReset();
        setStartDate(null);
        showToastSuccess("La requête a bien été créée !");
      }
    } catch (error) {
      handleReset();
      showToastError("Erreur lors de la création de la requête");
    }
  };

  // Function to handle file upload
  const handleFileEvent = (e: any) => {
    const choosenFile = Array.prototype.slice.call(e.target.files);
    setUploadedFiles(choosenFile);
  };

  // Function to delete a file
  const deleteFile = (fileToDelete: any) => {
    const newFiles = uploadedFiles?.filter(
      (file: any) => file !== fileToDelete
    );
    setUploadedFiles(newFiles);
  };

  const handleCheckboxChange = (value: number, checked: boolean) => {
    setCheckboxValues({ ...checkboxValues, [value]: checked });
  };

  return (
    choiceModal == "create" && (
      <>
        <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
          <div className="relative w-auto md:w-1/4 my-6 mx-auto max-w-3xl min-w-[55%]">
            {/*content*/}
            <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
              {/*header*/}
              <div className="flex items-center justify-center p-4 border-b border-solid border-slate-200 rounded-t">
                <h3 className="text-xl sm:text-3xl font-semibold font-K2D text-center text-nxm-darkGray">
                  Création d'une requête
                </h3>
              </div>
              {/*body*/}
              <div className="w-full flex flex-col items-center justify-center bg-opacity-20 bg-nxm-lightBlue">
                <div className="flex w-10/12 m-auto items-center justify-start my-5 ">
                  <div className="flex w-3/5 items-center justify-center mr-4">
                    <SelectCategories />
                  </div>
                  <div className="flex flex-col w-auto items-start justify-start ">
                    <SelectDate />
                  </div>
                </div>
                <div className="flex w-10/12 mb-5 items-start justify-start">
                  <div className="w-3/5 bg-white border-[1px] rounded-md h-56 overflow-y-scroll mr-8 shadow-md font-BarlowCondensed">
                    {usersMissionAssign.length > 0 ? (
                      usersMissionAssign?.map((user) => (
                        <div
                          key={user?.id}
                          className="flex flex-col items-start justify-start text-xs sm:text-base border-b-[1px] border-slate-200 "
                        >
                          <div className="flex justify-center items-center p-4 ">
                            <input
                              type="checkbox"
                              checked={checkboxValues[user?.id] || false}
                              onChange={() => {
                                handleCheckboxChange(
                                  user?.id,
                                  !checkboxValues[user?.id]
                                );
                              }}
                              className="mr-2 "
                            />
                            <label>
                              {user?.fullName} - {user?.eMail}
                            </label>
                          </div>
                        </div>
                      ))
                    ) : (
                      <p className="text-center italic">Aucun utilisateur</p>
                    )}
                  </div>
                  <div className="w-2/5 flex flex-col justify-start items-start font-BarlowCondensed">
                    <textarea
                      name="comment"
                      placeholder="Ajoutez une description"
                      value={comment}
                      onChange={handleChange}
                      ref={textAreaRef}
                      id="comment"
                      style={{ resize: "vertical", maxHeight: "200px" }}
                      className="w-full rounded border-[1px] border-slate-200 h-28 text-left self-start p-2 shadow-sm"
                    />
                    <div className="w-full flex justify-end items-end pt-1 pr-2">
                      <p className="text-gray-400 text-xs">
                        Il vous reste {compteur} caractère(s)
                      </p>
                    </div>
                    <div className="w-full flex flex-col mt-4">
                      <div className="flex flex-col w-">
                        <p className="pr-4">
                          Vous pouvez upload un document :{" "}
                        </p>
                        <input
                          type="file"
                          name="doc"
                          id="fileUpload"
                          multiple
                          onChange={handleFileEvent}
                          className="form-input w-full py-2 px-3 border border-gray-400 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
                        />
                      </div>
                      <div className="w-10/12 pt-2">
                        {uploadedFiles?.map((file: any, index: any) => (
                          <div className="flex pr-10" key={index}>
                            <p className="pr-2">{file.name}</p>
                            <button onClick={() => deleteFile(file)}>
                              <img
                                src={Delete}
                                alt="supprimer"
                                className="w-4 h-4"
                              />
                            </button>
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/*footer*/}
              <div className="flex items-center justify-end 2xl:p-4 p-2 border-t border-solid border-slate-200 rounded-b font-BarlowCondensed">
                <button
                  className="text-red-500 background-transparent font-bold uppercase px-6 py-2 text-lg outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
                  type="button"
                  onClick={() => handleReset()}
                >
                  Annuler
                </button>
                <button
                  className="text-white bg-gradient-to-r from-teal-400 via-teal-500 to-teal-600 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-teal-300 dark:focus:ring-teal-800 font-medium rounded-lg 2xl:text-lg text-base px-5 py-2.5 text-center me-2 mb-2 uppercase  shadow hover:shadow-lg outline-none  ease-linear transition-all duration-150"
                  type="button"
                  onClick={handleCreateRequest}
                >
                  Créer
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
      </>
    )
  );
};

export default ModalCreateRequest;
