import { create } from "zustand";

export type SuperAdminCreateUserPartnerStore = {

    firstName: string;
    setFirstName: (firstName: string) => void;

    lastName: string;
    setLastName: (lastName: string) => void;

    eMail: string;
    setEmail: (eMail: string) => void;

    titre: string;
    setTitre: (titre: string) => void;

    selectedRole: any;
    setSelectedRole: (selectedRole: any) => void;

    idPartner: number;
    setIdPartner: (idPartner: number) => void;

    roles : any[];
    setRoles: (roles: any[]) => void;

    reset: () => void;
}


export const useSuperAdminCreateUserPartnerStore = create<SuperAdminCreateUserPartnerStore>((set) => ({
    firstName: "",
    setFirstName: (firstName: string) => set({ firstName }),

    lastName: "",
    setLastName: (lastName: string) => set({ lastName }),

    eMail: "",
    setEmail: (eMail: string) => set({ eMail }),

    titre: "",
    setTitre: (titre: string) => set({ titre }),

    selectedRole: 0,
    setSelectedRole: (selectedRole: any) => set({selectedRole}),

    idPartner: 0,
    setIdPartner: (idPartner: number) => set({ idPartner }),

    roles: [],
    setRoles: (roles: any) => set({roles}),

    reset: () => set({
        firstName: "",
        lastName: "",
        eMail: "",
        titre: "",
        idPartner: 0,
        selectedRole: 0,
        roles: [],
    }),
}));