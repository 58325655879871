import { useEffect } from "react";
import { getPartnerAssociatedWithAdmin } from "../../../../services/Api/customerService";
import { useUtilsStore } from "../../../../stores/Utils/selects";
import { usePartnerMissionStore } from "../../../../stores/Cac/Partner/partnerMission";
import { useParams } from "react-router-dom";
import { usePartnerWorkingFolderStore } from "../../../../stores/Cac/Partner/partnerWorkingFolder";

const SelectManager = () => {
  const { idCustomer } = useParams();
  const { choiceModal, dataMission, setDataMission } = usePartnerMissionStore();
  const { choiceModalWF, dataWorkingFolder, setDataWorkingFolder } =
    usePartnerWorkingFolderStore();
  const { managers, setManagers, selectedManager, setSelectedManager } =
    useUtilsStore();

  useEffect(() => {
    if (idCustomer) {
      getPartnerAssociatedWithAdmin(Number(idCustomer))
        .then((data) => setManagers(data))
        .catch((error) => console.log(error));
    }
  }, [choiceModal]);

  return (
    <select
      name="managers"
      id="managers"
      onChange={(e) => {
        setSelectedManager(parseInt(e.target.value));
        {
          choiceModal !== "" && choiceModalWF === ""
            ? setDataMission({
                ...dataMission,
                managerId: parseInt(e.target.value),
              })
            : choiceModalWF === "create" && choiceModal === ""
            ? setDataWorkingFolder({
                ...dataWorkingFolder,
                managerId: parseInt(e.target.value),
              })
            : null;
        }
      }}
      value={selectedManager}
      className="w-1/4 h-10 p-1 border-[1px] border-gray-300 rounded-md mr-2 shadow-md"
    >
      {" "}
      {selectedManager === 0 && (
        <option value="">Choisir un responsable</option>
      )}
      {managers?.map((manager) => (
        <option value={manager?.id} key={manager?.id}>
          {manager?.firstName} {manager?.lastName}
        </option>
      ))}
    </select>
  );
};

export default SelectManager;
