import NavBarAdmin from "../../components/Admin/Layout/NavBarAdmin";
import PartnerAdminTable from "../../components/Admin/Table/PartnerAdminTable";
import { useEffect } from "react";
import useModal from "../../hooks/useModal";
import CreateUser from "../../components/Admin/Modales/CreateUser";
import SearchBar from "../../components/Admin/Services/SearchBar";
import { usePartnerStore } from "../../stores/admin/users";
import { useServiceStore } from "../../stores/admin/service";
import { useParams } from "react-router-dom";
import { getAllPartners } from "../../services/Api/adminService";
import { ToastContainer } from "react-toastify";
import { Notifications } from "react-push-notification";

const AdminPartner = () => {
  // Store
  const { usersPartner, setUsersPartner } = usePartnerStore();
  const { edit, update, searchTerm } = useServiceStore();
  const { idPartner } = useParams();
  // Hook
  const { modalContent, isModalOpen, openModal, closeModal } = useModal();

  // Function to display users partner filtered or not
  useEffect(() => {
    if (searchTerm !== "") {
      const filteredRequests = usersPartner.filter(
        (item: any) =>
          item?.lastName.toLowerCase().includes(searchTerm.toLowerCase()) ||
          item?.firstName.toLowerCase().includes(searchTerm.toLowerCase()) ||
          item?.eMail.toLowerCase().includes(searchTerm.toLowerCase())
      );
      setUsersPartner(filteredRequests);
    } else {
      getAllPartners(Number(idPartner))
        .then((data) => setUsersPartner(data))
        .catch((error) => console.log(error));
    }
  }, [searchTerm, update]);

  return (
    <div className="w-screen h-screen bg-background bg-no-repeat bg-cover bg-center bg-fixed bg-opacity-50 backdrop-filter backdrop-blur-lg overflow-hidden flex flex-col">
      {isModalOpen ? modalContent : null}
      <ToastContainer position="top-center" />
      <Notifications />
      <div className="flex w-screen h-screen">
        <NavBarAdmin />
        <div className="flex flex-col justify-start items-center w-full h-full pt-5">
          <div className="w-10/12">
            <h2 className="text-nxm-darkGray font-light 2xl:text-3xl text-xl font-K2D 2xl:py-5 py-2 ">
              Liste des collaborateurs :
            </h2>
            <div className="flex justify-between w-full items-center">
              <div className="my-5 w-auto">
                <SearchBar />
              </div>
              {edit ? (
                <p className="mr-36 font-BarlowCondensed">
                  Vous êtes en mode édition{" "}
                </p>
              ) : (
                <></>
              )}
              <div className="flex justify-end items-end">
                <button
                  className="text-md border-[1px] shadow-md border-gray-300 w-44 h-8 p-1 rounded-md hover:shadow-lg hover:bg-zinc-100 transition-all duration-500 bg-white font-K2D text-nxm-darkGray "
                  onClick={() =>
                    openModal(<CreateUser closeModal={closeModal} />)
                  }
                >
                  + Créer un utilisateur
                </button>
              </div>
            </div>
          </div>
          <div className="flex 2xl:h-5/6 h-[70%] w-10/12">
            <PartnerAdminTable />
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdminPartner;
