import { deleteFile } from "../../../../services/Api/documentService";
import { usePartnerRequestsStore2 } from "../../../../stores/Cac/Partner/partnerRequests";
import { showToastError } from "../../../../services/Toasts/toasts";

const ModalDeleteDoc = ({ idRequest, idDoc, closeModal }: any) => {
  // Store
  const { updateRequest, setUpdateRequest } = usePartnerRequestsStore2();

  const handleDeleteFile = async (idDoc: number) => {
    await deleteFile(idRequest, idDoc);
    setUpdateRequest(!updateRequest);
    showToastError("Le document a bien été supprimé !");
    closeModal();
  };

  return (
    <>
      <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
        <div className="relative w-auto md:w-1/4 my-6 mx-auto max-w-3xl min-w-[25%]">
          {/*content*/}
          <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
            {/*header*/}
            <div className="flex items-start justify-between p-5 border-b border-solid border-slate-200 rounded-t">
              <h3 className="text-3xl w-full font-semibold text-center">
                Suppression de document
              </h3>
              <button
                className="p-1 ml-auto bg-transparent border-0 text-black opacity-5 float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                onClick={() => closeModal()}
              >
                <span className="bg-transparent text-black opacity-5 h-6 w-6 text-2xl block outline-none focus:outline-none">
                  ×
                </span>
              </button>
            </div>
            {/*body*/}
            <div className="text-center font-light p-5">
              Etes-vous sûr de bien vouloir supprimer ce document ?
            </div>
            {/*footer*/}
            <div className="flex items-center justify-end p-6 border-t border-solid border-slate-200 rounded-b">
              <button
                className="text-red-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                type="button"
                onClick={() => closeModal()}
              >
                Annuler
              </button>
              <button
                className="bg-filter-green text-white active:bg-filter-green font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                type="button"
                onClick={() => {
                  handleDeleteFile(idDoc);
                }}
              >
                Supprimer
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
    </>
  );
};

export default ModalDeleteDoc;
