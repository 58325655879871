import logs from "../../assets/icons/logs.png";
import handshake from "../../assets/icons/handshake.png";
import { useSuperAdminPartnerStore } from "../../stores/superadmin/superadminpartner";
import { useNavigate } from "react-router-dom";

const NavBarSuperAdmin = () => {
  const { isNavbarVisible, setNavbarVisible, setPathVisible, setModePartner } =
    useSuperAdminPartnerStore();
  const navigate = useNavigate();

  return (
    <div
      className={`fixed top-24 left-0 h-auto w-[50px] transition-transform duration-300 bg-zinc-300 rounded-md z-30 ${
        isNavbarVisible ? "translate-x-0" : "translate-x-[-50px]"
      }`}
      onMouseLeave={() => setNavbarVisible(false)}
    >
      <div className="flex flex-col justify-center items-center">
        <button
          className="my-2"
          onClick={() => {
            setPathVisible("partners");
            navigate("/superadmin/dashboard");
            setModePartner("create");
          }}
        >
          <img src={handshake} alt="" className="w-8 h-8" />
        </button>
        <button
          className="my-2"
          onClick={() => {
            setPathVisible("logs");
            navigate("/superadmin/dashboard");
          }}
        >
          <img src={logs} alt="" className="w-10 h-10" />
        </button>
      </div>
    </div>
  );
};

export default NavBarSuperAdmin;
