import React, { useEffect, useState } from "react";
import useModal from "../../../hooks/useModal";
import Trash from "../../../assets/icons/trash.svg";
import Send from "../../../assets/icons/send.svg";
import DeleteUser from "../Modales/DeleteUser";
import { sendBackMail } from "../../../services/Api/userService";
import { usePartnerStore } from "../../../stores/admin/users";
import { useServiceStore } from "../../../stores/admin/service";
import TabsForModify from "../Layout/TabsForModify";
import { getRoleList } from "../../../services/Api/roleService";
import { showToastSuccess } from "../../../services/Toasts/toasts";

const PartnerAdminTable = () => {
  // Store
  const {
    idRole,
    usersPartner,
    editingUserId,
    setEditingUserId,
    updatedUser,
    setUpdatedUser,
  } = usePartnerStore();
  const { edit } = useServiceStore();
  const [roles, setRoles] = useState<any>([]);
  // Hook
  const { modalContent, isModalOpen, openModal, closeModal } = useModal();

  const handleSendBack = (idUser: number) => {
    sendBackMail(idUser)
      .then(() => showToastSuccess("L'email a bien été envoyé à l'utilisateur"))
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    getRoleList()
      .then((data) => setRoles(data))
      .catch((error) => console.log(error));
  }, [idRole]);

  return (
    <div className="h-full w-full rounded-md flex">
      {isModalOpen ? modalContent : null}
      <TabsForModify />
      <div className="flex flex-col w-full h-full font-BarlowCondensed ">
        <div className="w-full m-auto bg-nxm-red border-[1px] py-2 border-zinc-100 grid grid-cols-9 grid-rows-1 rounded-t-md text-xl text-white">
          <div className="col-start-1 col-end-3 pl-2">Nom</div>
          <div className="col-start-3 col-end-4">Prénom</div>
          <div className="col-start-4 col-end-6">Email</div>
          <div className="col-start-6 col-end-7">Titre</div>
          <div className="col-start-7 col-end-8">Rôle</div>
          <div className="col-start-8 col-end-9">Reset password</div>
          <div className="col-start-9 col-end-10"></div>
        </div>
        <div className="w-full h-full overflow-y-scroll bg-white mb-5">
          {usersPartner?.map((user: any) => (
            <div
              key={user?.id}
              className={
                editingUserId === user?.id && edit
                  ? "w-full bg-gray-200 border-b-2 border-zinc-100 m-auto grid grid-cols-9 2xl:text-xl text-md font-light h-auto py-2"
                  : "w-full hover:bg-nxm-lightBlue hover:bg-opacity-20 border-b-2 border-zinc-100 m-auto grid grid-cols-9 2xl:text-xl text-md font-light text-lg h-auto py-2"
              }
            >
              <div
                className="col-start-1 col-end-3 flex items-center "
                onClick={() => edit && setEditingUserId(user?.id)}
              >
                {editingUserId === user?.id && edit ? (
                  <input
                    type="text"
                    className="flex items-center text-start pl-2"
                    defaultValue={user?.lastName}
                    onChange={(e) => {
                      const newUser = {
                        ...updatedUser,
                        lastName: e.target.value,
                      };
                      setUpdatedUser(newUser);
                    }}
                  />
                ) : (
                  <p className="pl-2">{user?.lastName}</p>
                )}
              </div>
              <div
                className="col-start-3 col-end-4 flex items-center "
                onClick={() => edit && setEditingUserId(user?.id)}
              >
                {editingUserId === user?.id && edit ? (
                  <input
                    type="text"
                    className="w-11/12"
                    defaultValue={user?.firstName}
                    onChange={(e) => {
                      const newUser = {
                        ...updatedUser,
                        firstName: e.target.value,
                      };
                      setUpdatedUser(newUser);
                    }}
                  />
                ) : (
                  user?.firstName
                )}
              </div>
              <div
                className="col-start-4 col-end-6 flex items-center "
                onClick={() => edit && setEditingUserId(user?.id)}
              >
                {editingUserId === user?.id && edit ? (
                  <input
                    type="text"
                    className="w-11/12"
                    defaultValue={user?.eMail}
                    onChange={(e) => {
                      const newUser = { ...updatedUser, eMail: e.target.value };
                      setUpdatedUser(newUser);
                    }}
                  />
                ) : (
                  user?.eMail
                )}
              </div>
              <div
                className="col-start-6 col-end-7 flex items-center "
                onClick={() => edit && setEditingUserId(user?.id)}
              >
                {editingUserId === user?.id && edit ? (
                  <input
                    type="text"
                    className="w-11/12"
                    defaultValue={user?.titre}
                    onChange={(e) => {
                      const newUser = { ...updatedUser, titre: e.target.value };
                      setUpdatedUser(newUser);
                    }}
                  />
                ) : (
                  user?.titre
                )}
              </div>
              <div
                className="col-start-7 col-end-8 flex items-center "
                onClick={() => edit && setEditingUserId(user?.id)}
              >
                {editingUserId === user?.id && edit ? (
                  <select
                    className="text-center"
                    onChange={(e) => {
                      const newUser = {
                        ...updatedUser,
                        idRole: parseInt(e.target.value),
                      };
                      setUpdatedUser(newUser);
                    }}
                  >
                    <option value=""></option>
                    {roles?.map((role: any) => (
                      <option key={role?.id} value={role?.id}>
                        {role?.role}
                      </option>
                    ))}
                  </select>
                ) : (
                  user?.role?.role
                )}
              </div>
              <div className="col-start-8 col-end-9 flex justify-start items-center">
                <button className="" onClick={() => handleSendBack(user?.id)}>
                  <img src={Send} alt="Renvoi" className="block ml-5 h-5 w-5" />
                </button>
              </div>
              <div className="col-start-9 col-end-10 flex justify-center items-center">
                <button
                  onClick={() =>
                    openModal(
                      <DeleteUser id={user?.id} closeModal={closeModal} />
                    )
                  }
                >
                  <img src={Trash} alt="trash" className="h-5 w-5" />
                </button>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default React.memo(PartnerAdminTable);
