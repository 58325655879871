import { axiosWithToken, axiosWithTokenForBlob } from "./axiosService";

export const getPartners = async () => {
    try {
        const response = await axiosWithToken().get(`api/superadmin/partner/`)
        return response
    } catch (error) {
        throw error
    }
}

export const deletePartner = async (idPartner: number) => {
    try {
        const response = await axiosWithToken().delete(`api/superadmin/partner/${idPartner}`)
        return response
    } catch (error) {
        throw error
    }
}

export const getPartner = async (idPartner: number) => {
    try {
        const response = await axiosWithToken().get(`api/superadmin/partner/${idPartner}`)
        return response
    } catch (error) {
        throw error
    }
}

export const createInvoiceAddress = async (idPartner: number, data: any) => {
    try {
        const response = await axiosWithToken().post(`api/superadmin/partner/${idPartner}/invoiceaddress`, data)
        return response
    } catch (error) {
        throw error
    }
}

export const getInvoices = async (idPartner: number) => {
    try {
        const response = await axiosWithToken().get(`api/superadmin/partner/${idPartner}/invoices`)
        return response
    } catch (error) {
        throw error
    }
}

export const getLogs = async () => {
    try {
        const response = await axiosWithToken().get(`api/superadmin/logs/read`)
        return response
    } catch (error) {
        throw error
    }
}

export const downloadLogs = async () => {
    try {
        const response = await axiosWithTokenForBlob().get(`api/superadmin/logs/download`, { responseType: 'blob' })
        return response
    }
    catch (error) {
        throw error
    }
}

export const createNewPartner = async (data: any) => {
    try {
        const response = await axiosWithToken().post(`api/superadmin/partner`, data)
        return response
    } catch (error) {
        throw error
    }
}

export const updatePartnerWithoutInvoiceAddress = async (idPartner: number, data: any) => {
    try {
        const response = await axiosWithToken().patch(`api/superadmin/partner/${idPartner}`, data)
        return response
    } catch (error) {
        throw error
    }
}

export const updatePartnerAddress = async (idPartner: number, data: any) => {
    try {
        const response = await axiosWithToken().patch(`api/superadmin/partner/${idPartner}/invoiceaddress`, data)
        return response
    } catch (error) {
        throw error
    }
}

export const createUserPartner = async (data: any) => {
    try {
        const response = await axiosWithToken().post(`api/superadmin/user/partner`, data)
        return response
    } catch (error) {
        throw error
    }
}