import { useNavigate } from "react-router-dom";
import Logo from "../../assets/logos/logoNexum.png";

const LegalNotice = () => {
  const navigate = useNavigate();
  return (
    <div className="w-screen flex flex-col items-center justify-center overscroll-x-none">
      <img
        src={Logo}
        alt="logo NEXUM"
        className="w-24 h-36 cursor-pointer py-5"
        onClick={() => navigate(-1)}
      />
      <div className="w-full bg-gray-200 h-32 flex flex-col items-center justify-center">
        <h1 className="text-lg text-center font-extralight">
          <span className="font-bold text-3xl">
            Déclaration de confidentialité de Nexum
          </span>{" "}
          <br /> en date du 23/02/2023
        </h1>
      </div>

      <div className="w-10/12 flex flex-col items-center justify-start">
        <p className="w-10/12 py-10 text-justify">
          NEXUM s'engage à protéger la confidentialité de ses utilisateurs et à
          respecter les réglementations en vigueur concernant la protection des
          données. Cette Déclaration de confidentialité décrit les données que
          nous collectons, la manière dont nous les utilisons et les mesures que
          nous prenons pour assurer leur protection.
        </p>
        <h3 className="w-10/12 text-xl text-nxm-darkBlue font-bold">
          1. Collecte des données
        </h3>

        <p className="w-10/12 py-10 text-justify">
          Nous collectons les informations suivantes lorsque vous utilisez notre
          site web :<br></br> - Informations que vous fournissez lors de la
          création d'un compte ou de la mise à jour de votre profil (nom,
          adresse e-mail, numéro de téléphone, etc.).<br></br> - Informations
          sur les transactions et l'utilisation de notre plateforme (échanges de
          documents, activités de monitoring, etc.).
          <br></br> - Données de connexion et de navigation (adresse IP, type de
          navigateur, pages visitées, etc.).
        </p>

        <h3 className="w-10/12 text-xl text-nxm-darkBlue font-bold">
          2. Utilisation des données
        </h3>

        <p className="w-10/12 py-8 text-justify">
          Nous utilisons les informations collectées pour : <br></br> - Fournir
          et améliorer nos services. <br></br> - Personnaliser votre expérience
          utilisateur sur notre site. <br></br> - Communiquer avec vous et
          répondre à vos demandes.
          <br></br> - Analyser l'utilisation de notre site pour détecter et
          résoudre les problèmes techniques et améliorer notre offre.
        </p>

        <h3 className="w-10/12 text-xl text-nxm-darkBlue font-bold">
          3. Partage des données
        </h3>

        <p className="w-10/12 py-8 text-justify">
          Nous ne partageons pas vos données personnelles avec des tiers, sauf
          dans les cas suivants : <br></br> - Avec votre consentement préalable.
          <br></br> - Lorsqu'il est nécessaire pour répondre à une obligation
          légale ou réglementaire.<br></br> - Pour protéger les droits, la
          propriété ou la sécurité de Nexum, de ses utilisateurs ou d'autres
          personnes.
        </p>

        <h3 className="w-10/12 text-xl text-nxm-darkBlue font-bold">
          4. Conservation des données
        </h3>

        <p className="w-10/12 py-8 text-justify">
          Nous conservons vos données personnelles aussi longtemps que
          nécessaire pour remplir les objectifs décrits dans cette Déclaration
          de confidentialité, sauf si une période de conservation plus longue
          est exigée ou autorisée par la loi.
        </p>

        <h3 className="w-10/12 text-xl text-nxm-darkBlue font-bold">
          5. Sécurité des données
        </h3>

        <p className="w-10/12 py-8 text-justify">
          Nous mettons en œuvre des mesures de sécurité techniques et
          organisationnelles appropriées pour protéger vos données personnelles
          contre les accès non autorisés, les modifications, la divulgation ou
          la destruction.
        </p>

        <h3 className="w-10/12 text-xl text-nxm-darkBlue font-bold">
          6. Vos droits
        </h3>

        <p className="w-10/12 py-8 text-justify mb-20">
          En tant qu'utilisateur, vous disposez de droits concernant vos données
          personnelles, notamment : Le droit d'accéder à vos données.
        </p>
      </div>
    </div>
  );
};

export default LegalNotice;
