import NavBarAdmin from "../../components/Admin/Layout/NavBarAdmin";
import { useEffect } from "react";
import ClientFilesAdminTable from "../../components/Admin/Table/ClientFilesAdminTable";
import SearchBar from "../../components/Admin/Services/SearchBar";
import { useServiceStore } from "../../stores/admin/service";
import { useClientFileStore } from "../../stores/admin/clientFile";
import { getAllCustomers } from "../../services/Api/adminService";
import { useParams } from "react-router-dom";
import { ToastContainer } from "react-toastify";

const AdminClientFile = () => {
  const { edit, update, searchTerm } = useServiceStore();
  const { clientFiles, setClientFiles } = useClientFileStore();
  const { idPartner } = useParams();

  // Function to display users partner filtered or not
  useEffect(() => {
    if (searchTerm !== "") {
      const filteredRequests = clientFiles.filter((item: any) =>
        item?.name.toLowerCase().includes(searchTerm.toLowerCase())
      );
      setClientFiles(filteredRequests);
    } else {
      getAllCustomers(Number(idPartner))
        .then((data) => setClientFiles(data))
        .catch((error) => console.log(error));
    }
  }, [searchTerm, update]);

  return (
    <div className="w-screen h-screen bg-background bg-no-repeat bg-cover bg-center bg-fixed bg-opacity-50 backdrop-filter backdrop-blur-lg overflow-hidden flex flex-col">
      <div className="flex w-screen h-screen">
        <NavBarAdmin />
        <ToastContainer position="top-center" />
        <div className="flex flex-col justify-start items-center w-full h-full pt-5">
          <div className="w-10/12 h-full">
            <h2 className="text-nxm-darkGray font-light 2xl:text-3xl text-xl font-K2D 2xl:py-5 py-2 ">
              Liste des dossiers clients :
            </h2>
            <div className="flex justify-between w-full items-center">
              <div className="my-5 w-auto">
                <SearchBar />
              </div>
              {edit ? (
                <p className="mr-36 font-BarlowCondensed">
                  Vous êtes en mode édition{" "}
                </p>
              ) : (
                <p></p>
              )}
            </div>
            <div className="flex 2xl:h-5/6 h-[70%] w-full">
              <ClientFilesAdminTable />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdminClientFile;
