import { create } from "zustand";

type DashboardState = {
    cardsPartner: any[];
    setCardsPartner: (value: any) => void;
    
    cardsCustomer: any[];
    setCardsCustomer: (value: any) => void;

    dataCount: any;
    setDataCount: (value: any) => void;

    lastNotifications: any[];
    setLastNotifications: (value: any) => void;

    requestsInfo: any[];
    setRequestsInfo: (value: any) => void;
};

export const useDashboardStore = create<DashboardState>((set) => ({
    cardsPartner: [],
    setCardsPartner: (value: any) => set({ cardsPartner: value }),

    cardsCustomer: [],
    setCardsCustomer: (value: any) => set({ cardsCustomer: value }),

    dataCount: {},
    setDataCount: (value: any) => set({ dataCount: value }),

    lastNotifications: [],
    setLastNotifications: (value: any) => set({ lastNotifications: value }),

    requestsInfo: [],
    setRequestsInfo: (value: any) => set({ requestsInfo: value }),

}));