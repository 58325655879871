import { ToastContainer } from "react-toastify";
import SideBar from "../../../components/Layout/SideBar";
import { Notifications } from "react-push-notification";
import { useNavigate, useParams } from "react-router-dom";
import Return from "../../../assets/icons/return.svg";
import TableAndUploadFile from "../../../components/Cac/Partner/WorkingFolder/TableAndUploadFile";
import UsersAffectedWorkingFolder from "../../../components/Cac/Partner/WorkingFolder/UsersAffectedWorkingFolder";
import trash from "../../../assets/icons/trash-White.svg";
import { usePartnerWorkingFolderStore } from "../../../stores/Cac/Partner/partnerWorkingFolder";
import ModalDeleteWorkingFolder from "../../../components/Cac/Partner/WorkingFolder/ModalDeleteWorkingFolder";
import { useEffect } from "react";
import {
  getPartnerAssociated,
  getWorkingFolderData,
} from "../../../services/Api/customerService";
import AddUserWorkingFolder from "../../../components/Cac/Partner/WorkingFolder/AddUserWorkingFolder";

const PartnerWorkingFolder = () => {
  const navigate = useNavigate();
  const {
    setChoiceModalWF,
    workingFolderDetails,
    setWorkingFolderDetails,
    updateWorkingFolder,
    setAddUserModal,
    manager,
    setManager,
    collabsAssignedToClient,
    setCollabsAssignedToClient,
    checkboxValuesCollabs,
    setCheckboxValuesCollabs,
  } = usePartnerWorkingFolderStore();
  const { idCustomer, idWorkingFolder } = useParams();

  useEffect(() => {
    const fetchData = async () => {
      try {
        // Récupération des détails du dossier de travail
        const dataWF = await getWorkingFolderData(
          Number(idCustomer),
          Number(idWorkingFolder)
        );
        setWorkingFolderDetails(dataWF);

        // Définition du manager
        const manager = dataWF?.workingFolder?.partnerUsers?.find(
          (user: any) => user.isManager === true
        );
        setManager(manager);

        // Récupération des partenaires associés
        const partnerData = await getPartnerAssociated(Number(idCustomer));
        const filteredData = partnerData?.filter(
          (collab: any) => collab.id !== manager.idUser
        );

        // Mappage des données
        const mappedData: { [key: number]: any } = {};
        filteredData.forEach((assignUser: any) => {
          mappedData[assignUser.id] = assignUser;
        });
        setCollabsAssignedToClient(Object.values(mappedData));

        // Définition des valeurs des checkboxes
        const checkboxValues: { [key: number]: boolean } = {};
        Object.values(filteredData).forEach((assignUser: any) => {
          checkboxValues[assignUser.id] =
            dataWF?.workingFolder?.partnerUsers?.some(
              (user: any) => user.idUser === assignUser.id
            );
        });
        setCheckboxValuesCollabs(checkboxValues);
      } catch (error) {
        console.log("error", error);
      }
    };

    fetchData();
  }, [updateWorkingFolder]);

  return (
    <div className="w-screen h-screen bg-background bg-no-repeat bg-cover bg-center bg-fixed bg-opacity-50 backdrop-filter backdrop-blur-lg overflow-hidden flex justify-start items-start">
      <SideBar />
      <ToastContainer position="top-center" />
      <Notifications />
      <ModalDeleteWorkingFolder />
      <div className="flex flex-col justify-start items-center w-full h-screen">
        <div className="w-10/12 m-auto mt-10 flex flex-col h-full ">
          <div className="w-full flex justify-between items-center">
            <div className="flex flex-col justify-start items-start">
              <h2 className="text-nxm-darkGray font-light text-2xl 2xl:text-4xl font-K2D">
                Dossier de travail :{" "}
                <span className="font-bold">
                  {workingFolderDetails?.workingFolder?.nameCustomer}
                </span>
              </h2>
              <p className="text-nxm-darkGray mt-2 ml-1 font-BarlowCondensed text-xl">
                Responsable :{" "}
                <span className="font-bold">
                  {manager?.firstName + " " + manager?.lastName}
                </span>
              </p>
            </div>
            <button
              onClick={() => setChoiceModalWF("delete")}
              className="flex justify-center items-center w-auto px-4 h-10 bg-red-400 rounded-md font-extralight hover:bg-filter-orange shadow text-md  text-center mr-2 font-BarlowCondensed"
            >
              <img src={trash} alt="trash" className="mr-2" />
              <p className="text-white text-xl">Supprimer le dossier travail</p>
            </button>
          </div>
          <div className="hidden md:flex justify-between items-center mt-2 w-full m-auto">
            <button
              type="button"
              className="flex py-2"
              title="Retour"
              onClick={() => {
                navigate(-1);
              }}
            >
              <img src={Return} alt="return" />
            </button>
            <button
              onClick={() => setAddUserModal(true)}
              className="hidden xl:w-52 xl:h-10 xl:flex mt-5 2xl:mt-10 xl:mb-2 mr-2 items-center justify-center rounded-md border-[1px] border-gray-300 p-2 hover:bg-nxm-darkBlue hover:text-white transition-all duration-500 bg-white font-K2D text-md"
            >
              + Ajouter un accès
            </button>
          </div>
          <AddUserWorkingFolder />
          <div className="grid grid-cols-2 gap-10 h-full mb-20">
            <div className="col-start-1 col-end-2 h-full">
              <TableAndUploadFile />
            </div>
            <div className="col-start-2 col-end-3 h-full">
              <UsersAffectedWorkingFolder />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PartnerWorkingFolder;
