import { useLoaderFile } from "../../../../stores/Utils/loaderFile";

const LoadingUploadFile = () => {
  const { isLoading, percent } = useLoaderFile();
  const numDivs = Math.floor(percent / 20);

  return (
    isLoading && (
      <div className="w-full flex flex-col justify-center items-center">
        <div className="border-[1px] rounded-md border-gray-300 w-[300px] h-10 flex justify-start items-center">
          {percent > 0 &&
            Array.from({ length: numDivs }).map((_, index) => (
              <div
                key={index}
                className="border-2 rounded-md bg-green-400 p-1 h-10"
                style={{ width: `${100 / 5}%` }} // 5 divs en tout, donc chaque div fait 20% (100/5)
              ></div>
            ))}
        </div>
        <div className="w-[500px] flex justify-center items-center pt-2">
          <p className="text-xl">{percent} %</p>
        </div>
      </div>
    )
  );
};

export default LoadingUploadFile;
