import { create } from "zustand";
import { IMissionDetails } from "../../../interfaces/mission";
import { IRequest, createRequest } from "../../../interfaces/requestsInterface";

type PartnerRequestsState = {
    requests: IRequest[];
    setRequests: (requests: IRequest[]) => void;

    choiceModal: string;
    setChoiceModal: (choiceModal: string) => void;

    openDetails: boolean;
    setOpenDetails: (openDetails: boolean) => void;

    requestId: number;
    setRequestId: (requestId: number) => void;

    editStatus: boolean;
    setEditStatus: (editStatus: boolean) => void;

    requestIds: number[];
    setRequestIds: (requestIds: number[]) => void;

    updateRequest: boolean;
    setUpdateRequest: (updateRequest: boolean) => void;

    // Mission Details  
    missionData: IMissionDetails;
    setMissionData: (missionData: IMissionDetails) => void;

    usersMissionAssign: any[];
    setUsersMissionAssign: (usersMissionAssign: any[]) => void;

    checkboxValues: { [key: number]: boolean };
    setCheckboxValues: (checkboxValues: ((prevState: { [key: number]: boolean }) => { [key: number]: boolean }) | { [key: number]: boolean }) => void;
    
    // Create Request
    dataRequest: createRequest;
    setDataRequest: (dataRequest: createRequest) => void;
 
    comment: string;
    setComment: (comment: string) => void;

    displayAllRequests: boolean;
    setDisplayAllRequests: (displayAllRequests: boolean) => void;

    resetRequest: () => void;

    selectedUserToAffected: any;
    setSelectedUserToAffected: (selectedUserToAffected: any) => void;

    affectedUsers: boolean;
    setAffectedUsers: (affectedUsers: boolean) => void;

    docRequestUserAssign: any[];
    setDocRequestUserAssign: (docRequestUserAssign: any[]) => void;

    toggleRequestAssignment: (id: any) => void;
  };

export const usePartnerRequestsStore2 = create<PartnerRequestsState>((set) => ({
    requests: [],
    setRequests: (requests) => set({ requests: requests }),

    choiceModal: "",
    setChoiceModal: (choiceModal) => set({ choiceModal: choiceModal }),

    openDetails: false,
    setOpenDetails: (openDetails) => set({ openDetails: openDetails }),
    
    requestId: 0,
    setRequestId: (requestId) => set({ requestId: requestId }),

    editStatus: false,
    setEditStatus: (editStatus) => set({ editStatus: editStatus }),

    requestIds: [],
    setRequestIds: (requestIds) => set({ requestIds: requestIds }),

    updateRequest: false,
    setUpdateRequest: (updateRequest) => set({ updateRequest: updateRequest }),
    // Mission Details
    missionData: {
      id: 0,
      dateExercice: "",
      idCustomer: 0,
      categoryId: 0,
      name: "",
      closed: 0,
      dueDate: "",
      createdAt: "",
      updatedAt: "",
      manager: "",
      isRenewable: false,
      isThereALetter: false,
      updateAt: "",
    },
    setMissionData: (missionData) => set({ missionData: missionData }),

    usersMissionAssign: [],
    setUsersMissionAssign: (usersMissionAssign) => set({ usersMissionAssign: usersMissionAssign }),

    checkboxValues: {},
    setCheckboxValues: (checkboxValues) => set((prevState) => {
      if (typeof checkboxValues === 'function') {
          return { checkboxValues: checkboxValues(prevState.checkboxValues) };
      }
      return { checkboxValues };
      }),
    
    // Create Request
    dataRequest: {
      description: "",
      categoryId: 0,
      dueDate: "",
      customerUsers: []
    },
    setDataRequest: (dataRequest) => set({ dataRequest: dataRequest }),
  
    comment: "",
    setComment: (comment) => set({ comment: comment }),

    displayAllRequests: false,
    setDisplayAllRequests: (displayAllRequests) => set({ displayAllRequests: displayAllRequests }),

    resetRequest: () => set({
      checkboxValues: {},
      dataRequest: {
        description: "",
        categoryId: 0,
        dueDate: "",
        customerUsers: []
      },
      comment: "",
      choiceModal: "",
      }),

      selectedUserToAffected: { id: null, fullName: '' },
      setSelectedUserToAffected: (selectedUserToAffected) => set({ selectedUserToAffected: selectedUserToAffected }),

    affectedUsers: false,
    setAffectedUsers: (affectedUsers) => set({ affectedUsers: affectedUsers }),

    docRequestUserAssign: [],
    setDocRequestUserAssign: (docRequestUserAssign) => set({ docRequestUserAssign: docRequestUserAssign }),

    toggleRequestAssignment: (id: any) => set((state) => {
      if (state.docRequestUserAssign.includes(id)) {
        return { docRequestUserAssign: state.docRequestUserAssign.filter((item) => item !== id) };
      } else {
        return { docRequestUserAssign: [...state.docRequestUserAssign, id] };
      }
    }),
  }));