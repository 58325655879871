import {
  Bar,
  BarChart,
  Cell,
  LabelList,
  ResponsiveContainer,
  XAxis,
  YAxis,
} from "recharts";
import { yAxisTickFormatter } from "../../../../../services/Utils/functionService";

const AcceptedRate = ({ accepted, numberDocRequests }: any) => {
  const data = [
    { name: "Accepté", value: accepted?.totalAccepted },
    { name: "Avec -7 jours", value: accepted?.acceptedLessThan7Days },
    { name: "Avec +7 jours", value: accepted?.acceptedMoreThan7Days },
  ];

  return (
    <ResponsiveContainer width="80%" height="70%">
      <BarChart data={data} style={{ paddingTop: "10px" }}>
        <XAxis
          dataKey="name"
          tickFormatter={(value) => `${value}`}
          style={{ fontWeight: "100" }}
        />
        <YAxis
          domain={[0, numberDocRequests]}
          tickFormatter={yAxisTickFormatter}
          label={{
            value: "Nb de requêtes",
            angle: -90,
            position: "center",
            dx: -20,
            fontWeight: "100",
          }}
          style={{ fontWeight: "100" }}
        />
        <Bar dataKey="value" barSize={30}>
          <LabelList dataKey="value" position="top" color="black" />
          <Cell fill="#93CC87" key="Accepté" />
          <Cell fill="#F2B33D" key="Dans -7 jours" />
          <Cell fill="#CC0000" key="Dans +7 jours" />
        </Bar>
      </BarChart>
    </ResponsiveContainer>
  );
};

export default AcceptedRate;
