import React, { useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  dateFormat,
  splitMissionName,
} from "../../../services/Utils/functionService";
import ProgressBar from "../../../components/Cac/Partner/Utils/MonitoringElements/ProgressBar";
import ModalDocRequestDetails from "../../../components/Cac/Partner/Request/ModalDocRequestDetails";
import Return from "../../../assets/icons/return.svg";
import StatusRequest from "../../../components/Cac/Customer/Request/StatusRequest";
import useModal from "../../../hooks/useModal";
import PartnerRequestTable from "../../../components/Cac/Partner/Request/PartnerRequestTable";
import { useTokenContext } from "../../../context/tokenContext";
import { usePartnerRequestsStore2 } from "../../../stores/Cac/Partner/partnerRequests";
import ModalCreateRequest from "../../../components/Cac/Partner/Request/ModalCreateRequest";
import { useUtilsStore } from "../../../stores/Utils/selects";
import { useNotifsStore } from "../../../stores/Utils/notifs";
import SelectState from "../../../components/Cac/Partner/Utils/SelectState";
import {
  getDocRequests,
  getMissionDataByCustomer,
  handleExportCSV,
  handleExportTemplate,
  importService,
  updateRequestState,
} from "../../../services/Api/customerService";
import {
  showToastError,
  showToastSuccess,
} from "../../../services/Toasts/toasts";
import { ToastContainer } from "react-toastify";
import { Notifications } from "react-push-notification";
import { usePartnerMissionStore } from "../../../stores/Cac/Partner/partnerMission";
import SideBar from "../../../components/Layout/SideBar";

const PartnerRequests = () => {
  // Hooks
  const { modalContent, isModalOpen, openModal, closeModal } = useModal();
  const fileInputRef = useRef<HTMLInputElement>(null);
  const navigate = useNavigate();
  const { idPartner, idCustomer, idMission } = useParams();
  const { roleId } = useTokenContext();
  const { selectedState, setSelectedState } = useUtilsStore();
  const { notifs } = useNotifsStore();
  const { renewable } = usePartnerMissionStore();
  const {
    requests,
    setRequests,
    missionData,
    setMissionData,
    requestIds,
    setRequestIds,
    choiceModal,
    setChoiceModal,
    editStatus,
    setEditStatus,
    updateRequest,
    setUpdateRequest,
    openDetails,
    setOpenDetails,
    setRequestId,
    displayAllRequests,
    setDisplayAllRequests,
    affectedUsers,
    setAffectedUsers,
  } = usePartnerRequestsStore2();

  // States
  const [loading, setLoading] = useState<boolean>(false);
  const [loadingImport, setLoadingImport] = useState<boolean>(false);

  // Function for get the data of the mission
  useEffect(() => {
    if (idMission) {
      getMissionDataByCustomer(Number(idCustomer), Number(idMission))
        .then((data) => setMissionData(data))
        .catch((error) => console.log(error));
    }
  }, [idMission]);

  useEffect(() => {
    if (idCustomer && idMission)
      getDocRequests(parseInt(idCustomer), parseInt(idMission))
        .then((data) => setRequests(data))
        .catch((error) => console.log(error));
  }, [idMission, updateRequest, notifs]);

  useEffect(() => {
    if (openDetails === undefined || openDetails === false) {
      setRequestId(0);
    } else {
      setRequestIds([]);
      setEditStatus(false);
    }
  }, [openDetails]);

  const resetState = () => {
    setLoading(false);
    setUpdateRequest(!updateRequest);
    setRequestIds([]);
    setEditStatus(false);
    setSelectedState(0);
  };

  // Function for change state of requests
  const updateSelectedRequestStates = async () => {
    try {
      setLoading(true);

      const data = requestIds.map((requestId) => ({
        idDocRequest: requestId,
        state: selectedState,
      }));

      await updateRequestState(Number(idCustomer), Number(idMission), data);

      showToastSuccess("Statuts mis à jour !");
      resetState();
    } catch (error) {
      console.log(error);
      showToastError("Erreur lors de la mise à jour des statuts !");
      resetState();
    }
  };

  // Function for import CSV
  const handleUpload = async () => {
    if (
      fileInputRef.current &&
      fileInputRef.current.files &&
      fileInputRef.current.files.length > 0
    ) {
      const originalFile = fileInputRef.current.files[0];
      try {
        setLoadingImport(true);
        const importResult = await importService(
          Number(idCustomer),
          Number(idMission),
          originalFile
        );

        if (importResult === "success") {
          showToastSuccess("Importation réussie !");
          setUpdateRequest(!updateRequest);
          setLoadingImport(false);
        }
      } catch (error) {
        showToastError("Erreur lors de l'importation !");
        setLoadingImport(false);
        console.log(error);
      }
    }
  };

  useEffect(() => {
    if (affectedUsers) {
      setEditStatus(false);
    } else if (editStatus) {
      setAffectedUsers(false);
    }
  }, [affectedUsers, editStatus]);

  return (
    <div className="w-screen h-screen bg-background bg-no-repeat bg-cover bg-center bg-fixed bg-opacity-50 backdrop-filter backdrop-blur-lg overflow-hidden flex justify-start items-start">
      {isModalOpen ? modalContent : null}
      <SideBar />
      <ModalDocRequestDetails openModal={openModal} closeModal={closeModal} />
      <ToastContainer position="top-center" />
      <Notifications />
      <div className="flex flex-col justify-start items-center w-10/12 h-screen">
        {choiceModal === "create" ? <ModalCreateRequest /> : null}
        <div className="w-11/12 flex flex-col">
          <div className="flex justify-between items-center w-full my-5">
            <h2 className="text-nxm-darkGray font-light 2xl:text-3xl text-xl font-K2D">
              Requêtes :{" "}
              <span className="font-bold">
                {missionData?.name && splitMissionName(missionData.name)}
              </span>
            </h2>
            <div className="flex justify-center items-center">
              {roleId === 1 || roleId === 3 ? (
                <button
                  type="button"
                  className="hidden mr-2 xl:w-44 xl:h-10 mt-2 xl:flex items-center justify-center rounded-md border-[1px] border-gray-300 p-2 hover:bg-nxm-darkBlue hover:text-white transition-all duration-500 bg-nxm-red font-K2D text-md text-white"
                  onClick={() =>
                    navigate(
                      `/partner/${idPartner}/customers/${idCustomer}/missions/${idMission}/monitoring`
                    )
                  }
                >
                  Monitoring
                </button>
              ) : null}
              <button
                type="button"
                className="hidden mr-2 xl:w-44 xl:h-10 mt-2 xl:flex items-center justify-center rounded-md border-[1px] border-gray-300 p-2 hover:bg-nxm-darkBlue hover:text-white transition-all duration-500 bg-nxm-red font-K2D text-md text-white"
                onClick={() =>
                  navigate(
                    `/partner/${idPartner}/customers/${idCustomer}/missions/${idMission}/recoveryfile`
                  )
                }
              >
                Relance
              </button>
              <button
                type="button"
                className="hidden xl:w-44 xl:h-10 mt-2 lg:flex items-center justify-center rounded-md border-[1px] border-gray-300 p-2 hover:bg-nxm-darkBlue hover:text-white transition-all duration-500 bg-white font-K2D text-md"
                onClick={() => setChoiceModal("create")}
              >
                + Ajouter une requête{" "}
              </button>
            </div>
          </div>
          <div className="flex justify-between items-center w-full">
            <button
              type="button"
              className="flex py-2"
              title="Retour"
              onClick={() => {
                setOpenDetails(false);
                setRequestId(0);
                navigate(-1);
              }}
            >
              <img src={Return} alt="return" />
            </button>
            {requests.length > 0 ? (
              <div className="pt-2 flex flex-col sm:flex-row sm:items-center sm:justify-center items-start justify-start mr-24 font-BarlowCondensed">
                <ProgressBar />
              </div>
            ) : (
              <></>
            )}
          </div>
          <div className="flex justify-between items-center w-full mt-2 font-BarlowCondensed text-lg">
            <p className="font-thin italic">
              Date de fin de mission : {dateFormat(missionData?.dueDate)}
            </p>
            <StatusRequest />
          </div>
        </div>
        <div className="flex justify-between items-center w-11/12 py-2 font-BarlowCondensed">
          <div className="flex">
            <input
              type="file"
              id="fileInput"
              ref={fileInputRef}
              onChange={handleUpload}
              onClick={(
                event: React.MouseEvent<HTMLInputElement, MouseEvent>
              ) => {
                if (event.currentTarget) {
                  event.currentTarget.value = "";
                  event.currentTarget.click();
                }
              }}
              style={{ display: "none" }}
            />
            {renewable ? null : (
              <div className="flex justify-start items-center">
                <button
                  className="hidden sm:w-36 sm:h-6 text-md sm:flex items-center mr-2 justify-center rounded-sm border-[1px] border-gray-300 p-2 hover:bg-nxm-red hover:bg-opacity-70 hover:text-white transition-all duration-500 bg-white"
                  onClick={() => fileInputRef.current?.click()}
                >
                  {!loadingImport ? (
                    <p>Importer Modèle</p>
                  ) : (
                    <svg
                      aria-hidden="true"
                      role="status"
                      className="inline w-4 h-4 mr-3 text-gray-200 animate-spin dark:text-gray-600"
                      viewBox="0 0 100 101"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                        fill="currentColor"
                      />
                      <path
                        d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                        fill="#1C64F2"
                      />
                    </svg>
                  )}
                </button>
                <button
                  type="button"
                  onClick={() =>
                    handleExportCSV(Number(idCustomer), Number(idMission))
                  }
                  className="hidden sm:w-36 sm:h-6 text-md sm:flex items-center justify-center rounded-sm border-[1px] border-gray-300 p-2 hover:bg-nxm-red hover:bg-opacity-70 hover:text-white transition-all duration-500 bg-white"
                >
                  <p>Exporter Modèle</p>
                </button>
                <button
                  type="button"
                  onClick={() =>
                    handleExportTemplate(Number(idCustomer), Number(idMission))
                  }
                  className="hidden sm:w-36 sm:h-6 ml-2 text-md sm:flex items-center justify-center rounded-sm border-[1px] border-gray-300 p-2 hover:bg-nxm-red hover:bg-opacity-70 hover:text-white transition-all duration-500 bg-white"
                >
                  <p>Template</p>
                </button>
              </div>
            )}
            {renewable && (
              <button
                type="button"
                className={
                  displayAllRequests
                    ? "hidden sm:w-52 sm:h-6 text-md sm:flex items-center justify-center rounded-sm border-[1px] border-gray-300 p-2 bg-nxm-red opacity-30 hover:bg-opacity-70 hover:text-white transition-all duration-500"
                    : "hidden sm:w-52 sm:h-6 text-md sm:flex items-center justify-center rounded-sm border-[1px] border-gray-300 p-2 hover:bg-nxm-red hover:bg-opacity-70 hover:text-white transition-all duration-500 bg-white"
                }
                onClick={() => setDisplayAllRequests(!displayAllRequests)}
              >
                <p>Afficher les anciennes requêtes</p>
              </button>
            )}
          </div>
          <div className="hidden md:flex font-BarlowCondensed text-lg">
            <div className="flex justify-center items-center text-white ">
              {!affectedUsers && !editStatus ? (
                <button
                  type="button"
                  onClick={() => setAffectedUsers(!affectedUsers)}
                  className="hidden sm:w-38 sm:h-6 ml-2 text-nxm-darkGray text-sm sm:flex items-center justify-center rounded-sm border-[1px] border-gray-300 p-2 hover:bg-nxm-red hover:bg-opacity-70 hover:text-white transition-all duration-500 bg-white mr-2"
                >
                  <p>Affectation multiple</p>
                </button>
              ) : null}
              {!editStatus && !affectedUsers ? (
                <button
                  onClick={() => setEditStatus(true)}
                  className="hidden sm:w-38 sm:h-6 ml-2 text-nxm-darkGray text-sm sm:flex items-center justify-center rounded-sm border-[1px] border-gray-300 p-2 hover:bg-nxm-red hover:bg-opacity-70 hover:text-white transition-all duration-500 bg-white mr-2"
                >
                  <p className="mr-2">
                    Editer{" "}
                    <span className="hidden xl:inline-block">les statuts</span>{" "}
                  </p>
                </button>
              ) : null}
            </div>
            <div>
              {editStatus ? (
                <div className="flex z-10">
                  <SelectState />
                  <button
                    type="button"
                    onClick={() => {
                      if (selectedState === 0) {
                        showToastError("Veuillez selectionner un statut");
                      } else {
                        updateSelectedRequestStates();
                      }
                    }}
                    className={
                      !loading
                        ? "flex justify-center items-center mt-1 border-[1px] border-filter-green rounded-sm w-10 ml-2 hover:bg-filter-green hover:text-white transition-all duration-500 bg-white h-6"
                        : `flex items-center justify-center w-10 pl-2 ml-2 text-sm font-medium text-gray-900 bg-white rounded-sm border hover:bg-gray-100 hover:text-filter-green focus:z-10 focus:ring-2 focus:ring-filter-green dark:text-gray-400 border-filter-green`
                    }
                  >
                    {!loading ? (
                      <p>Ok</p>
                    ) : (
                      <svg
                        aria-hidden="true"
                        role="status"
                        className="inline w-4 h-4 mr-3 text-gray-200 animate-spin dark:text-gray-600"
                        viewBox="0 0 100 101"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                          fill="currentColor"
                        />
                        <path
                          d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                          fill="#1C64F2"
                        />
                      </svg>
                    )}
                  </button>
                  <button
                    className="flex justify-center items-center mt-1 border-[1px] border-red-500 rounded-sm h-6 w-10 ml-2 transition-all duration-500 bg-white"
                    onClick={() => {
                      setEditStatus(!editStatus);
                    }}
                  >
                    X
                  </button>
                </div>
              ) : null}
            </div>
          </div>
        </div>
        <div className="w-11/12 border-2 border-zinc-100 h-[90%] md:h-[75%] rounded-md bg-white mb-20">
          <PartnerRequestTable />
        </div>
      </div>
    </div>
  );
};

export default PartnerRequests;
