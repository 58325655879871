import { create } from "zustand";

type SuperAdminPartnerStore = {
    partners: any[];
    setPartners: (partners: any[]) => void;

    sendData: boolean;
    setSendData: (sendData: boolean) => void;

    partnerId: number;
    setPartnerId: (partnerId: number) => void;

    idPartnerSubscription: number;
    setIdPartnerSubscription: (idPartnerSubscription: number) => void;

    isNavbarVisible: boolean;
    setNavbarVisible: (isNavbarVisible: boolean) => void;

    pathVisible: string;
    setPathVisible: (pathVisible: string) => void;

    modePartner: string;
    setModePartner: (modePartner: string) => void;
}

export const useSuperAdminPartnerStore = create<SuperAdminPartnerStore>((set) => ({
    partners: [],
    setPartners: (partners: any[]) => set({ partners }),

    sendData: false,
    setSendData: (sendData: boolean) => set({ sendData }),

    partnerId: 0,
    setPartnerId: (partnerId: number) => set({ partnerId }),

    idPartnerSubscription: 0,
    setIdPartnerSubscription: (idPartnerSubscription: number) => set({ idPartnerSubscription }),

    isNavbarVisible: false,
    setNavbarVisible: (isNavbarVisible: boolean) => set({ isNavbarVisible }),

    pathVisible: 'partners',
    setPathVisible: (pathVisible: string) => set({ pathVisible }),

    modePartner: 'create',
    setModePartner: (modePartner: string) => set({ modePartner }),
}));