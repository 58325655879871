import { create } from "zustand";

  type MFAstate = {
    showMfa: boolean;
    setShowMfa: (showMfa: boolean | ((prevShowMfa: boolean) => boolean)) => void;
    
    showModalCodeMFA: boolean;
    setShowModalCodeMFA: (showModalCodeMFA:boolean ) => void;

    codeMfa: string;
    setCodeMfa: (codeMfa: string) => void;
  };
  
  export const useMfaStore = create<MFAstate>((set) => ({
    showMfa: false,
    setShowMfa: (showMfa: boolean | ((prevShowMfa: boolean) => boolean)) =>
      set((state) => ({
        showMfa: typeof showMfa === 'function' ? showMfa(state.showMfa) : showMfa,
      })),

    showModalCodeMFA: false,
    setShowModalCodeMFA: (showModalCodeMFA) => set({ showModalCodeMFA }),

    codeMfa: "",
    setCodeMfa: (codeMfa) => set({ codeMfa })
    
  }));
  