import { useNavigate, useParams } from "react-router-dom";
import { usePartnerHomeCustomerStore } from "../../../../stores/Cac/Partner/partnerHomeCustomer";
import useSorting from "../../../../hooks/useSorting";
import Pen from "../../../../assets/icons/edit.svg";
import Trash from "../../../../assets/icons/trash.svg";
import Down from "../../../../assets/icons/chevron-down.svg";
import { useTokenContext } from "../../../../context/tokenContext";

const PartnerCustomerTable = () => {
  // Context
  const { roleId } = useTokenContext();
  // Store
  const { clients, setChoiceModal, setIdCustomer } =
    usePartnerHomeCustomerStore();
  const navigate = useNavigate();
  // Hooks
  const { idPartner } = useParams();
  const [sortedData, handleSort] = useSorting(clients);

  return (
    <div className="w-full h-full overflow-y-scroll ">
      <div
        className={
          roleId === 2
            ? "w-full m-auto bg-nxm-red 2xl:p-3 p-2 border-zinc-100 grid md:grid-cols-4 xl:text-xl sticky top-0 z-10 font-BarlowCondensed shadow-xl rounded-tl-md text-white"
            : "w-full m-auto bg-nxm-red 2xl:p-3 p-2 border-zinc-100 grid md:grid-cols-5 xl:text-xl sticky top-0 z-10 font-BarlowCondensed shadow-xl rounded-tl-md text-white"
        }
      >
        <div className="col-start-1 col-end-3 flex justify-start items-center ml-5 md:ml-0">
          Nom <span className="hidden md:flex">&nbsp; du client</span>
          <button onClick={() => handleSort("name")}>
            <img src={Down} alt="down" className="p-1 mt-1" />
          </button>
        </div>
        <div className="col-start-3 col-end-4 flex justify-start items-center ml-5 md:ml-0">
          Créateur
          <button onClick={() => handleSort("fullName")}>
            <img src={Down} alt="down" className="p-1 mt-1" />
          </button>
        </div>
        <div className="hidden sm:col-start-4 col-end-5 sm:flex justify-start items-center ml-5 md:ml-0">
          Missions
          <button onClick={() => handleSort("missionCount")}>
            <img src={Down} alt="down" className="p-1 mt-1" />
          </button>
        </div>
        <div className="col-start-5 col-end-6 ">
          <div className={roleId === 2 ? "hidden" : "grid grid-cols-2"}>
            <div className="hidden lg:block lg:col-start-1 lg:col-end-2 ">
              Gestion
            </div>
            <div className="hidden lg:block lg:col-start-2 lg:col-end-3 ">
              Suppression
            </div>
          </div>
        </div>
      </div>
      <div className="w-full md:h-[92%] text-md 2xl:text-lg font-BarlowCondensed">
        {sortedData?.length === 0 || sortedData === undefined ? (
          <p className="text-center italic font-thin mt-2">
            Vous n'avez pas créé de dossier client.
          </p>
        ) : (
          sortedData?.map((client) => (
            <div
              key={client?.id}
              className={
                roleId === 2
                  ? "w-full hover:bg-nxm-lightBlue hover:bg-opacity-20 border-b-2 border-zinc-100 m-auto grid md:grid-cols-4 font-light 2xl:text-xl text-md text-nxm-darkGray"
                  : "hover:bg-nxm-lightBlue hover:bg-opacity-20 border-b-2 w-full m-auto border-zinc-100 grid md:grid-cols-5 font-light 2xl:text-xl text-md text-nxm-darkGray"
              }
            >
              <button
                type="button"
                className="grid grid-cols-4 col-start-1 col-end-5 w-full p-3"
                onClick={() => {
                  navigate(
                    `/partner/${idPartner}/customers/${client?.id}/missions`
                  );
                }}
              >
                <div className="col-start-1 col-end-3 w-full text-start font-bold text-lg">
                  {client?.name}
                </div>
                <div className="col-start-3 md:col-end-4 col-end-5 w-full text-start ml-2 md:ml-0">
                  {client?.User?.fullName}
                </div>
                <div className="hidden md:flex col-start-4 col-end-5 w-full text-start ml-2">
                  {client?.missionCount}
                </div>
              </button>
              <div
                className={
                  roleId === 2
                    ? "hidden"
                    : "col-start-5 col-end-6 hidden md:flex justify-around items-center"
                }
              >
                <div className="grid grid-cols-2 w-full">
                  <button
                    type="button"
                    className="col-start-1 col-end-2 flex items-center justify-start mr-3"
                    onClick={() => {
                      setIdCustomer(client?.id);
                      setChoiceModal("update");
                    }}
                  >
                    <img
                      className="block 2xl:h-6 2xl:w-6 h-5 w-5"
                      src={Pen}
                      alt="pen "
                    />
                  </button>
                  <button
                    type="button"
                    onClick={() => {
                      setIdCustomer(client?.id);
                      setChoiceModal("delete");
                    }}
                    className="col-start-2 col-end-3 flex items-center justify-start "
                  >
                    <img
                      className="block 2xl:h-6 2xl:w-6 h-5 w-5"
                      src={Trash}
                      alt="pen"
                    />
                  </button>
                </div>
              </div>
            </div>
          ))
        )}
      </div>
    </div>
  );
};

export default PartnerCustomerTable;
