import { create } from "zustand";
import { IData } from "../../interfaces/customerPage";

type RecoveryFileState = {
    percent: IData[];
    setPercent: (percent: IData[]) => void;

    customerName: string;
    setCustomerName: (customerName: string) => void;

    missionName: string;
    setMissionName: (missionName: string) => void;

    missionInProgress: any[];
    setMissionInProgress: (missionInProgress: any[]) => void;

    missionDeadLine: any[];
    setMissionDeadLine: (missionDeadLine: any[]) => void;

    requestId: number;
    setRequestId: (requestId: number) => void;

    selectedIdsRequestDeadLine : any[],
    setSelectedIdsRequestDeadLine : (update: any[] | ((prev: any[]) => any[])) => void;

    selectedIdsRequestInProgress :  any[],
    setSelectedIdsRequestInProgress : (update: any[] | ((prev: any[]) => any[])) => void;

    selectedAllDeadLine: boolean;
    setSelectedAllDeadLine: (value: boolean | ((prev: boolean) => boolean)) => void;

    selectedAllInProgress: boolean;
    setSelectedAllInProgress: (value: boolean | ((prev: boolean) => boolean)) => void;
    
    resetSelect: () => void;
}

export const useRecoveryFileStore = create<RecoveryFileState>((set) => ({
    percent: [],
    setPercent: (percent) => set({ percent }),

    customerName: "",
    setCustomerName: (customerName) => set({ customerName }),

    missionName: "",
    setMissionName: (missionName) => set({ missionName }),

    missionInProgress: [],
    setMissionInProgress: (missionInProgress) => set({ missionInProgress }),

    missionDeadLine: [],
    setMissionDeadLine: (missionDeadLine) => set({ missionDeadLine }),

    requestId: 0,
    setRequestId: (requestId) => set({ requestId }),

    selectedIdsRequestDeadLine: [],
    setSelectedIdsRequestDeadLine: (update: any[] | ((prev: any[]) => any[])) =>
    typeof update === 'function' ? set((state) => ({ selectedIdsRequestDeadLine: update(state.selectedIdsRequestDeadLine) }))
                                 : set({ selectedIdsRequestDeadLine: update }),

    selectedIdsRequestInProgress: [],
    setSelectedIdsRequestInProgress: (update: any[] | ((prev: any[]) => any[])) =>
    typeof update === 'function' ? set((state) => ({ selectedIdsRequestInProgress: update(state.selectedIdsRequestInProgress) }))
                                 : set({ selectedIdsRequestInProgress: update }),

    selectedAllDeadLine: false,
    setSelectedAllDeadLine: (value) => set((state) => ({
      selectedAllDeadLine: typeof value === 'function' ? value(state.selectedAllDeadLine) : value
    })),
    
    selectedAllInProgress: false,
    setSelectedAllInProgress: (value) => set((state) => ({
      selectedAllInProgress: typeof value === 'function' ? value(state.selectedAllInProgress) : value
    })),

    resetSelect: () => {
        set({ selectedIdsRequestDeadLine: [] });
        set({ selectedIdsRequestInProgress: [] });
        set({ selectedAllDeadLine: false });
        set({ selectedAllInProgress: false });
    }
}));