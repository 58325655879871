import { create } from "zustand";
import { NotifsDetailsInt } from "../../interfaces/notifs";

type NotifsState = {
    notifs: any[]; 
    setNotifs: (notifs: any[] | ((prevNotifs: any[]) => any[])) => void;
    addNotif: (notif: any) => void;

    showNotifs: string;
    setShowNotifs: (showNotifs: string) => void;

    hasOpenedNotifs: boolean;
    setHasOpenedNotifs: (hasOpened: boolean) => void;

    notifsDoc: NotifsDetailsInt[];
    setNotifsDoc: (notifsDoc: NotifsDetailsInt[]) => void;

    notifsDetails: NotifsDetailsInt[];
    setNotifsDetails: (notifsDetails: NotifsDetailsInt[]) => void;

    unreadNotifCount: number;
    setUnreadNotifCount: (unreadNotifCount: number) => void

    unreadNotifCountDoc: number;
    setUnreadNotifCountDoc: (unreadNotifCountDoc: number) => void
};
  
  export const useNotifsStore = create<NotifsState>((set) => ({
    notifs: [],
    setNotifs: (notifs) =>
      set((state) => ({
        notifs: notifs instanceof Function ? notifs(state.notifs) : notifs,
      })),
    addNotif: (notif) => set((state) => ({ notifs: [...state.notifs, notif] })),
    
    showNotifs: "",
    setShowNotifs: (showNotifs) => set(() => ({ showNotifs: showNotifs })),

    hasOpenedNotifs: false,
    setHasOpenedNotifs: (hasOpened) => set(() => ({ hasOpenedNotifs: hasOpened })),

    notifsDetails: [],
    setNotifsDetails: (notifsDetails) => set({ notifsDetails }),

    notifsDoc: [],
    setNotifsDoc: (notifsDoc) => set({ notifsDoc }),

    unreadNotifCount: 0,
    setUnreadNotifCount: (unreadNotifCount) => set({unreadNotifCount: unreadNotifCount}),

    unreadNotifCountDoc: 0,
    setUnreadNotifCountDoc: (unreadNotifCountDoc) => set({unreadNotifCountDoc: unreadNotifCountDoc}),
  }));
  