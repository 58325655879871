import { create } from "zustand";
import { MissionData } from "../../../interfaces/mission";
import { CommentaryRefused } from "../../../interfaces/commentary";
import { IRequest } from "../../../interfaces/requestsInterface";

type CustomerRequestState = {
    requests: any[];
    setRequests: (requests: IRequest[]) => void;

    dataMission: MissionData;
    setDataMission: (dataMission: MissionData) => void;

    requestIdCustomer: number;
    setRequestIdCustomer: (resquestIdCustomer: number) => void;

    openDetailsCustomer: boolean;
    setOpenDetailsCustomer: (openDetailsCustomer: boolean) => void;

    request: any;
    setRequest: (requestDetails: any) => void;
    
    choiceTab: string;
    setChoiceTab: (choiceTab: string) => void;

    updateRequestByClient: boolean;
    setUpdateRequestByClient: (updateRequestByClient: boolean) => void;

    commentsRefused: CommentaryRefused[];
    setCommentsRefused: (commentsRefused: CommentaryRefused[]) => void;

    displayAllRequests: boolean;
    setDisplayAllRequests: (displayAllRequests: boolean) => void;
}

export const useCustomerRequestsStore = create<CustomerRequestState>(
    (set) => ({
        requests: [],
        setRequests: (requests: IRequest[]) => set(() => ({ requests: requests })),
        
        dataMission: {} as MissionData,
        setDataMission: (dataMission: MissionData) => set(() => ({ dataMission: dataMission })),
    
        requestIdCustomer: 0,
        setRequestIdCustomer: (requestIdCustomer: number) => set(() => ({ requestIdCustomer: requestIdCustomer})),
    
        openDetailsCustomer: false,
        setOpenDetailsCustomer: (openDetailsCustomer: boolean) => set(() => ({ openDetailsCustomer: openDetailsCustomer })),
    
        request: {} ,
        setRequest: (requestDetails: any) => set({ request: requestDetails }),
  
        choiceTab: "Documents",
        setChoiceTab: (choiceTab: string) => set(() => ({ choiceTab: choiceTab })),
    
        updateRequestByClient: false,
        setUpdateRequestByClient: (updateRequestByClient: boolean) => set(() => ({ updateRequestByClient: updateRequestByClient })),
    
        commentsRefused: [],
        setCommentsRefused: (commentsRefused: CommentaryRefused[]) => set(() => ({ commentsRefused: commentsRefused })),

        displayAllRequests: false,
        setDisplayAllRequests: (displayAllRequests) => set({ displayAllRequests: displayAllRequests }),    
    })
);