import { useEffect } from "react";
import { getPartners } from "../../services/Api/superadminService";
import { useSuperAdminPartnerStore } from "../../stores/superadmin/superadminpartner";
import { useSuperAdminCreatePartnerStore } from "../../stores/superadmin/createPartner";
import { useSuperAdminLogsStore } from "../../stores/superadmin/logs";
import useModal from "../../hooks/useModal";
import HeaderSuperAdmin from "../../components/SuperAdmin/HeaderSuperAdmin";
import NavBarSuperAdmin from "../../components/SuperAdmin/NavBarSuperAdmin";
import SuperAdminLogsTable from "../../components/SuperAdmin/SuperAdminLogsTable";
import SuperAdminPartnerTable from "../../components/SuperAdmin/SuperAdminPartnerTable";
import { ToastContainer } from "react-toastify";

const SuperAdminHome = () => {
  // Store
  const {
    setPartners,
    setNavbarVisible,
    pathVisible,
    modePartner,
    setModePartner,
    sendData,
  } = useSuperAdminPartnerStore();
  const { openDetails, setOpenDetails, setDisplayDiv } =
    useSuperAdminCreatePartnerStore();

  // Hook
  const { modalContent, isModalOpen, openModal, closeModal } = useModal();
  const { handleDownloadLogs } = useSuperAdminLogsStore();

  useEffect(() => {
    getPartners()
      .then((res) => {
        const sortedData = res?.data.sort((a: any, b: any) => a.id - b.id);
        setPartners(sortedData);
      })
      .catch((err) => console.log(err));
  }, [sendData]);

  return (
    <div className="w-screen h-screen bg-background bg-no-repeat bg-cover bg-center bg-fixed bg-opacity-50 backdrop-filter backdrop-blur-lg overflow-hidden flex flex-col">
      {isModalOpen ? modalContent : null}
      <HeaderSuperAdmin />
      <ToastContainer position="top-center" />
      <div
        className="absolute top-20 left-0 h-full w-[250px]"
        onMouseEnter={() => setNavbarVisible(true)}
      >
        <NavBarSuperAdmin />
      </div>
      {pathVisible === "partners" ? (
        <>
          <div className="flex items-center justify-between w-11/12 m-auto font-K2D">
            {!openDetails ? (
              <button
                className="w-auto 3xl:h-10 h-8 hover:animate-background-shine items-center justify-center rounded-md border border-gray-300 bg-[linear-gradient(110deg,#FFFFFF,45%,#f3f3f3,55%,#FFFFFF)] bg-[length:300%_100%] px-6 font-medium transition-colors focus:outline-none focus:ring-2 focus:ring-slate-400 focus:ring-offset-2 focus:ring-offset-slate-50 hover:bg-nxm-lightBlue hover:bg-opacity-20 font-K2D"
                onClick={() => {
                  setDisplayDiv(true);
                  setOpenDetails(true);
                  setModePartner("create");
                }}
              >
                Créer un partenaire
              </button>
            ) : null}
          </div>
          <div className="flex flex-col w-11/12 m-auto h-[80%]">
            <SuperAdminPartnerTable
              closeModal={closeModal}
              openModal={openModal}
            />
          </div>
        </>
      ) : pathVisible === "logs" ? (
        <>
          <div className="flex w-10/12 justify-between items-center m-auto">
            <h1 className="text-center text-4xl text-nexum-blue font-extralight">
              Logs :
            </h1>
            <button
              className="border-[1px] rounded-md shadow-lg bg-nexum-blue text-white hover:text-nexum-or p-2 mt-2"
              onClick={() => handleDownloadLogs()}
            >
              Télécharger
            </button>
          </div>
          <div className="flex flex-col w-11/12 m-auto h-[80%]">
            <div className="flex flex-col w-11/12 m-auto h-full">
              <SuperAdminLogsTable />
            </div>
          </div>
        </>
      ) : null}
    </div>
  );
};

export default SuperAdminHome;
