import { useServiceStore } from "../../../stores/admin/service";
import { usePartnerStore } from "../../../stores/admin/users";
import Check from "../../../assets/icons/check-square.svg";
import { useLocation, useParams } from "react-router-dom";
import {
  updateClientFile,
  updateUserCustomer,
  updateUserPartner,
} from "../../../services/Api/adminService";
import { useClientFileStore } from "../../../stores/admin/clientFile";
import { showToastError } from "../../../services/Toasts/toasts";

type UserProps = {
  lastName: string | undefined;
  firstName: string | undefined;
  eMail: string | undefined;
  titre: string | undefined;
  idRole?: number;
};

const TabsForModify = () => {
  // Store
  const {
    updatedUser,
    setUpdatedUser,
    editingUserId,
    setEditingUserId,
    setSelectedIdRole,
  } = usePartnerStore();
  const {
    clientFiles,
    editingClientFileId,
    setEditingClientFileId,
    updatedClientFile,
    setUpdatedClientFile,
    convertResponsable,
  } = useClientFileStore();
  const { edit, setEdit, update, setUpdate } = useServiceStore();

  // Hook
  const location = useLocation();
  const { idPartner } = useParams();

  // Function to update partner or customer
  const onSaveModifyUser = async () => {
    if (updatedUser === null) {
      return setEdit(false);
    } else if (updatedUser?.eMail === "") {
      return showToastError("L'email doit être renseigné");
    }
    try {
      const userToUpdate: UserProps = {
        lastName: updatedUser?.lastName,
        firstName: updatedUser?.firstName,
        eMail: updatedUser?.eMail,
        titre: updatedUser?.titre,
        idRole: updatedUser?.idRole,
      };

      if (editingUserId !== undefined) {
        location.pathname.includes("/users/partner")
          ? await updateUserPartner(
              Number(idPartner),
              editingUserId,
              userToUpdate
            )
              .then(() => {
                setUpdate(!update);
                setUpdatedUser(null);
                setEditingUserId(0);
                setEdit(false);
                setSelectedIdRole(0);
              })
              .catch((err) => console.log(err))
          : await updateUserCustomer(
              Number(idPartner),
              editingUserId,
              userToUpdate
            )
              .then(() => {
                setUpdate(!update);
                setUpdatedUser(null);
                setEditingUserId(0);
                setEdit(false);
                setSelectedIdRole(0);
              })
              .catch((err) => console.log(err));
      } else {
        console.error("L'ID de l'utilisateur est indéfini");
      }
    } catch (error) {
      console.error("Erreur lors de la mise à jour de l'utilisateur:", error);
    }
  };

  const onSaveClientFile = async () => {
    if (clientFiles === null) {
      setEdit(false);
    }
    try {
      const clientFileToUpdate = {
        name: updatedClientFile?.name,
        managerId: convertResponsable,
      };
      // Create copy of user object and delete properties that are not needed
      const updatedClientFileCopy = { ...updatedClientFile };

      if (editingClientFileId !== undefined) {
        await updateClientFile(Number(idPartner), editingClientFileId, {
          ...updatedClientFileCopy,
          ...clientFileToUpdate,
        })
          .then(() => {
            setUpdate(!update);
            setUpdatedClientFile(null);
            setEditingClientFileId(0);
            setEdit(false);
          })
          .catch((err) => console.log(err));
      } else {
        console.error("L'ID de l'utilisateur est indéfini");
      }
    } catch (error) {
      console.error("Erreur lors de la mise à jour de l'utilisateur:", error);
    } finally {
    }
  };

  const handleSaveModify = () => {
    if (
      location.pathname.includes("/users/partner") ||
      location.pathname.includes("customer")
    ) {
      onSaveModifyUser();
    } else if (location.pathname.includes("clientfile")) {
      onSaveClientFile();
    }
  };

  return (
    <div className="mt-11 w-10 h-20 bg-white flex flex-col justify-start items-center border-[1px] rounded-tl-md rounded-bl-md border-r-none ">
      <button onClick={() => setEdit(!edit)} className="mt-2">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="20"
          height="20"
          viewBox="0 0 24 24"
          fill={edit ? "black " : "none"}
          stroke="currentColor"
          strokeWidth="1"
          strokeLinecap="round"
          strokeLinejoin="round"
        >
          <path d="M17 3a2.828 2.828 0 1 1 4 4L7.5 20.5 2 22l1.5-5.5L17 3z"></path>
        </svg>
      </button>
      <hr className="w-full my-2" />
      <button
        className={`flex justify-center items-center border-[1px]  border-filter-green mb-2 rounded-md w-auto bg-white transition-all duration-500 ${
          edit
            ? "hover:bg-filter-green hover:text-white hover:border-filter-green"
            : "border-gray-300 text-gray-500"
        }`}
        onClick={handleSaveModify}
        disabled={!edit}
      >
        <img src={Check} alt="check" className="w-5 h-5" />
      </button>
    </div>
  );
};

export default TabsForModify;
