import { useLocation, useParams } from "react-router-dom";
import { useServiceStore } from "../../../stores/admin/service";
import {
  showToastError,
  showToastSuccess,
} from "../../../services/Toasts/toasts";
import { deleteUser } from "../../../services/Api/adminService";
import { AxiosError } from "axios";

const DeleteUser = ({ id, closeModal }: any) => {
  const { update, setUpdate } = useServiceStore();
  const location = useLocation();
  const idPartner = useParams();

  const handleDeletePartner = async (id: number) => {
    try {
      await deleteUser(Number(idPartner.idPartner), id);
      setUpdate(!update);
      closeModal();
      showToastSuccess("L'utilisateur a bien été supprimé !");
    } catch (error) {
      if ((error as AxiosError).response?.status === 403) {
        showToastError("Vous ne pouvez pas supprimer cet utilisateur car il est créateur d'un dossier client !");
      } else {
        showToastError("La suppression a échoué !");
      }

      console.log(error);
    }
  };

  return (
    <>
      <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
        <div className="relative w-auto md:w-1/4 my-6 mx-auto max-w-3xl min-w-[35%]">
          {/*content*/}
          <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
            {/*header*/}
            <div className="flex items-start justify-between p-5 border-b border-solid border-slate-200 rounded-t">
              <h3 className="text-3xl font-semibold text-center">
                Suppression
              </h3>
            </div>
            {/*body*/}
            <div className="text-center font-light p-5">
              {location.pathname.includes("users/partner")
                ? "Etes-vous sûr de bien vouloir supprimer ce collaborateur ?"
                : "Etes-vous sûr de bien vouloir supprimer ce client ?"}
            </div>
            {/*footer*/}
            <div className="flex items-center justify-end p-6 border-t border-solid border-slate-200 rounded-b">
              <button
                className="text-red-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                type="button"
                onClick={() => closeModal()}
              >
                Annuler
              </button>
              <button
                className="text-white bg-gradient-to-r from-teal-400 via-teal-500 to-teal-600 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-teal-300 dark:focus:ring-teal-800 font-medium rounded-lg text-sm lg:text-lg px-5 py-2 text-center me-2  uppercase  shadow hover:shadow-lg outline-none  ease-linear transition-all duration-150"
                type="button"
                onClick={() => {
                  handleDeletePartner(id);
                }}
              >
                Supprimer
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
    </>
  );
};

export default DeleteUser;
