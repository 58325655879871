import React from "react";
import useModal from "../../../hooks/useModal";
import Trash from "../../../assets/icons/trash.svg";
import Archive from "../../../assets/icons/archive.svg";
import DeleteClientFile from "../Modales/DeleteClientFile";
import ArchiveDeleteFile from "../Modales/ArchiveDeleteFile";
import { SelectorResponsable } from "../Services/SelectorResponsable";
import { useClientFileStore } from "../../../stores/admin/clientFile";
import { useServiceStore } from "../../../stores/admin/service";
import TabsForModify from "../Layout/TabsForModify";

const ClientFilesAdminTable = () => {
  // Store
  const { edit } = useServiceStore();
  const {
    clientFiles,
    fullName,
    setFullName,
    editingClientFileId,
    setEditingClientFileId,
    setUpdatedClientFile,
    setConvertResponsable,
  } = useClientFileStore();

  // Hook
  const { modalContent, isModalOpen, openModal, closeModal } = useModal();

  return (
    <div className="h-full w-full rounded-md flex">
      {isModalOpen ? modalContent : null}
      <TabsForModify />
      <div className="flex flex-col w-full h-full font-BarlowCondensed ">
        <div className="w-full m-auto bg-nxm-red border-2 py-2 border-zinc-100 grid grid-cols-7 grid-rows-1 rounded-t-md text-xl text-white">
          <div className="col-start-1 col-end-3 ml-2">Nom du dossier</div>
          <div className="col-start-3 col-end-5 ">Responsable</div>
          <div className="col-start-5 col-end-7 ">Nombre de missions</div>
        </div>
        <div className="w-full h-full overflow-y-scroll bg-white mb-5">
          {clientFiles?.map((clientfile: any) => (
            <div
              key={clientfile?.id}
              className={
                editingClientFileId === clientfile?.id && edit
                  ? "w-full bg-gray-100 border-b-2 border-zinc-100 m-auto grid grid-cols-7 h-10  2xl:text-xl text-md font-light justify-start items-center"
                  : clientfile?.closed === 1
                  ? "w-full bg-gray-100 border-b-2 border-zinc-100 m-auto grid grid-cols-7 h-10 italic text-gray-400  2xl:text-xl text-md font-light justify-start items-center"
                  : "w-full hover:bg-nxm-lightBlue hover:bg-opacity-20 border-b-2 border-zinc-100 m-auto grid grid-cols-7 h-10  2xl:text-xl text-md font-light justify-start items-center"
              }
            >
              <div
                className="col-start-1 col-end-3 ml-2"
                onClick={() => edit && setEditingClientFileId(clientfile?.id)}
              >
                {editingClientFileId === clientfile?.id && edit ? (
                  <input
                    type="text"
                    className="w-11/12 pt-1"
                    defaultValue={clientfile?.name}
                    onChange={(e) =>
                      setUpdatedClientFile({
                        ...clientfile,
                        name: e.target.value,
                      })
                    }
                  />
                ) : (
                  clientfile?.name
                )}
              </div>
              <div
                className="col-start-3 col-end-5 ml-2"
                onClick={() => {
                  edit && setEditingClientFileId(clientfile?.id);
                  if (clientfile?.User?.fullName) {
                    setFullName(clientfile?.User?.fullName);
                  }
                }}
              >
                {editingClientFileId === clientfile?.id && edit ? (
                  clientfile?.User?.id ? (
                    <SelectorResponsable
                      id
                      setConvertResponsable={setConvertResponsable}
                      fullName={fullName}
                    />
                  ) : (
                    clientfile?.User?.fullName
                  )
                ) : (
                  clientfile?.User?.fullName
                )}
              </div>
              <div className="col-start-5 col-end-7 ml-10 ">
                {clientfile?.missionCount}
              </div>
              <div className="col-start-7 col-end-8 pt-1">
                <div className="grid grid-cols-2">
                  <button
                    className="col-start-1 col-end-2 "
                    onClick={() =>
                      openModal(
                        <ArchiveDeleteFile
                          closed={clientfile?.closed}
                          id={clientfile?.id}
                          closeModal={closeModal}
                        />
                      )
                    }
                  >
                    <img src={Archive} alt="trash" className="pt-1" />
                  </button>
                  <button
                    className="col-start-2 col-end-3"
                    onClick={() =>
                      openModal(
                        <DeleteClientFile
                          id={clientfile?.id}
                          closeModal={closeModal}
                        />
                      )
                    }
                  >
                    <img src={Trash} alt="trash" />
                  </button>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default React.memo(ClientFilesAdminTable);
