import { create } from "zustand";
 
type ClientFileState = {
    clientFiles: any[];
    setClientFiles: (clientFiles: any[]) => void;

    editingClientFileId: any;
    setEditingClientFileId: (editingClientFileId: any) => void;

    fullName: string;
    setFullName: (fullName: string) => void;

    updatedClientFile: any;
    setUpdatedClientFile: (clientFile: any) => void;

    convertResponsable: number;
    setConvertResponsable: (convertResponsable: number) => void;

}

export const useClientFileStore = create<ClientFileState>((set) => ({
    clientFiles: [],
    setClientFiles: (clientFiles) => set({ clientFiles }),

    editingClientFileId: null,
    setEditingClientFileId: (clientFileId) => set({ editingClientFileId: clientFileId }),

    fullName: "",
    setFullName: (fullName) => set({ fullName }),

    updatedClientFile: null,
    setUpdatedClientFile: (clientFile) => set({ updatedClientFile: clientFile }),

    convertResponsable: 0,
    setConvertResponsable: (convertResponsable) => set({ convertResponsable }),
}));