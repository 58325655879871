import { create } from "zustand";

type MissionState = {
    missions: any[];
    setMissions: (missions: any[]) => void;

    editingMissionId: any;
    setEditingMissionId: (editingMissionId: any) => void;

    updatedMission: any;
    setUpdatedMission: (mission: any) => void;
}

export const useMissionStore = create<MissionState>((set) => ({
    missions: [],
    setMissions: (missions) => set({ missions }),

    editingMissionId: null,
    setEditingMissionId: (missionId) => set({ editingMissionId: missionId }),

    updatedMission: null,
    setUpdatedMission: (mission) => set({ updatedMission: mission }),
}));