import { create } from "zustand";

interface PartnerWorkingFolderState {
    workingFolder: any;
    setWorkingFolder: (workingFolder: any) => void;

    workingFolderDetails: any;
    setWorkingFolderDetails: (workingFolderDetails: any) => void;

    choiceModalWF: string;
    setChoiceModalWF: (choiceModalWF: string) => void;

    workingFolderId: number;
    setWorkingFolderId: (workingFolderId: number) => void;

    collabsAssignedToClient: any[];
    setCollabsAssignedToClient: (collabsAssignedToClient: any[]) => void;

    checkboxValuesCollabs: { [key: number]: boolean };
    setCheckboxValuesCollabs: (checkboxValuesCollabs: ((prevState: { [key: number]: boolean }) => { [key: number]: boolean }) | { [key: number]: boolean }) => void;

    initialCheckboxValuesCollabs: { [key: number]: boolean };
    setInitialCheckboxValuesCollabs: (initialCheckboxValuesCollabs: ((prevState: { [key: number]: boolean }) => { [key: number]: boolean }) | { [key: number]: boolean }) => void;
    
    checkboxValuesClients: { [key: string]: boolean };
    setCheckboxValuesClients: (setCheckboxValuesClients: ((prevState: { [key: string]: boolean }) => { [key: string]: boolean }) | { [key: string]: boolean }) => void;

    initialCheckboxValuesClients: { [key: string]: boolean };
    setInitialCheckboxValuesClients: (initialCheckboxValuesClients: ((prevState: { [key: string]: boolean }) => { [key: string]: boolean }) | { [key: string]: boolean }) => void;
    
    resetWorkingFolder: () => void;

    updateWorkingFolder: boolean;
    setUpdateWorkingFolder: (updateWorkingFolder: boolean) => void;

    dataWorkingFolder: any;
    setDataWorkingFolder: (dataWorkingFolder: any) => void;

    email: string;
    setEmail: (email: string) => void;

    mails: string[];
    setMails: (mails: string[]) => void;

    addUserModal : boolean;
    setAddUserModal : (addUserModal : boolean) => void;

    manager: any;
    setManager: (manager: any) => void;
}

export const usePartnerWorkingFolderStore = create<PartnerWorkingFolderState>((set) => ({
    workingFolder: {},
    setWorkingFolder: (workingFolder) => set({ workingFolder: workingFolder }),

    workingFolderDetails: {},
    setWorkingFolderDetails: (workingFolderDetails) => set({ workingFolderDetails: workingFolderDetails }),

    choiceModalWF: "",
    setChoiceModalWF: (choiceModalWF) => set({ choiceModalWF: choiceModalWF }),

    collabsAssignedToClient: [],
    setCollabsAssignedToClient: (collabsAssignedToClient) =>
        set({ collabsAssignedToClient: collabsAssignedToClient }),
    
    checkboxValuesCollabs: {},
    setCheckboxValuesCollabs: (checkboxValuesCollabs) => set((prevState) => {
        if (typeof checkboxValuesCollabs === 'function') {
            return { checkboxValuesCollabs: checkboxValuesCollabs(prevState.checkboxValuesCollabs) };
        }
        return { checkboxValuesCollabs };
        }),

    initialCheckboxValuesCollabs: {},
    setInitialCheckboxValuesCollabs: (initialCheckboxValuesCollabs) => set((prevState) => {
        if (typeof initialCheckboxValuesCollabs === 'function') {
            return { initialCheckboxValuesCollabs: initialCheckboxValuesCollabs(prevState.initialCheckboxValuesCollabs) };
        }
        return { initialCheckboxValuesCollabs };
    }),

    checkboxValuesClients: {},
    setCheckboxValuesClients: (checkboxValuesClients) => set((prevState) => {
        if (typeof checkboxValuesClients === 'function') {
            return { checkboxValuesClients: checkboxValuesClients(prevState.checkboxValuesClients) };
        }
        return { checkboxValuesClients };
    }),

    initialCheckboxValuesClients: {},
    setInitialCheckboxValuesClients: (initialCheckboxValuesClients) => set((prevState) => {
        if (typeof initialCheckboxValuesClients === 'function') {
            return { initialCheckboxValuesClients: initialCheckboxValuesClients(prevState.initialCheckboxValuesClients) };
        }
        return { initialCheckboxValuesClients };
    }),
    
    workingFolderId: 0,
    setWorkingFolderId: (workingFolderId) => set({ workingFolderId: workingFolderId }),

    updateWorkingFolder: false,
    setUpdateWorkingFolder: (updateWorkingFolder) => set({ updateWorkingFolder: updateWorkingFolder }),

    resetWorkingFolder: () => {
        set({
            choiceModalWF: "",
            collabsAssignedToClient: [],
            checkboxValuesCollabs: {},
            initialCheckboxValuesCollabs: {},
            checkboxValuesClients: {},
            initialCheckboxValuesClients: {},
            workingFolderId: 0,
            updateWorkingFolder: false,
        });
    },

    dataWorkingFolder: {},
    setDataWorkingFolder: (dataWorkingFolder) => set({ dataWorkingFolder: dataWorkingFolder }),

    email: "",
    setEmail: (email) => set({ email: email }),

    mails: [],
    setMails: (mails) => set({ mails: mails }),

    addUserModal: false,
    setAddUserModal: (addUserModal) => set({ addUserModal: addUserModal }),

    manager: {},
    setManager: (manager) => set({ manager: manager }),
}));

