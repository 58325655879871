import { useEffect } from "react";
import { usePartnerMissionStore } from "../../../../stores/Cac/Partner/partnerMission";
import { useUtilsStore } from "../../../../stores/Utils/selects";
import { useParams } from "react-router-dom";
import { getCategoryMissionByIdPartner } from "../../../../services/Api/partnerService";
import { usePartnerWorkingFolderStore } from "../../../../stores/Cac/Partner/partnerWorkingFolder";

const SelectServiceMission = () => {
  const { choiceModal, dataMission, setDataMission } = usePartnerMissionStore();
  const { choiceModalWF, dataWorkingFolder, setDataWorkingFolder } = usePartnerWorkingFolderStore();
  const {
    services,
    setServices,
    selectedService,
    setSelectedService,
    setIsRenewable,
  } = useUtilsStore();
  const { idPartner } = useParams();

  useEffect(() => {
    if (idPartner)
      getCategoryMissionByIdPartner(Number(idPartner))
        .then((data) => setServices(data))
        .catch((error) => console.log(error));
  }, [choiceModal]);

  const handleServiceChange = (e: any) => {
    const serviceId = parseInt(e.target.value);
    const service = services.find((service) => service.id === serviceId);

      if (service) {
        setSelectedService(serviceId);
        setIsRenewable(service.isRenewable);
        {choiceModal !== "" ? setDataMission({
          ...dataMission,
          categoryId: parseInt(e.target.value),
        }) : choiceModalWF === "create" ? setDataWorkingFolder({
          ...dataWorkingFolder,
          categoryId: parseInt(e.target.value),
        }) : null
      }
    }
  };

  return (
    <select
      name="services"
      id="services"
      onChange={(e) => handleServiceChange(e)}
      value={selectedService}
      className="w-1/4 h-10 p-1 border-[1px] border-gray-300 rounded-md mr-2 shadow-md"
    >
      {selectedService === 0 && (
        <option value="" className="text-slate-300">
          Choisir un service
        </option>
      )}
      {services?.map((service) => (
        <option value={service?.id} key={service?.id}>
          {service.isRenewable === false
            ? service?.name
            : service?.name + " (renouvelable)"}
        </option>
      ))}
    </select>
  );
};

export default SelectServiceMission;
