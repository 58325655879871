import SearchBar from "../../components/Admin/Services/SearchBar";
import NavBarAdmin from "../../components/Admin/Layout/NavBarAdmin";
import MissionsAdminTable from "../../components/Admin/Table/MissionsAdminTable";
import { useEffect } from "react";
import { getAllMissions } from "../../services/Api/adminService";
import { useServiceStore } from "../../stores/admin/service";
import { useMissionStore } from "../../stores/admin/mission";
import { useParams } from "react-router-dom";

const AdminClientFile = () => {
  // Store
  const { edit, update, searchTerm } = useServiceStore();
  const { missions, setMissions } = useMissionStore();
  const { idPartner } = useParams();

  useEffect(() => {
    if (searchTerm !== "") {
      const filteredRequests = missions.filter(
        (item: any) =>
          item?.name?.toLowerCase().includes(searchTerm.toLowerCase()) ||
          item?.CategoryMission?.name
            .toLowerCase()
            .includes(searchTerm.toLowerCase())
      );
      setMissions(filteredRequests);
    } else {
      getAllMissions(Number(idPartner))
        .then((data) => setMissions(data))
        .catch((error) => console.log(error));
    }
  }, [searchTerm, update]);

  return (
    <div className="w-screen h-screen bg-background bg-no-repeat bg-cover bg-center bg-fixed bg-opacity-50 backdrop-filter backdrop-blur-lg overflow-hidden flex flex-col">
      <div className="flex w-screen h-screen">
        <NavBarAdmin />

        <div className="flex flex-col justify-start items-center w-full h-full pt-5">
          <div className="w-10/12 h-full">
            <h2 className="text-nxm-darkGray font-light 2xl:text-3xl text-xl font-K2D 2xl:py-5 py-2 ">
              Liste des missions :
            </h2>
            <div className="flex justify-between w-full items-center">
              <div className="my-5 w-auto">
                <SearchBar />
              </div>
              {edit ? (
                <p className="mr-36 font-BarlowCondensed">
                  Vous êtes en mode édition{" "}
                </p>
              ) : (
                <p></p>
              )}
            </div>
            <div className="flex 2xl:h-5/6 h-[70%]">
              <MissionsAdminTable />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdminClientFile;
