import { create } from "zustand";

interface newPartner {
    name: string;
    address: string;
    socialReason: string;
    isSubscriptionEnabled: boolean;
    startDate: Date | null;
    discountAmount: number;
    idSubscription: number;
    discountExpirationDate: Date | null;
    firstName: string;
    lastName: string;
    email: string;
    companyName: string;
    addressLine1: string;
    addressLine2: string;
    city: string;
    postalCode: string;
    stateProvinceRegion: string;
    country: string;
    phone: string;
}
export type SuperAdminCreatePartnerStore = {
    name: string;
    setName: (name: string) => void;

    adress: string;
    setAdress: (adress: string) => void;

    socialReason: string;
    setSocialReason: (socialReason: string) => void;

    isSubscriptionEnabled: boolean;
    setIsSubscriptionEnabled: (isSubscriptionEnabled: boolean) => void;

    startDate: Date | null;
    setStartDate: (startDate: Date | null) => void;

    discountAmount: number;
    setDiscountAmount: (discountAmount: number) => void;

    discountExpirationDate: Date | null;
    setDiscountExpirationDate: (discountExpirationDate: Date | null) => void;

    isSubscription: any[];
    setIsSubscription: (isSubscription: any) => void;

    selectedSubscription: number;
    setSelectedSubscription: (selectedSubscription: number) => void;

    firstName: string;
    setFirstName: (firstName: string) => void;

    lastName: string;
    setLastName: (lastName: string) => void;

    email: string;
    setEmail: (email: string) => void;

    companyName: string;
    setCompanyName: (companyName: string) => void;

    addressLine1: string;
    setAddressLine1: (addressLine1: string) => void;

    addressLine2: string;
    setAddressLine2: (addressLine2: string) => void;

    city: string;
    setCity: (city: string) => void;

    postalCode: string;
    setPostalCode: (postalCode: string) => void;

    stateProvinceRegion: string;
    setStateProvinceRegion: (stateProvinceRegion: string) => void;

    country: string;
    setCountry: (country: string) => void;

    openDetails: boolean | null;
    setOpenDetails: (openDetails: boolean) => void;

    reset: () => void;

    invoices: any;
    setInvoices: (invoices: any) => void;

    displayDiv: boolean;
    setDisplayDiv: (displayDiv: boolean) => void;

    animationClass: string;
    setAnimationClass: (animationClass: string) => void;

    partnerData: newPartner;
    setPartnerData: (property: keyof newPartner, value: any) => void;

    files: any;
    setFiles: (files: any) => void;
}


export const useSuperAdminCreatePartnerStore = create<SuperAdminCreatePartnerStore>((set) => ({
    name: "",
    setName: (name: string) => set({ name }),

    adress: "",
    setAdress: (adress: string) => set({ adress }),

    socialReason: "",
    setSocialReason: (socialReason: string) => set({ socialReason }),

    isSubscriptionEnabled: false,
    setIsSubscriptionEnabled: (isSubscriptionEnabled: boolean) => set({ isSubscriptionEnabled }),

    startDate: null,
    setStartDate: (startDate: Date | null) => set({ startDate }),

    discountAmount: 0,
    setDiscountAmount: (discountAmount: number) => set({ discountAmount }),

    discountExpirationDate: null,
    setDiscountExpirationDate: (discountExpirationDate: Date | null) => set({ discountExpirationDate }),

    isSubscription: [],
    setIsSubscription: (isSubscription: any) => set({ isSubscription }),

    selectedSubscription: 0,
    setSelectedSubscription: (selectedSubscription: any) => set({ selectedSubscription }),

    firstName: "",
    setFirstName: (firstName: string) => set({ firstName }),

    lastName: "",
    setLastName: (lastName: string) => set({ lastName }),

    email: "",
    setEmail: (email: string) => set({ email }),

    companyName: "",
    setCompanyName: (companyName: string) => set({ companyName }),

    addressLine1: "",
    setAddressLine1: (addressLine1: string) => set({ addressLine1 }),

    addressLine2: "",
    setAddressLine2: (addressLine2: string) => set({ addressLine2 }),

    city: "",
    setCity: (city: string) => set({ city }),

    postalCode: "",
    setPostalCode: (postalCode: string) => set({ postalCode }),

    stateProvinceRegion: "",
    setStateProvinceRegion: (stateProvinceRegion: string) => set({ stateProvinceRegion }),

    country: "",
    setCountry: (country: string) => set({ country }),

    openDetails: null,
    setOpenDetails: (openDetails: boolean | null) => set({ openDetails }),

    reset: () => set({
        name: "",
        adress: "",
        socialReason: "",
        isSubscriptionEnabled: false,
        startDate: null,
        discountAmount: 0,
        discountExpirationDate: null,
        isSubscription: [],
        selectedSubscription: 0,
        firstName: "",
        lastName: "",
        email: "",
        companyName: "",
        addressLine1: "",
        addressLine2: "",
        city: "",
        postalCode: "",
        stateProvinceRegion: "",
        country: "",
        partnerData: {
            name: "",
            address: "",
            socialReason: "",
            isSubscriptionEnabled: false,
            startDate: null,
            discountAmount: 0,
            idSubscription: 0,
            discountExpirationDate: null,
            firstName: "",
            lastName: "",
            email: "",
            companyName: "",
            addressLine1: "",
            addressLine2: "",
            city: "",
            postalCode: "",
            stateProvinceRegion: "",
            country: "",
            phone: "",
        },
        openDetails: false,
    }),

    invoices: [],
    setInvoices: (invoices: any) => set({ invoices }),

    displayDiv: false,
    setDisplayDiv: (displayDiv: boolean) => set({ displayDiv }),

    animationClass: "",
    setAnimationClass: (animationClass: string) => set({ animationClass }),

    partnerData: {
        name: "",
        address: "",
        socialReason: "",
        isSubscriptionEnabled: false,
        startDate: null,
        discountAmount: 0,
        discountExpirationDate: null,
        idSubscription: 0,
        firstName: "",
        lastName: "",
        email: "",
        companyName: "",
        addressLine1: "",
        addressLine2: "",
        city: "",
        postalCode: "",
        stateProvinceRegion: "",
        country: "",
        phone: "",
    },
    setPartnerData: (property, value) => set((state) => ({ partnerData: { ...state.partnerData, [property]: value } })),

    files: [],
    setFiles: (files: any) => set({ files}),
}));