import { create } from "zustand";

type CategoriesState = {
    services: any;
    setServices: (services: any[]) => void;

    newService: string;
    setNewService: (newService: string) => void;

    updateService: boolean;
    setUpdateService: (update: boolean) => void;

    idService: number;
    setIdService: (id: number) => void;

    categories: any;
    setCategories: (categories: any[]) => void;

    newCategory: string;
    setNewCategory: (newCategory: string) => void;

    modifyNameCategory: string;
    setModifyNameCategory: (modifyNameCategory: string) => void;

    modifyNameService: string;
    setModifyNameService: (modifyNameService: string) => void;

    editingServiceId: number | null;
    setEditingServiceId: (editingServiceId: number | null) => void;

    idCategory: number;
    setIdCategory: (idCategory: number) => void;

    editingCategoryId: number | null;
    setEditingCategoryId: (editingCategoryId: number | null) => void;
}

export const useCategoriesServicesStore = create<CategoriesState>((set) => ({
    services: [],
    setServices: (services) => set({ services }),

    newService: "",
    setNewService: (newService) => set({ newService }),

    updateService: false,
    setUpdateService: (update) => set({ updateService: update }),

    idService: 0,
    setIdService: (id) => set({ idService: id }),

    categories: [],
    setCategories: (categories) => set({ categories }),

    newCategory: "",
    setNewCategory: (newCategory) => set({ newCategory }),

    modifyNameCategory: "",
    setModifyNameCategory: (modifyNameCategory) => set({ modifyNameCategory }),

    modifyNameService: "",
    setModifyNameService: (modifyNameService) => set({ modifyNameService }),

    editingServiceId: null,
    setEditingServiceId: (editingServiceId) => set({ editingServiceId }),

    idCategory: 0,
    setIdCategory: (idCategory) => set({ idCategory }),

    editingCategoryId: null,
    setEditingCategoryId: (editingCategoryId) => set({ editingCategoryId }),
}));
