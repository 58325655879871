import { deleteClient } from "../../../../services/Api/customerService";
import { usePartnerHomeCustomerStore } from "../../../../stores/Cac/Partner/partnerHomeCustomer";
import { showToastError, showToastSuccess } from "../../../../services/Toasts/toasts";

const PartnerDeleteCustomer = () => {
  // Store
  const { choiceModal, setChoiceModal, idCustomer, setIdCustomer } =
    usePartnerHomeCustomerStore();

  const handleReset = () => {
    setChoiceModal("");
    setIdCustomer(0);
  };

  const handleDeleteClient = async (id: number) => {
    const success = await deleteClient(id);
    if (success) {
      handleReset();
      showToastSuccess("La suppression a bien été effectuée !");
    } else {
      showToastError("La suppression a échoué !");
      handleReset();
    }
  };

  return (
    choiceModal === "delete" && (
      <>
        <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
          <div className="relative w-auto md:w-1/4 my-6 mx-auto max-w-3xl min-w-[35%]">
            {/*content*/}
            <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
              {/*header*/}
              <div className="flex items-center justify-center p-5 border-b border-solid border-slate-200 rounded-t font-K2D">
                <h3 className="text-3xl font-semibold text-center ">
                  Suppression
                </h3>
              </div>
              {/*body*/}
              <div className="text-center font-light p-5 font-BarlowCondensed text-xl">
                Etes-vous sûr de bien vouloir supprimer ce client ?
              </div>
              {/*footer*/}
              <div className="flex items-center justify-end p-4 border-t border-solid border-slate-200 rounded-b font-BarlowCondensed">
                <button
                  className="text-red-500 background-transparent font-bold uppercase px-6 py-2 text-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                  type="button"
                  onClick={() => handleReset()}
                >
                  Annuler
                </button>
                <button
                  className="text-white bg-gradient-to-r from-teal-400 via-teal-500 to-teal-600 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-teal-300 dark:focus:ring-teal-800 font-medium rounded-lg text-lg px-5 py-2.5 text-center me-2 mb-2 uppercase  shadow hover:shadow-lg outline-none  ease-linear transition-all duration-150"
                  type="button"
                  onClick={() => handleDeleteClient(idCustomer)}
                >
                  Supprimer
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
      </>
    )
  );
};

export default PartnerDeleteCustomer;
