import { normalizeFileName } from "../Utils/functionService";
import { axiosWithToken, axiosWithTokenForBlob } from "./axiosService";
import { toast } from "react-toastify";

export const getDocRequest = async (id: number) => {
  try {
    const response = await axiosWithToken().get(`api/docrequest/${id}`);
    return response.data;
  } catch (err) {
    console.log(err);
    throw err;
  }
}

export const getCustomersRequest = async (id: number|undefined) => {
  try {
    const response = await axiosWithToken().get(`api/docrequest/${id}/customer/users`);
    return response.data;
  } catch (err) {
    console.log(err);
    throw err;
  }
};

export const getHistoryRefused = async (id: number|undefined) => {
  try {
    const response = await axiosWithToken().get(`api/docrequest/${id}/refusalhistory`);
    return response;
  } catch (err) {
    console.log(err);
    throw err;
  }
}

export const deleteRequest = async (id: number|undefined) => {
  try {
    const response = await axiosWithToken().delete(`api/docrequest/${id}`);
    return response.data;
  } catch (err) {
    console.log(err)
    throw new Error
  }
};

export const refuseRequest = async (id: number) => {
  try {
    const response = await axiosWithToken().post(`api/docrequest/${id}/refuse`);
    return response
  }  catch (err) {
    console.log(err)
    throw new Error
  }
}

export const handleAcceptDoc = async (id: number|undefined) => {
  try{
      const response = await axiosWithToken().patch(`api/docrequest/${id}/state`, { state: 3 });
      return response
  } catch (err) {
      console.log(err);
      throw err;
  }
}

export const handleRejectDoc = async (id: number|undefined) => {
  try{
      const response = await axiosWithToken().patch(`api/docrequest/${id}/state`, { state: 1 });
      console.log(response)
      return response
  } catch (err) {
      console.log(err);
      throw err;
  }
}

export const patchRequest = async (idRequest: number, data: any) => {
  try {
    const response = await axiosWithToken().patch(`api/docrequest/${idRequest}`, data);
    return response;
  } catch (err) {
    console.log(err);
    throw err;
  }
}

export const getCommentaries = async (idRequest: number) => {
  try {
    const response = await axiosWithToken().get(`api/docrequest/${idRequest}/commentaries`);
    return response.data;
  } catch (err) {
    console.log(err);
    throw err;
  }
}

export const postCommentary = async (idRequest: number, commentary: string) => {
  const data = {
    text: commentary,
    idCategory: 1
  }
  try {
    const response = await axiosWithToken().post(`api/docrequest/${idRequest}/commentary`, data);
    return response.data;
  } catch (err) {
    console.log(err);
    throw err;
  }
}

 // Upload a document
 export const handleUploadFiles = async (idRequest: number, files: any) => {
  const uploaded = files.map((file: any) => ({ name: file.name, file }));
  
  const formData = new FormData();
  
  uploaded.forEach((file: any) => {
    const normalizedFileName = normalizeFileName(file.name);
    formData.append('files', file.file, normalizedFileName);
  });

  try{
      const response = await axiosWithTokenForBlob().post(`api/docrequest/${idRequest}/document`, formData);
      return response;
  } catch (error: any) {
    if (error.response?.data instanceof Blob && error.response?.data.type === "application/json") {
      const reader = new FileReader();
      reader.onload = function() {
          const jsonResponse = JSON.parse(reader.result as string);
          if (jsonResponse.message === "file name too long") {
              toast.error("Le nom du fichier est trop long. Veuillez le raccourcir et réessayer.");
          }
      };
      reader.readAsText(error.response.data);
  }    
  throw error;
  }
};

// ******************** FUNCTIONS ******************** //
export const handleCheckboxChangeService = (
  id: any,
  requestIds: number[],
  setRequestIds: (requestIds: number[]) => void
): void => {
  const isChecked: boolean = requestIds ? requestIds.includes(id) : false;
  if (isChecked) {
    setRequestIds(
      requestIds.filter((existingId: number) => existingId !== id)
    );
  } else {
    setRequestIds([...requestIds, id]);
  }
};

export const handleAllCheckboxChangeService = (
  requests: any[],
  requestIds: number[],
  setRequestIds: (requestIds: number[]) => void
): void => {
  const allChecked = requestIds.length === requests.length;
  if (allChecked) {
    setRequestIds([])
  } else {
    const allIds = requests.map((item: any) => item.id);
    setRequestIds(allIds);
  }
};