export type ColorType = 'red' | 'orange' | 'green' | 'grey';

export interface MissionMonitoring {
    id: number;
    name: string;
    dueDate: string;
    fullName: string;
    progress: number;
    color: ColorType;
    missions: any;
    categoryMission:string;
    dateExercice: string;
}

export const colorPriority: Record<ColorType, number> = {
    red: 1,
    orange: 2,
    green: 3,
    grey: 4,
  };