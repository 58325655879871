import Pen from "../../../../assets/icons/edit.svg";
import Trash from "../../../../assets/icons/trash.svg";
import Down from "../../../../assets/icons/chevron-down.svg";
import { useTokenContext } from "../../../../context/tokenContext";
import useSorting from "../../../../hooks/useSorting";
import { useNavigate, useParams } from "react-router-dom";
import {
  dateFormat,
  splitMissionName,
} from "../../../../services/Utils/functionService";
import { usePartnerMissionStore } from "../../../../stores/Cac/Partner/partnerMission";
import repeat from "../../../../assets/icons/repeat.svg";
import { postMissionRenew } from "../../../../services/Api/customerService";
import {
  showToastError,
  showToastSuccess,
} from "../../../../services/Toasts/toasts";

const PartnerMissionTable = () => {
  // Context
  const { roleId, isSuperAdmin } = useTokenContext();

  // Store
  const {
    missions,
    setMissionId,
    setChoiceModal,
    setRenewable,
    updateMission,
    setUpdateMission,
  } = usePartnerMissionStore();

  // Hooks
  const [sortedData, handleSort] = useSorting(missions);
  const { idPartner, idCustomer } = useParams();
  const navigate = useNavigate();

  const handleRenewMission = (idMission: number) => {
    postMissionRenew(Number(idCustomer), idMission)
      .then(() => {
        showToastSuccess("Mission reconduite avec succès");
        setUpdateMission(!updateMission);
      })
      .catch((error) => {
        showToastError("Vous devez accepter les requêtes");
        console.log(error);
      });
  };

  return (
    <div className="w-full h-full overflow-y-scroll">
      <div
        className={
          roleId === 2
            ? "w-full m-auto bg-nxm-red xl:text-xl 2xl:p-3 p-2 border-zinc-100 grid grid-cols-5 sticky top-0 z-10 font-BarlowCondensed shadow-xl rounded-tl-md text-white "
            : "w-full m-auto bg-nxm-red xl:text-xl 2xl:p-3 p-2 border-zinc-100 grid grid-cols-5 sm:grid-cols-8 sticky top-0 z-10 font-BarlowCondensed shadow-xl rounded-tl-md text-white "
        }
      >
        <div className="hidden sm:flex sm:col-start-1 sm:col-end-2 justify-start items-center relative">
          Service
          <button onClick={() => handleSort("category")}>
            <img src={Down} alt="down" className="p-1 mt-1" />
          </button>
        </div>
        <div className="flex col-start-1 col-end-4 sm:col-start-2 sm:col-end-4 justify-start items-center mr-24 sm:mr-0">
          Nom
          <button onClick={() => handleSort("name")}>
            <img src={Down} alt="down" className="p-1 mt-1" />
          </button>
        </div>
        <div className="hidden sm:flex sm:col-start-4 sm:col-end-5 justify-start items-center">
          Responsable
          <button onClick={() => handleSort("fullName")}>
            <img src={Down} alt="down" className="p-1 mt-1" />
          </button>
        </div>
        <div className="hidden sm:flex sm:col-start-5 sm:col-end-6 justify-start items-center ml-2">
          Date <span className="hidden lg:inline-block">de fin</span>
          <button onClick={() => handleSort("dueDate")}>
            <img src={Down} alt="down" className="p-1 mt-1" />
          </button>
        </div>
        <div className="col-start-5 col-end-6 sm:col-start-6 sm:col-end-8 lg:pr-16 flex justify-start items-center">
          Statut{" "}
          <span className="hidden sm:inline-block ml-1"> des requêtes</span>
        </div>
        <div
          className={
            roleId === 2
              ? "hidden"
              : "col-start-4 col-end-5 sm:col-start-8 sm:col-end-9 text-start"
          }
        >
          <div className="hidden sm:grid grid-cols-3"></div>
        </div>
      </div>
      <div className="w-full h-[92%] text-md xl:text-lg font-BarlowCondensed">
        {sortedData?.length === 0 || sortedData === undefined ? (
          <p className="text-center italic font-thin mt-2">
            Vous n'avez pas créé de mission.
          </p>
        ) : (
          sortedData?.map((mission) => (
            <div
              key={mission?.id}
              className={`
                ${
                  mission?.isAffected === true || isSuperAdmin === 1
                    ? "bg-white"
                    : "bg-gray-100 text-gray-400"
                }
                ${
                  roleId === 2
                    ? "w-full m-auto grid grid-cols-4 items-center hover:bg-nxm-lightBlue hover:bg-opacity-20 border-b-2 border-zinc-100 font-light 2xl:text-xl text-md"
                    : "w-full m-auto grid grid-cols-5 sm:grid-cols-8 items-center hover:bg-nxm-lightBlue hover:bg-opacity-20 border-b-2 border-zinc-100 font-light 2xl:text-xl text-md"
                }
              `}
            >
              <button
                type="button"
                className={`
                  grid 
                  ${roleId === 2 ? "grid-cols-5" : "grid-cols-7"}
                  col-start-1 
                  col-end-8 
                  w-full 
                  p-3 
                  2xl:text-xl 
                  text-md 
                  text-nxm-darkGray 
                  ${
                    mission?.isAffected
                      ? "cursor-pointer"
                      : "cursor-not-allowed"
                  }
                `}
                onClick={() => {
                  if (mission?.isAffected || isSuperAdmin === 1) {
                    setRenewable(mission?.isRenewable);
                    navigate(
                      `/partner/${idPartner}/customers/${idCustomer}/missions/${mission?.id}/requests`
                    );
                  }
                }}
                disabled={!mission?.isAffected}
              >
                <div className="hidden sm:inline-block sm:col-start-1 sm:col-end-2 text-start">
                  {mission?.category} {mission?.nameMissionPeriod}
                </div>
                <div className="font-bold col-start-1 col-end-3 sm:col-start-2 sm:col-end-4 text-start pl-2 sm:pl-0 sm:text-start">
                  {mission?.name && splitMissionName(mission?.name)}
                </div>
                <div className="hidden sm:inline-block col-start-4 col-end-5 text-start">
                  {mission?.fullName}
                </div>
                <div className="hidden sm:inline-block col-start-5 col-end-6 text-start ml-2">
                  {dateFormat(mission?.dueDate)}
                </div>
                <div className="col-start-6 col-end-9 text-start w-full ">
                  <div className="w-full lg:grid lg:grid-cols-4 lg:gap-x-2 ml-4 lg:ml-0 ">
                    <div className="w-full flex justify-start items-center mr-2">
                      <div className="w-6 h-3 border-[1px] rounded-md border-filter-yellow bg-filter-yellow mr-2 min-w-[24px]"></div>
                      <p className="2xl:pl-1">
                        {mission?.DocRequests[0] &&
                        Object.keys(mission.DocRequests[0])[0] === "En Attente"
                          ? mission.DocRequests[0]["En Attente"]
                          : 0}
                      </p>
                    </div>
                    <div className="flex justify-start items-center">
                      <div className="w-6 h-3 border-[1px] rounded-md border-filter-orange bg-filter-orange mr-2 min-w-[24px]"></div>
                      <p className="2xl:pl-1">
                        {mission?.DocRequests[3] &&
                        Object.keys(mission.DocRequests[3])[0] === "En retard"
                          ? mission.DocRequests[3]["En retard"]
                          : 0}
                      </p>
                    </div>
                    <div className="flex justify-start items-center">
                      <div className="w-6 h-3 border-[1px] rounded-md border-filter-blue bg-filter-blue mr-2 min-w-[24px]"></div>
                      <p className="2xl:pl-1">
                        {mission?.DocRequests[1] &&
                        Object.keys(mission.DocRequests[1])[0] === "Délivrée"
                          ? mission.DocRequests[1]["Délivrée"]
                          : 0}
                      </p>
                    </div>
                    <div className="flex justify-start items-center">
                      <div className="w-6 h-3 border-[1px] rounded-md border-filter-green bg-filter-green mr-2 min-w-[24px]"></div>
                      <p className="2xl:pl-1">
                        {mission?.DocRequests[2] &&
                        Object.keys(mission.DocRequests[2])[0] === "Accepté"
                          ? mission.DocRequests[2]["Accepté"]
                          : 0}
                      </p>
                    </div>
                  </div>
                </div>
              </button>
              <div
                className={`
                  ${
                    roleId === 2
                      ? "hidden"
                      : "col-start-8 col-end-9 text-center hidden md:flex justify-around items-center w-full"
                  }
                  ${mission?.isAffected ? "cursor-pointer" : "hidden"}
                `}
              >
                <div className="grid grid-cols-3 w-full">
                  {mission?.isRenewable && !mission?.isLastPeriod ? (
                    <button
                      type="button"
                      className={`col-start-1 col-end-2 flex items-start justify-start mr-3`}
                      onClick={() => {
                        if (mission?.isAffected === true) {
                          handleRenewMission(mission.id);
                        }
                      }}
                      disabled={!mission?.isAffected}
                    >
                      <img
                        className="block 2xl:h-6 2xl:w-6 h-5 w-5"
                        src={repeat}
                        alt="pen"
                      />
                    </button>
                  ) : null}
                  <button
                    type="button"
                    className="col-start-2 col-end-3 flex items-start justify-start mr-3"
                    onClick={() => {
                      if (mission?.isAffected === true) {
                        setChoiceModal("update");
                        setMissionId(mission.id);
                      }
                    }}
                    disabled={!mission?.isAffected}
                  >
                    <img
                      className="block 2xl:h-6 2xl:w-6 h-5 w-5"
                      src={Pen}
                      alt="pen"
                    />
                  </button>

                  <button
                    type="button"
                    className="col-start-3 col-end-4 flex items-start justify-start mr-3"
                    onClick={() => {
                      if (mission?.isAffected === true) {
                        setMissionId(mission.id);
                        setChoiceModal("archive");
                      }
                    }}
                    disabled={!mission?.isAffected}
                  >
                    <img
                      className="block 2xl:h-6 2xl:w-6 h-5 w-5"
                      src={Trash}
                      alt="trash"
                    />
                  </button>
                </div>
              </div>
            </div>
          ))
        )}
      </div>
    </div>
  );
};

export default PartnerMissionTable;
