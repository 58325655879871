import { useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import Return from "../../../assets/icons/return.svg";
import Camembert from "../../../components/Cac/Partner/Utils/MonitoringElements/Camembert";
import LateRequestsDate from "../../../components/Cac/Partner/Utils/MonitoringElements/LateRequestsDate";
import DeliveryTimes from "../../../components/Cac/Partner/Utils/MonitoringElements/DeliveryTimes";
import RejectedRate from "../../../components/Cac/Partner/Utils/MonitoringElements/RejectedRate";
import AcceptedRate from "../../../components/Cac/Partner/Utils/MonitoringElements/AcceptedRate";
import StatusRequest from "../../../components/Cac/Customer/Request/StatusRequest";
import { IData } from "../../../interfaces/customerPage";
import {
  convertData,
  cutName,
  mathRoundSup,
} from "../../../services/Utils/functionService";
import {
  getDocRequests,
  getLateRequests,
  getMissionAccepted,
  getMissionDataByCustomer,
  getMissionDelivered,
  getMissionRejected,
  getPercentageMission,
  getUpMissionComing,
} from "../../../services/Api/customerService";
import { ToastContainer } from "react-toastify";
import SideBar from "../../../components/Layout/SideBar";

const PartnerMonitoring = () => {
  const navigate = useNavigate();
  const { idCustomer, idMission } = useParams();
  const [customerName, setCustomerName] = useState<string>("");
  const [managerName, setManagerName] = useState("");
  const [percent, setPercent] = useState<IData[]>([]);
  const [upComing, setUpComing] = useState<IData[]>([]);
  const [rejected, setRejected] = useState<IData[]>([]);
  const [delivered, setDelivered] = useState<any>([]);
  const [accepted, setAccepted] = useState<any>([]);
  const [lateRequests, setLateRequests] = useState<IData[]>([]);
  const [numberDocRequests, setNumberDocRequests] = useState<number>();

  useEffect(() => {
    Promise.all([
      getPercentageMission(Number(idCustomer), Number(idMission)),
      getMissionDataByCustomer(Number(idCustomer), Number(idMission)),
      getLateRequests(Number(idCustomer), Number(idMission)),
      getUpMissionComing(Number(idCustomer), Number(idMission)),
      getMissionDelivered(Number(idCustomer), Number(idMission)),
      getDocRequests(Number(idCustomer), Number(idMission)),
      getMissionRejected(Number(idCustomer), Number(idMission)),
      getMissionAccepted(Number(idCustomer), Number(idMission)),
    ])
      .then(
        ([
          percentageData,
          missionData,
          lateRequestsData,
          upComingData,
          deliveredData,
          docRequestsData,
          rejectedData,
          acceptedData,
        ]) => {
          const convertedData = convertData(percentageData.percentage);
          setPercent(convertedData);
          const customerNameCut = cutName(missionData?.name);
          setCustomerName(customerNameCut);
          setManagerName(missionData?.manager);
          setLateRequests(lateRequestsData);
          setUpComing(upComingData);
          setDelivered(deliveredData);
          setNumberDocRequests(docRequestsData.length);
          setRejected(rejectedData);
          setAccepted(acceptedData);
        }
      )
      .catch((error) => console.log(error));
  }, []);

  return (
    <div className="w-full h-screen bg-background bg-no-repeat bg-cover bg-center bg-fixed bg-opacity-50 backdrop-filter backdrop-blur-lg overflow-hidden flex">
      <ToastContainer position="top-center" />
      <SideBar />
      <div className="flex flex-col justify-start items-center w-full">
        <div className="w-11/12 m-auto pt-5 flex flex-col justify-between items-center">
          <h2 className="text-gray-700 font-light text-xl 2xl:text-4xl mb-2 font-K2D">
            Monitoring des requêtes :{" "}
            <span className="font-bold">{customerName}</span>
          </h2>
          <p className="italic font-thin mb-2 font-K2D">
            Responsable de la mission : {managerName}
          </p>
        </div>
        <div className="w-11/12 m-auto flex justify-start">
          <button
            type="button"
            className="flex p-2"
            title="Retour"
            onClick={() => navigate(-1)}
          >
            <img src={Return} alt="return" />
          </button>
        </div>
        <div className="flex m-auto w-11/12 h-5/6 overflow-y-auto font-BarlowCondensed text-xl">
          <div className="w-full grid grid-cols-4 gap-3 ">
            <div className="w-full col-start-1 col-end-3 border-[1px] bg-white border-zinc-100 rounded-md shadow-md flex flex-col justify-center items-center relative h-full">
              <div className="grid grid-cols-2">
                <div className="col-start-1 col-end-2 flex flex-col">
                  <p className="flex justify-start p-2 font-thin absolute left-2 top-1">
                    États des requêtes :
                  </p>
                  <Camembert data={percent} />
                  <StatusRequest />
                </div>
                <div className="col-start-2 col-end-3 flex flex-col ">
                  <p className="flex justify-start p-2 font-thin absolute left-1/2 top-1">
                    Taux de rejet :
                  </p>
                  <RejectedRate rejected={rejected} />
                </div>
              </div>
            </div>
            <div className="col-start-3 col-end-5 border-[1px] bg-white border-zinc-100 rounded-md shadow-md flex flex-col justify-center items-center relative h-full">
              <p className="flex justify-start font-thin absolute top-2 left-4">
                Requêtes à venir :
              </p>
              <LateRequestsDate
                lateRequests={lateRequests}
                upComing={upComing}
                numberDocRequests={numberDocRequests}
              />
            </div>
            <div className="col-start-1 col-end-3 border-[1px] bg-white border-zinc-100 rounded-md shadow-md flex flex-col justify-center items-center relative h-full">
              <p className="flex justify-start font-thin absolute top-2 left-4">
                Délais d'envoi :
              </p>
              <DeliveryTimes
                delivered={delivered}
                numberDocRequests={numberDocRequests}
              />
              <p className="font-thin text-sm">
                Nombres de jours moyens :{" "}
                <span className="inline-block font-normal text-base">
                  {mathRoundSup(delivered?.averageDelay)} jours
                </span>
              </p>
            </div>
            <div className="col-start-3 col-end-5 border-[1px] bg-white border-zinc-100 rounded-md shadow-md flex flex-col justify-center items-center relative h-full min-h-[280px]">
              <p className="flex justify-start font-thin absolute top-2 left-4">
                Délais d'acceptation :
              </p>
              <AcceptedRate
                accepted={accepted}
                numberDocRequests={numberDocRequests}
              />
              <p className="font-thin text-sm">
                Nombres de jours moyens :{" "}
                <span className="inline-block font-normal text-base">
                  {mathRoundSup(accepted?.averageDelay)} jours
                </span>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PartnerMonitoring;
