import { useEffect } from "react";

// ******************** Click Outside for close modal ******************** //
export const useClickOutside = (callbacks: Array<Function>, elementId: string) => {
    useEffect(() => {
      const handleClickOutside = (event: any) => {
        const modal = document.getElementById(elementId);
        if (modal && !modal.contains(event.target)) {
          for (const callback of callbacks) {
            callback();
          }
        }
      };
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [callbacks]);
  }
  