import { usePartnerMissionStore } from "../../../../stores/Cac/Partner/partnerMission";
import { useUtilsStore } from "../../../../stores/Utils/selects";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { format } from "date-fns";

const SelectMonth = () => {
  const { startMonth, setStartMonth } = useUtilsStore();
  const { dataMission, setDataMission } = usePartnerMissionStore();

  return (
    <DatePicker
      selected={startMonth ? new Date(startMonth) : null}
      onChange={(date) => {
        const formattedDate = date ? format(date, "yyyy-MM-dd") : undefined;
        setStartMonth(formattedDate);
        setDataMission({
          ...dataMission,
          startMonth: formattedDate,
        });
      }}
      showMonthYearPicker
      dateFormat="MM/yyyy"
      value={startMonth ? startMonth : ""}
      className="border border-gray-300 rounded-md w-full p-2 text-lg"
    />
  );
};

export default SelectMonth;
