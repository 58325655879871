import { useState } from "react";
import Down from "../../../../../assets/icons/chevron-down.svg";

const FilterManagerMonitoringMission = ({
  managers,
  selectedResponsable,
  setSelectedResponsable,
}: any) => {
  const [open, setOpen] = useState(false);
  const [inputValue, setInputValue] = useState("");

  return (
    <div className="w-36 sm:w-52 font-medium h-6 flex flex-col relative">
      <div
        onClick={() => setOpen(!open)}
        className={`bg-white w-36 sm:w-52 text-xs flex items-center justify-between rounded-sm border-[1px] border-gray-300 z-10 h-6 pl-1 ${
          !selectedResponsable && "text-gray-700 text-xs pl-1"
        }`}
      >
        {selectedResponsable
          ? selectedResponsable?.length > 25
            ? selectedResponsable?.substring(0, 25) + "..."
            : selectedResponsable
          : "Responsable"}
        <img src={Down} alt="down" className={`${open && "rotate-180"}`} />
      </div>
      {
        <ul
          className={`bg-white overflow-y-auto absolute text-black ${
            open ? "max-h-32 w-36 sm:w-52 z-50 top-[24px]" : "max-h-0"
          } z-10`}
        >
          {managers?.map((manager: any) => (
            <li
              key={manager?.id}
              className={`p-2 text-xs hover:bg-sky-600 text-black`}
              onClick={() => {
                setSelectedResponsable(manager);
                setOpen(false);
                setInputValue("");
              }}
            >
              {manager}
            </li>
          ))}
        </ul>
      }
    </div>
  );
};

export default FilterManagerMonitoringMission;
