import { useNavigate, useParams } from "react-router-dom";
import { usePartnerWorkingFolderStore } from "../../../../stores/Cac/Partner/partnerWorkingFolder";
import { deleteWorkingFolder } from "../../../../services/Api/customerService";
import { usePartnerMissionStore } from "../../../../stores/Cac/Partner/partnerMission";
import { showToastSuccess } from "../../../../services/Toasts/toasts";
import { ToastContainer } from "react-toastify";

const ModalDeleteWorkingFolder = () => {
  const { choiceModalWF, setChoiceModalWF } = usePartnerWorkingFolderStore();
  const { idCustomer, idWorkingFolder } = useParams();
  const { updateMission, setUpdateMission } = usePartnerMissionStore();
  const navigate = useNavigate();

  const handleDeleteWorkingFolder = () => {
    deleteWorkingFolder(Number(idCustomer), Number(idWorkingFolder))
      .then(() => {
        setChoiceModalWF("");
        setUpdateMission(!updateMission);
        navigate(-1);
        showToastSuccess("Le dossier de travail a bien été supprimé");
      })
      .catch((err) => console.log(err));
  };

  return (
    choiceModalWF === "delete" && (
      <>
        <ToastContainer position="top-center" />
        <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none min-w-full">
          <div className="relative w-auto md:w-1/4 my-6 mx-auto max-w-3xl min-w-[35%]">
            {/*content*/}
            <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
              {/*header*/}
              <div className="flex items-center justify-center p-5 border-b border-solid border-slate-200 rounded-t">
                <h3 className="text-base md:text-3xl font-semibold text-center text-nxm-darkGray font-K2D ">
                  Suppression du dossier de travail
                </h3>
              </div>
              {/*body*/}
              <div className="text-sm md:text-xl text-center p-5 text-nxm-darkGray font-BarlowCondensed flex flex-col justify-center items-center">
                <p>
                  Vous êtes sur le point de supprimer le dossier de travail.{" "}
                </p>
                <p className="">
                  Cette action est irréversible, et entrainera la suppression
                  des accès aux utilisateurs affectés à ce dossier.
                </p>
                <p className="font-bold">
                  Êtes-vous sûr de vouloir continuer ?
                </p>
              </div>
              {/*footer*/}
              <div className="flex items-center justify-end p-4 border-t border-solid border-slate-200 rounded-b font-BarlowCondensed">
                <button
                  className="text-red-500 background-transparent font-bold uppercase px-6 py-2 text-xl outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                  type="button"
                  onClick={() => setChoiceModalWF("")}
                >
                  Annuler
                </button>
                <button
                  className="text-white bg-gradient-to-r from-teal-400 via-teal-500 to-teal-600 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-teal-300 dark:focus:ring-teal-800 font-medium rounded-lg text-lg px-5 py-2.5 text-center me-2 mb-2 uppercase  shadow hover:shadow-lg outline-none  ease-linear transition-all duration-150"
                  type="button"
                  onClick={handleDeleteWorkingFolder}
                >
                  Supprimer
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
      </>
    )
  );
};

export default ModalDeleteWorkingFolder;
