import { useParams } from "react-router-dom";
import { usePartnerWorkingFolderStore } from "../../../../stores/Cac/Partner/partnerWorkingFolder";
import {
  showToastError,
  showToastSuccess,
} from "../../../../services/Toasts/toasts";
import { useState } from "react";
import { createNewUserWorkingFolder } from "../../../../services/Api/customerService";

const AddUserWorkingFolder = () => {
  const {
    updateWorkingFolder,
    setUpdateWorkingFolder,
    addUserModal,
    setAddUserModal,
  } = usePartnerWorkingFolderStore();
  const { idCustomer, idWorkingFolder } = useParams();
  const [email, setEmail] = useState("");

  const handleAddUser = () => {
    const data = {
      email: email,
    };
    createNewUserWorkingFolder(
      Number(idCustomer),
      Number(idWorkingFolder),
      data
    )
      .then(() => {
        setUpdateWorkingFolder(!updateWorkingFolder);
        setAddUserModal(false);
        setEmail("");
        showToastSuccess("Accès envoyé avec succès");
      })
      .catch(() => {
        showToastError("Erreur lors de l'envoi de l'accès");
      });
  };

  return (
    addUserModal && (
      <>
        <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none min-w-full">
          <div className="relative w-auto md:w-1/4 my-6 mx-auto max-w-3xl min-w-[35%]">
            {/*content*/}
            <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
              {/*header*/}
              <div className="flex items-center justify-center p-5 border-b border-solid border-slate-200 rounded-t">
                <h3 className="text-base md:text-3xl font-semibold text-center text-gray-700 font-K2D ">
                  Ajouter un utilisateur
                </h3>
              </div>
              {/*body*/}
              <div className="text-sm md:text-xl text-center p-5 text-gray-700 font-BarlowCondensed ">
                <p className="mb-2">
                  Vous vous apprêtez à ajouter un utilisateur et à lui envoyer
                  un lien d'accès.
                </p>
                <input
                  type="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  placeholder="Entrez l'email de l'utilisateur"
                  className="w-1/2 h-10 border-[1px] border-slate-200 rounded-lg p-2 focus:outline-none focus:ring-2 focus:ring-teal-300 dark:focus:ring-teal-800"
                />
              </div>
              {/*footer*/}
              <div className="flex items-center justify-end p-4 border-t border-solid border-slate-200 rounded-b font-BarlowCondensed">
                <button
                  className="text-red-500 background-transparent font-bold uppercase px-6 py-2 text-xl outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                  type="button"
                  onClick={() => setAddUserModal(false)}
                >
                  Annuler
                </button>
                <button
                  className="text-white bg-gradient-to-r from-teal-400 via-teal-500 to-teal-600 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-teal-300 dark:focus:ring-teal-800 font-medium rounded-lg text-lg px-5 py-2.5 text-center me-2 mb-2 uppercase  shadow hover:shadow-lg outline-none  ease-linear transition-all duration-150"
                  type="button"
                  onClick={() => {
                    handleAddUser();
                  }}
                >
                  Ajouter
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
      </>
    )
  );
};

export default AddUserWorkingFolder;
