import { useEffect } from "react";
import { getLogs } from "../../services/Api/superadminService";
import { useSuperAdminLogsStore } from "../../stores/superadmin/logs";

const SuperAdminLogsTable = () => {
  const { logs, setLogs } = useSuperAdminLogsStore();

  useEffect(() => {
    getLogs()
      .then((res) => setLogs(res?.data))
      .catch((err) => console.log(err));
  }, []);

  return (
    <div
      className={` w-full h-full bg-[#3f3f3f] rounded-md overflow-y-scroll text-white`}
    >
      {logs?.map((log: any) => (
        <p className="m-2">{log}</p>
      ))}
    </div>
  );
};

export default SuperAdminLogsTable;
