import { create } from "zustand";

type PartnerState = {
    usersPartner: any[];
    setUsersPartner: (users: any[]) => void;

    usersCustomer: any[];
    setUsersCustomer: (users: any[]) => void;

    editingUserId: any;
    setEditingUserId: (userId: any) => void;

    idRole: number;
    setIdRole: (idRole: number) => void;

    selectedIdRole: number;
    setSelectedIdRole: (selectedIdRole: number) => void;

    lastName: string;
    setLastName: (lastName: string) => void;

    firstName: string;
    setFirstName: (firstName: string) => void;

    email: string;
    setEmail: (email: string) => void;

    titre: string;
    setTitre: (titre: string) => void;

    roles: any[];
    setRoles: (roles: any[]) => void;

    updatedUser: any;
    setUpdatedUser: (user: any) => void;

}

export const usePartnerStore = create<PartnerState>((set) => ({
    usersPartner: [],
    setUsersPartner: (users) => set({ usersPartner: users }),

    usersCustomer: [],
    setUsersCustomer: (users) => set({ usersCustomer: users }),

    editingUserId: null,
    setEditingUserId: (userId) => set({ editingUserId: userId }),

    idRole: 0,
    setIdRole: (idRole) => set({ idRole }), 

    selectedIdRole: 1,
    setSelectedIdRole: (selectedIdRole) => set({ selectedIdRole }),

    lastName: "",
    setLastName: (lastName) => set({ lastName }),

    firstName: "",
    setFirstName: (firstName) => set({ firstName }),

    email: "",
    setEmail: (email) => set({ email }),

    titre: "",
    setTitre: (titre) => set({ titre }),

    updatedUser: null,
    setUpdatedUser: (user) => set({ updatedUser: user }),

    roles: [],
    setRoles: (roles) => set({ roles }),

}));