import { axiosWithToken } from "./axiosService";

  // Download a document
  export const downloadDoc = async (
    idDocRequest: number,
    idDocument: number,
    fileName: string
  ) => {
    try {
      const response = await axiosWithToken().get(
        `api/docrequest/${idDocRequest}/document/${idDocument}`,
        {
          responseType: "blob",
        }
      );
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", fileName);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      console.log(error);
      throw error;
    }
  };
  
  // Open a document
  export const openDoc = async (idDocRequest: number, idDocument: number) => {
    try {
      const response = await axiosWithToken().get(
        `api/docrequest/${idDocRequest}/document/${idDocument}`,
        {
          responseType: "blob",
        }
      );
      const fileReader = new FileReader();
      fileReader.onload = () => {
        const url = URL.createObjectURL(response.data);
        window.open(url, "_blank");
      };
      fileReader.readAsBinaryString(response.data);
    } catch (error) {
      console.log(error);
      throw error;
    }
  };

  // Delete a document
  export const deleteFile = async (idDocRequest: number, idDocument: number) => {
    try {
      const response = await axiosWithToken().delete(`api/docrequest/${idDocRequest}/document/${idDocument}`);
      return response;
    } catch (error) {
      console.log(error);
    }
  };

   // Download all documents
  export const downloadFiles = async (id: number) => {
    try {
        const response = await axiosWithToken().get(`api/docrequest/${id}/documents`,
      {
        responseType: "blob",
      })
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "documents.zip");
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      console.log(error);
      throw error;
    }
  }