import { Cell, Pie, PieChart } from "recharts";

const RejectedRate = ({ rejected }: any) => {
  const RADIAN = Math.PI / 180;
  const total = 100;
  const data = [
    { name: "Rejected", value: rejected.rejectedRate, color: "#CC0000" },
    {
      name: "Accepted",
      value: total - rejected.rejectedRate,
      color: "#93CC87",
    },
  ];
  const cx = 150;
  const cy = 200;
  const iR = 50;
  const oR = 100;
  const value = rejected.rejectedRate;

  const needle = (
    value: number,
    data: { value: number }[],
    cx: number,
    cy: number,
    iR: number,
    oR: number,
    color: string | undefined
  ) => {
    let total = 0;
    data.forEach((v: { value: number }) => {
      total += v.value;
    });
    const ang = 180.0 * (1 - value / total);
    const length = (iR + 2 * oR) / 3;
    const sin = Math.sin(-RADIAN * ang);
    const cos = Math.cos(-RADIAN * ang);
    const r = 5;
    const x0 = cx + 5;
    const y0 = cy + 5;
    const xba = x0 + r * sin;
    const yba = y0 - r * cos;
    const xbb = x0 - r * sin;
    const ybb = y0 + r * cos;
    const xp = x0 + length * cos;
    const yp = y0 + length * sin;

    return [
      <circle cx={x0} cy={y0} r={r} fill={color} stroke="none" />,
      <path
        d={`M${xba} ${yba}L${xbb} ${ybb} L${xp} ${yp} L${xba} ${yba}`}
        stroke="#none"
        fill={color}
      />,
      <text
        x={x0}
        y={y0 + 25}
        textAnchor="middle"
        fill="#444444"
        fontSize="16"
        fontWeight="200"
      >
        {value?.toFixed(1)}% de taux de rejet
      </text>,
    ];
  };

  return (
    <div>
      <PieChart width={270} height={300}>
        <Pie
          dataKey="value"
          startAngle={180}
          endAngle={0}
          data={data}
          cx={cx}
          cy={cy}
          innerRadius={iR}
          outerRadius={oR}
          fill="#8884d8"
          stroke="none"
        >
          {data.map((entry, index) => (
            <Cell key={`cell-${index}`} fill={entry.color} />
          ))}
        </Pie>
        {needle(value, data, cx, cy, iR, oR, "#d0d000")}
      </PieChart>
    </div>
  );
};

export default RejectedRate;
