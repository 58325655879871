import { useState } from "react";
import Down from "../../../../../assets/icons/chevron-down.svg";

const FilterCategoryMonitoringMission = ({
  categories,
  selectedCategory,
  setSelectedCategory,
}: any) => {
  const [open, setOpen] = useState(false);
  const [inputValue, setInputValue] = useState("");

  return (
    <div className="w-36 sm:w-28 font-medium h-auto flex flex-col relative">
      <div
        onClick={() => setOpen(!open)}
        className={`bg-white w-36 sm:w-28 text-xs flex items-center justify-between rounded-sm border-[1px] border-gray-300 z-10 h-6 pl-1 ${
          !selectedCategory && "text-gray-700 text-xs pl-1"
        }`}
      >
        {selectedCategory
          ? selectedCategory?.length > 25
            ? selectedCategory?.substring(0, 25) + "..."
            : selectedCategory
          : "Service"}
        <img src={Down} alt="down" className={`${open && "rotate-180"}`} />
      </div>
      {
        <ul
          className={`bg-white overflow-y-auto absolute text-black ${
            open ? "max-h-32 w-28 sm:w-28 z-50 top-[24px]" : "max-h-0"
          } z-10`}
        >
          {categories?.map((category: any) => (
            <li
              key={category?.id}
              className={`p-2 text-xs hover:bg-sky-600 text-black`}
              onClick={() => {
                setSelectedCategory(category);
                setOpen(false);
                setInputValue("");
              }}
            >
              {category}
            </li>
          ))}
        </ul>
      }
    </div>
  );
};

export default FilterCategoryMonitoringMission;
