import { create } from "zustand";
import { CustomerData } from "../../../interfaces/customer";
import { AssignUser, ManagerObject } from "../../../interfaces/modales";
import { User } from "../../../interfaces/user";

interface PartnerHomeCustomerStore {
    // state
    clients: CustomerData[];
    setClients: (clients: CustomerData[]) => void;
    
    choiceModal: string;
    setChoiceModal: (choiceModal: string) => void;
    
    partners: ManagerObject[];
    setPartners: (partners: []) => void;

    name: string;
    setName: (name: string) => void;

    checkboxValues: { [key: number]: boolean };
    setCheckboxValues: (checkboxValues: ((prevState: { [key: number]: boolean }) => { [key: number]: boolean }) | { [key: number]: boolean }) => void;

    idCustomer: number;
    setIdCustomer: (idCustomer: number) => void;

    partnersAssigned: AssignUser[];
    setPartnersAssigned: (partnersAssigned: AssignUser[]) => void;

    customersAssigned: User[];
    setCustomersAssigned: (customersAssigned: User[]) => void;

    reset: () => void;
}

export const usePartnerHomeCustomerStore = create<PartnerHomeCustomerStore>((set) => ({
    clients: [],
    setClients: (clients) => set({ clients }),

    choiceModal: "",
    setChoiceModal: (choiceModal) => set({ choiceModal }),

    partners: [],
    setPartners: (partners) => set({ partners }),

    name: "",
    setName: (name) => set({ name }),

    checkboxValues: {},
    setCheckboxValues: (checkboxValues) => set((prevState) => {
        if (typeof checkboxValues === 'function') {
          return { checkboxValues: checkboxValues(prevState.checkboxValues) };
        }
        return { checkboxValues };
      }),
          
    idCustomer: 0,
    setIdCustomer: (idCustomer) => set({ idCustomer }),

    partnersAssigned: [],
    setPartnersAssigned: (partnersAssigned) => set({ partnersAssigned }),  
    
    customersAssigned: [],
    setCustomersAssigned: (customersAssigned) => set({ customersAssigned }),

    reset: () => set({
        clients: [],
        choiceModal: "",
        partners: [],
        name: "",
        checkboxValues: {},
        idCustomer: 0,
        partnersAssigned: [],
        customersAssigned: [],
    }),
}));
