import { create } from "zustand";

interface PartnerRequestDetailsStore {
    // state
    request : any;
    setRequest : (request : any) => void;

    comment : string;
    setComment : (comment : string) => void;

    clientsAssign: any[];
    setClientsAssign: (clients: any[]) => void;

    clientsAssignToMission: any[];
    setClientsAssignToMission: (clients: any[]) => void;
    
    openDocuments: string;
    setOpenDocuments: (open: string) => void;

    openCommentary: boolean;
    setOpenCommentary: (open: boolean) => void;

    startDate: Date;
    setStartDate: (date: Date) => void;

    loading: boolean;
    setLoading: (loading: boolean) => void;

    commentsRefused: any[];
    setCommentsRefused: (comments: any[]) => void;

    commentRefused: string;
    setCommentRefused: (comment: string) => void;

    checkboxValues: { [key: number]: boolean };
    setCheckboxValues: (checkboxValues: ((prevState: { [key: number]: boolean }) => { [key: number]: boolean }) | { [key: number]: boolean }) => void;
    
    activeNotification: boolean;
    setActiveNotification: (active: boolean) => void;

    requestRefused: boolean;
    setRequestRefused: (requestRefused: boolean) => void;

    messages: any;
    setMessages: (messages: any) => void;

    message: string;
    setMessage: (message: string) => void;
}

export const usePartnerRequestDetailsStore = create<PartnerRequestDetailsStore>(
    (set) => ({
        // state
        request : null,
        setRequest : (request) => set({ request }),

        comment : "",
        setComment : (comment) => set({ comment }),

        clientsAssign: [],
        setClientsAssign: (clients) => set({ clientsAssign: clients }),

        clientsAssignToMission: [],
        setClientsAssignToMission: (clients) => set({ clientsAssignToMission: clients }),

        openDocuments: "Documents",
        setOpenDocuments: (open) => set({ openDocuments: open }),

        openCommentary: false,
        setOpenCommentary: (open) => set({ openCommentary: open }),

        startDate: new Date(),
        setStartDate: (date) => set({ startDate: date }),

        loading: false,
        setLoading: (loading) => set({ loading: loading }),

        commentsRefused: [],
        setCommentsRefused: (comments) => set({ commentsRefused: comments }),

        commentRefused: "",
        setCommentRefused: (comment) => set({ commentRefused: comment }),        

        checkboxValues: {},
        setCheckboxValues: (checkboxValues) => set((prevState) => {
          if (typeof checkboxValues === 'function') {
              return { checkboxValues: checkboxValues(prevState.checkboxValues) };
          }
          return { checkboxValues };
          }),

        activeNotification: false,
        setActiveNotification: (active) => set({ activeNotification: active }),

        requestRefused: false,
        setRequestRefused: (requestRefused) => set({ requestRefused }),

        messages: null,
        setMessages: (messages) => set({ messages }),

        message: "",
        setMessage: (message) => set({ message }),
    }));