import user from "../../assets/icons/user.svg";
import layout from "../../assets/icons/layout.svg";
import bell from "../../assets/icons/bellBlack.svg";
import setting from "../../assets/icons/settings.svg";
import logout from "../../assets/icons/logout.svg";
import sliders from "../../assets/icons/sliders.svg";
import { NavLink, useParams } from "react-router-dom";
import useModal from "../../hooks/useModal";
import ModalDeconnexion from "./ModalDeconnexion";
import { useTokenContext } from "../../context/tokenContext";
import { useNotifsStore } from "../../stores/Utils/notifs";
import Notifs from "../Cac/Partner/Utils/Notifs";
import { getNotifications } from "../../services/Api/userService";
import { useEffect } from "react";
import { changeFavicon } from "../../services/Utils/functionService";
import { useClickOutside } from "../../hooks/useClickOutside";
import { useSettingsStore } from "../../stores/Utils/settings";
import Settings from "../Cac/Partner/Utils/Settings";
import "../../css/sideBar.css";
import file from "../../assets/icons/file-text.svg";
import { NotifsDetailsInt } from "../../interfaces/notifs";
import { readNotification } from "../../services/Api/notifService";

const SideBar = () => {
  const { modalContent, isModalOpen, openModal, closeModal } = useModal();
  const { utp, firstname, roleId } = useTokenContext();
  const { idPartner } = useParams();
  const { setShowSettings, showSettings } = useSettingsStore();
  const {
    notifs,
    setNotifs,
    showNotifs,
    setShowNotifs,
    setNotifsDetails,
    setNotifsDoc,
    unreadNotifCount,
    setUnreadNotifCount,
    unreadNotifCountDoc,
    setUnreadNotifCountDoc,
  } = useNotifsStore();

  useEffect(() => {
    const filterNotifDoc = notifs.filter(
      (el) =>
        (el.idCategoryNotification === 2 && el.read === false) ||
        (el.idCategoryNotification === 2 && el.read === null)
    );
    const filterNotifDetails = notifs.filter(
      (el) =>
        (el.idCategoryNotification === 1 && el.read === false) ||
        (el.idCategoryNotification === 1 && el.read === null)
    );

    setUnreadNotifCountDoc(filterNotifDoc.length);
    setUnreadNotifCount(filterNotifDetails.length);

    if (unreadNotifCount >= 1 || unreadNotifCountDoc >= 1) {
      changeFavicon("/src/assets/logos/NexumX.png");
    } else {
      changeFavicon("/src/assets/logos/NexumX.png");
    }
  }, [notifs]);

  const toggleDetails = () => {
    getNotifications()
      .then((response) => {
        setNotifs(response);
        const filteredNotifsDoc = response.filter(
          (el: NotifsDetailsInt) => el.idCategoryNotification === 2
        );
        setNotifsDoc(filteredNotifsDoc);
        const filteredNotifsDetails = response.filter(
          (el: NotifsDetailsInt) => el.idCategoryNotification === 1
        );
        setNotifsDetails(filteredNotifsDetails);
      })
      .catch((error) => console.log(error));
  };

  useClickOutside([setShowNotifs], "close-modal");

  const handleReadNotif = (idCategory: number) => {
    readNotification(idCategory)
      .then(() => {
        getNotifications()
          .then(() => toggleDetails())
          .catch((error) => console.log(error));
      })
      .catch((error) => console.log(error));
  };

  return (
    <div className="w-16 md:w-44 lg:w-52 2xl:w-80 h-full border-r-[1px] border-nxm-blueDark shadow-xl rounded-r-3xl rounded-tr-3xl flex flex-col justify-start items-center bg-nxm-blueLight z-50">
      {isModalOpen ? modalContent : null}
      <div className="hidden md:flex flex-col justify-center items-center bg-nxm-blueDark 2xl:h-[184px] md:h-[182px] lg:h-[170px] 3xl:h-[240px] w-full font-K2D rounded-tr-3xl">
        <img src={user} alt="user" className="2xl:w-20 2xl:h-20 w-12 h-12" />
        <p className="mt-4">
          Bonjour <span className="font-bold">{firstname}</span>
        </p>
      </div>

      <ul className="flex flex-col justify-start items-start font-K2D absolute 3xl:mt-64 md:mt-40">
        <li className="mt-6 list-none">
          <NavLink
            to="/dashboard"
            className="mt-6	flex justify-start items-center"
          >
            <img
              src={layout}
              alt="dashboard"
              className="2xl:w-8 2xl:h-8 w-6 h-6"
            />
            <p className="hidden md:flex 2xl:ml-8 ml-4 2xl:text-lg text-sm text-white el-list">
              DASHBOARD
            </p>
          </NavLink>
        </li>
        {roleId === 3 && (
          <li className="list-none">
            <NavLink
              to={`/partner/${idPartner}/admin/users/partner`}
              className="mt-6	hidden lg:flex justify-start items-center"
            >
              <img
                src={sliders}
                alt="ADMIN"
                className="hidden lg:flex 2xl:w-8 2xl:h-8 w-6 h-6"
              />
              <p className="hidden lg:flex 2xl:ml-8 ml-4 2xl:text-lg text-sm text-white el-list">
                ADMINISTRATEUR
              </p>
            </NavLink>
          </li>
        )}
        {utp === 1 && (
          <li className="mt-6 list-none	" id="close-modal-2">
            <button
              className="relative flex justify-center items-center "
              onClick={() => {
                setShowNotifs("Documents");
                handleReadNotif(2);
              }}
            >
              <img
                src={file}
                alt="documents"
                className="2xl:w-8 2xl:h-8 w-6 h-6 "
              />
              {unreadNotifCountDoc > 0 && (
                <div className="flex justify-center items-center">
                  <span className="absolute top-1 w-4 h-4 bg-red-500 rounded-full text-white text-xs flex items-center justify-center">
                    {unreadNotifCountDoc}
                  </span>
                </div>
              )}
              <p className="hidden md:flex 2xl:ml-8 ml-4 2xl:text-lg text-sm text-white mt-1 el-list">
                DOCUMENTS
              </p>
            </button>
          </li>
        )}
        <li className="mt-6 list-none	" id="close-modal">
          <button
            className="relative flex justify-center items-center "
            onClick={() => {
              setShowNotifs("Notifications");
              handleReadNotif(1);
            }}
          >
            <img
              src={bell}
              alt="notifications cloche"
              className="2xl:w-8 2xl:h-8 w-6 h-6 "
            />
            {unreadNotifCount > 0 && (
              <div className="flex justify-center items-center">
                <span className="absolute top-1 w-4 h-4 bg-red-500 rounded-full text-white text-xs flex items-center justify-center">
                  {unreadNotifCount}
                </span>
              </div>
            )}
            <p className="hidden md:flex 2xl:ml-8 ml-4 2xl:text-lg text-sm text-white mt-1 el-list">
              NOTIFICATIONS
            </p>
          </button>
          <Notifs />
        </li>
        <li className="mt-6 list-none	">
          <button
            className="relative flex justify-start items-center"
            onClick={() => {
              setShowSettings(!showSettings);
            }}
          >
            <img
              src={setting}
              alt="parametre"
              className="2xl:w-8 2xl:h-8 w-6 h-6"
            />
            <p className="hidden md:flex 2xl:ml-8 ml-4 2xl:text-lg text-sm text-white el-list">
              PARAMETRES
            </p>
          </button>
          <Settings />
        </li>
        <li className="mt-6 list-none	">
          <button
            className="flex justify-start items-center"
            onClick={() =>
              openModal(<ModalDeconnexion closeModal={closeModal} />)
            }
          >
            <img
              src={logout}
              alt="deconnexion"
              className="2xl:w-8 2xl:h-8 w-6 h-6"
            />
            <p className="hidden md:flex 2xl:ml-8 ml-4 2xl:text-lg text-sm text-white el-list">
              DECONNEXION
            </p>
          </button>
        </li>
      </ul>

      <div className="mt-auto hidden md:flex flex-col justify-center items-center w-full font-BarlowCondensed text-nxm-darkGray">
        <div className=" md:flex justify-center items-center ">
          <NavLink to="/legalnotice" className="underline 2xl:text-sm text-xs">
            Mentions légales
          </NavLink>
          <p className="px-1">-</p>
          <NavLink
            to="/cookiesnotice"
            className="underline 2xl:text-sm text-xs"
          >
            Cookies
          </NavLink>
          <p className="px-1">-</p>
          <NavLink to="/cgu" className="underline 2xl:text-sm text-xs">
            CGU
          </NavLink>
        </div>
        <p className="my-2 text-xs italic">© 2024 - Trionode</p>
      </div>
    </div>
  );
};

export default SideBar;
