import { useTokenContext } from "../../context/tokenContext";
import { NavLink, useNavigate } from "react-router-dom";
import { acceptCGU, firstConnection } from "../../services/Api/loginService";
import Logo from "../../assets/logos/logoNexum.png";
import Cookies from "js-cookie";
import Eyes from "../../assets/icons/eye.svg";
import { useConnexionStore } from "../../stores/Layout/connexion";
import { showToastError, showToastSuccess } from "../../services/Toasts/toasts";
import { ToastContainer } from "react-toastify";

const ResetPasswordFirstCnnection = () => {
  // Hooks
  const navigate = useNavigate();

  // Store
  const {
    passwordResetPassword,
    setPasswordResetPassword,
    verifyPasswordResetPassword,
    setVerifyPasswordResetPassword,
    passwordInputType,
    setPasswordInputType,
  } = useConnexionStore();

  // Context
  const {
    setToken,
    setFirstname,
    setLastname,
    setEmailUser,
    setUtc,
    setUtp,
    setMfa,
    setIsAdmin,
    setIsSuperAdmin,
  } = useTokenContext();

  // function for password visibility
  const togglePasswordVisibility = () => {
    setPasswordInputType(
      passwordInputType === "password" ? "text" : "password"
    );
  };

  const handlePassword = (e: any) => {
    e.preventDefault();
    const cguCheckbox = document.getElementById("cgu") as HTMLInputElement;
    if (!cguCheckbox.checked) {
      showToastError("Vous devez accepter les CGU.");
      return;
    }
    if (passwordResetPassword !== verifyPasswordResetPassword) {
      showToastError("Les deux mots de passe doivent être identiques !");
      return;
    }
    firstConnection(verifyPasswordResetPassword)
      .then((response) => {
        acceptCGU();
        if (response?.status === 200) {
          setToken(0);
          setFirstname("");
          setLastname("");
          setEmailUser("");
          setUtc(0);
          setMfa(false);
          setUtp(0);
          setIsAdmin(0);
          setIsSuperAdmin(0);
          setPasswordInputType("password");
          Cookies.remove("connect.sid");
          localStorage.clear();
          showToastSuccess("Le mot de passe a bien été réinitialisé !");
          navigate("/");
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <div className="w-screen h-screen bg-background bg-no-repeat bg-cover bg-center bg-fixed flex flex-col justify-start items-center">
      <ToastContainer position="top-center" />
      <p className="font-K2D font-extrabold text-white text-2xl text-center my-10">
        NEXUM EXCHANGE PLATEFORM
      </p>
      <form
        action="#"
        className="border-2 rounded-lg bg-white w-3/12 2xl:h-3/6 h-4/6 flex flex-col items-center justify-center shadow-lg"
      >
        <div className="w-full flex flex-col items-start justify-start h-auto font-BarlowCondensed">
          <p className="w-full text-xl text-center pb-5">
            Veuillez renseigner votre nouveau mot de passe.
          </p>
          <p className="w-10/12 3xl:w-8/12 m-auto text-base pb-10 text-nxm-darkGray">
            Celui ci doit contenir au minimum 8 caractères, au maximum 30, une
            majuscule, une minuscule, un chiffre et un caractère spécial.
          </p>
          <div className="w-10/12 3xl:w-8/12 m-auto flex flex-col justify-start">
            <label
              htmlFor="password"
              className="text-nxm-darkBlue text-xl mb-4"
            >
              Entrez votre mot de passe :
            </label>
            <div className="relative w-full">
              <button
                type="button"
                className="absolute top-2 right-2"
                onClick={togglePasswordVisibility}
              >
                <img src={Eyes} alt="oeil" />
              </button>
              <input
                type={passwordInputType}
                className="border-b-[1px] border-nxm-darkBlue w-full h-10 mb-5 focus:outline-none p-1"
                pattern="(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}"
                onChange={(e) => setPasswordResetPassword(e.target.value)}
                name="newPassword"
                placeholder="********"
                required
              />
            </div>
            <label
              htmlFor="password"
              className="text-nxm-darkBlue  text-xl mb-4 mt-5"
            >
              Verifiez votre mot de passe :
            </label>
            <div className="relative w-full">
              <input
                type={passwordInputType}
                className="border-b-[1px] border-nxm-darkBlue w-full h-10 mb-5 focus:outline-none p-1"
                pattern="(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}"
                name="password"
                onChange={(e) => setVerifyPasswordResetPassword(e.target.value)}
                placeholder="********"
                required
              />
              <button
                type="button"
                className="absolute top-2 right-2"
                onClick={togglePasswordVisibility}
              >
                <img src={Eyes} alt="oeil" />
              </button>
            </div>
          </div>
          <div className="w-full flex items-center justify-center">
            <input type="checkbox" required name="cgu" id="cgu" />
            <p className="ml-2">
              Accepter les{" "}
              <NavLink
                to="/cgu"
                className=" text-nxm-darkBlue underline text-sm"
              >
                CGU
              </NavLink>
            </p>
          </div>
          <div className="w-full flex justify-center items-center">
            <button
              type="submit"
              onClick={(e) => handlePassword(e)}
              className="font-BarlowCondensed text-xl w-28 border-2 border-nxm-darkBlue  rounded-md h-10 mt-10 bg-nxm-lightBlue text-white hover:shadow-lg hover:bg-white hover:text-nxm-darkBlue transition-all duration-500"
            >
              Réinitialiser
            </button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default ResetPasswordFirstCnnection;
