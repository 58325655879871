import Search from "../../../assets/icons/search.svg";
import { useServiceStore } from "../../../stores/admin/service";

const SearchBar = () => {
  const { searchTerm, setSearchTerm } = useServiceStore();

  return (
    <div className="relative w-80 flex">
      <input
        type="text"
        className="w-full border-[1px] rounded-md h-8 p-2 ml-1 font-K2D focus:outline-none"
        placeholder="Rechercher..."
        value={searchTerm}
        onChange={(e) => {
          setSearchTerm(e.target.value);
        }}
      />
      <img
        src={Search}
        alt="search"
        className="w-4 h-4 absolute right-3 top-2"
      />
    </div>
  );
};

export default SearchBar;
