import { create } from "zustand";
import { IMission } from "../../../interfaces/mission";

type CustomerMissionState = {
  missionsClient: IMission[];
  setMissionsClient: (mission: IMission[]) => void;

  showFilterCategories: boolean;
  setShowFilterCategories: (showFilterCategories: boolean) => void;

  isRenewable: boolean;
  setIsRenewable: (isRenewable: boolean) => void;
};

export const useCustomerMissionStore = create<CustomerMissionState>(
  (set) => ({
    missionsClient: [],
    setMissionsClient: (mission: IMission[]) => set(() => ({ missionsClient: mission })),
  
    showFilterCategories: false,
    setShowFilterCategories: (showFilterCategories: boolean) => set(() => ({ showFilterCategories: showFilterCategories })),
    
    isRenewable: false,
    setIsRenewable: (isRenewable: boolean) => set(() => ({ isRenewable: isRenewable })),
  })
);