import { useSuperAdminPartnerStore } from "../../stores/superadmin/superadminpartner";
import pen from "../../assets/icons/edit.svg";
import trash from "../../assets/icons/trash.svg";
import clip from "../../assets/icons/paperclip.svg";
import plus from "../../assets/icons/plus.svg";
import { getMandate } from "../../services/Api/partnerSuscriptionService";
import "../../css/animations.css";
import DeletePartnerSuperAdmin from "./modal/DeletePartnerSuperAdmin";
import { useSuperAdminCreatePartnerStore } from "../../stores/superadmin/createPartner";
import CreatePartner from "./modal/CreatePartner";
import { dateFormat } from "../../services/Utils/functionService";
import { useNavigate } from "react-router-dom";
import AddUser from "./modal/AddUser";

const SuperAdminPartnerTable = ({ openModal, closeModal }: any) => {
  // Store
  const { partners, setPartnerId, setModePartner } =
    useSuperAdminPartnerStore();
  const { openDetails, setOpenDetails, displayDiv, setDisplayDiv } =
    useSuperAdminCreatePartnerStore();

  // Hook
  const navigate = useNavigate();

  const downloadMandate = (idPartnerSubscription: number) => {
    getMandate(idPartnerSubscription)
      .then((response) => {
        if (response) {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", "mandat.pdf");
          document.body.appendChild(link);
          link.click();
          link.remove();
          window.URL.revokeObjectURL(url);
        } else {
          console.error("La réponse est undefined");
        }
      })
      .catch((error) => console.log(error));
  };
  return (
    <div
      className={`relative w-full h-full  rounded-md ${
        openDetails ? "overflow-hidden" : "overflow-y-scroll"
      }`}
    >
      <table className="w-full h-full border-[1px] bg-white rounded-md">
        <thead className="flex h-10 bg-zinc-300 sticky top-0 justify-center items-center font-BarlowCondensed text-lg ">
          <tr className="w-[5%] ml-2">Id</tr>
          <tr className="w-[20%]">Nom</tr>
          <tr className="w-[20%]">Début souscription</tr>
          <tr className="w-[15%]">Type de licence</tr>
          <tr className="w-[5%]">Statut</tr>
          <tr className="w-[5%]">Manda</tr>
          <tr className="w-[10%] text-center">Visualiser</tr>
          <tr className="w-[10%] text-center">Ajouter User</tr>
          <tr className="w-[5%]">Modifier</tr>
          <tr className="w-[10%]">Supprimer</tr>
        </thead>
        <tbody className="flex flex-col w-full text-lg justify-start items-center font-BarlowCondensed">
          {partners?.map((partner) => (
            <tr
              className={`w-full border-b-[1px] flex h-10 hover:bg-nxm-lightBlue hover:bg-opacity-20`}
              key={partner.id}
            >
              <td className="w-[5%] flex justify-start items-center ml-1">
                {partner.id}
              </td>
              <td className="w-[20%] flex justify-start items-center ml-1">
                {partner.name}
              </td>
              <td className="w-[20%] flex justify-start items-center ml-2">
                {dateFormat(partner.startDate)}
              </td>
              <td className="w-[15%] flex justify-start items-center ml-2">
                {partner.subscription}
              </td>
              <td className="w-[5%] flex justify-start items-center ml-2">
                {partner.isSubscriptionEnabled == 1 ? "✅" : "❌"}
              </td>
              <td className="w-[5%] flex justify-start items-center">
                {partner.mandate ? (
                  <button
                    className="flex justify-center items-center"
                    onClick={() =>
                      downloadMandate(partner?.idPartnerSubscription)
                    }
                  >
                    <img src={clip} alt="clip" />
                  </button>
                ) : null}
              </td>
              <td className="w-[10%] flex justify-start items-center gap-2">
                <button
                  className="flex justify-center items-center w-1/2 rounded-md bg-nxm-darkBlue"
                  onClick={() =>
                    navigate(`/partner/${partner?.id}/admin/users/partner`)
                  }
                >
                  Admin
                </button>
                <button
                  className="flex justify-center items-center w-1/2 rounded-md bg-nxm-darkBlue"
                  onClick={() => navigate(`/partner/${partner?.id}/customers`)}
                >
                  Manager
                </button>
              </td>
              <td className="w-[10%] flex justify-center items-center">
                <button
                  className="flex justify-center items-center"
                  onClick={() =>
                    openModal(
                      <AddUser
                        idPartner={partner.id}
                        closeModal={closeModal}
                        namePartner={partner.name}
                      />
                    )
                  }
                >
                  <img src={plus} alt="clip" />
                </button>
              </td>
              <td className="w-[5%] flex justify-start items-center ml-5">
                <button className="flex justify-start items-center">
                  <img
                    src={pen}
                    alt="pen"
                    className="w-5 h-5"
                    onClick={() => {
                      setPartnerId(partner.id);
                      setDisplayDiv(true);
                      setModePartner("edit");
                      setOpenDetails(true);
                    }}
                  />
                </button>
              </td>
              <td className="w-[10%] flex justify-start items-center">
                <button
                  className="flex justify-start items-center"
                  onClick={() =>
                    openModal(
                      <DeletePartnerSuperAdmin
                        idPartner={partner.id}
                        closeModal={closeModal}
                      />
                    )
                  }
                >
                  <img src={trash} alt="trash" />
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      {displayDiv && openDetails && <CreatePartner />}
    </div>
  );
};

export default SuperAdminPartnerTable;
