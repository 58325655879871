import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { getProgressData } from "../../../../../services/Utils/functionService";
import { usePartnerRequestsStore2 } from "../../../../../stores/Cac/Partner/partnerRequests";
import { useNotifsStore } from "../../../../../stores/Utils/notifs";
import { getPercentageMission } from "../../../../../services/Api/customerService";

const ProgressBar = () => {
  const { notifs } = useNotifsStore();
  const { idCustomer, idMission } = useParams();
  const { updateRequest } = usePartnerRequestsStore2();
  const [percentState, setPercentState] = useState<any>({});
  const [countsState, setCountsState] = useState<any>({});

  useEffect(() => {
    getPercentageMission(Number(idCustomer), Number(idMission))
      .then((data) => {
        setPercentState(data.percentage);
        setCountsState(data.counts);
      })
      .catch((error) => console.log(error));
  }, [idMission, updateRequest, notifs]);

  const progressData = getProgressData(percentState, countsState);

  return (
    <div className="w-full sm:w-[30%] xl:w-[60%] h-4 rounded-full bg-gray-300">
      <div className="flex m-auto h-full w-[250px] xl:w-[500px] ">
        {progressData?.map((data, index) => (
          <div
            key={index}
            style={{
              width: `${data.percent}%`,
            }}
            className={`${data.classes} flex justify-center items-center`}
          >
            <p className="text-xs">{data.count !== 0 ? `${data.count}` : ""}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default React.memo(ProgressBar);
