import { usePartnerMissionStore } from "../../../../stores/Cac/Partner/partnerMission";

const UploadMission = () => {
  const { dataMission, setDataMission } = usePartnerMissionStore();

  const handleUpload = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length > 0) {
      const file = e.target.files[0];
      setDataMission({ ...dataMission, file: file });
    }
  };

  return (
    <div className="w-6/12 m-auto flex flex-col justify-center items-start mb-4">
      <p>Télécharger la lettre de mission : </p>
      <input
        type="file"
        name="doc"
        id="fileUpload"
        onChange={handleUpload}
        className="w-full py-1 m-auto"
      />
    </div>
  );
};

export default UploadMission;
