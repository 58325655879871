import { useEffect } from "react";
import { getUsersCustomerToMission } from "../../../../services/Api/customerService";
import { useParams } from "react-router-dom";
import { usePartnerMissionStore } from "../../../../stores/Cac/Partner/partnerMission";
import { usePartnerRequestsStore2 } from "../../../../stores/Cac/Partner/partnerRequests";

const SelectedUsersRequest = () => {
  const { idCustomer, idMission } = useParams();
  const { usersClients, setUsersClients } = usePartnerMissionStore();
  const { selectedUserToAffected, setSelectedUserToAffected } =
    usePartnerRequestsStore2();

  useEffect(() => {
    getUsersCustomerToMission(Number(idCustomer), idMission)
      .then((data) => setUsersClients(data))
      .catch((error) => console.log(error));
  }, [idMission]);

  const handleSelectChange = (e: any) => {
    const selectedId = parseInt(e.target.value);
    const selectedUser = usersClients.find(
      (client) => client.id === selectedId
    );
    setSelectedUserToAffected({
      id: selectedId,
      fullName: selectedUser.fullName,
    });
  };

  return (
    <div className="w-full flex justify-start items-center font-BarlowCondensed ">
      <p className="mr-4">Affecter </p>
      <select
        name="usersClients"
        id="usersClients"
        onChange={handleSelectChange}
        value={selectedUserToAffected.id}
        className="w-full h-8 border-[1px] border-gray-300 rounded-sm mr-2 shadow-md text-nxm-darkGray text-sm "
      >
        <option value="" className="text-gray">
          Selectionner un utilisateur
        </option>
        {usersClients?.map((client) => (
          <option
            value={client?.id}
            key={client?.id}
            className="text-nxm-darkGray text-sm"
          >
            {client?.fullName}
          </option>
        ))}
      </select>
    </div>
  );
};

export default SelectedUsersRequest;
