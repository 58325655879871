import { useEffect, useState } from "react";
import Nexum from "../../../assets/logos/logoNexum.png";
import arrow from "../../../assets/icons/arrow-right-blue.svg";
import download from "../../../assets/icons/download-white.svg";
import { useTokenContext } from "../../../context/tokenContext";
import { useLocation } from "react-router-dom";
import {
  getFile,
  getFileData,
  isValidToken,
} from "../../../services/Api/workingFolderService";
import {
  showToastError,
  showToastSuccess,
} from "../../../services/Toasts/toasts";

const CustomerWorkingFolder = () => {
  const [password, setPassword] = useState("");
  const [open, setOpen] = useState(false);
  const { setToken } = useTokenContext();
  const [validToken, setValidToken] = useState(false);
  const [data, setData] = useState<any>();
  const [dataName, setDataName] = useState<any>();
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const token = params.get("token");

  useEffect(() => {
    if (token) {
      setToken(1);
      isValidToken(token)
        .then((res) => {
          setDataName(res.data);
          setValidToken(true);
        })
        .catch(() => setValidToken(false));
    }
  }, []);

  const handlePassword = () => {
    getFileData(String(token), password)
      .then((data) => {
        setData(data);
        setOpen(true);
      })
      .catch(() => {
        showToastError("Mot de passe incorrect");
        setOpen(false)
      });
  };

  const handleDownload = () => {
    getFile(String(token), password, data.filePath)
      .then(() => showToastSuccess("Fichier téléchargé avec succès"))
      .catch((err) => {
        console.log(err);
        showToastError("Erreur lors du téléchargement du fichier");
      });
  };

  return (
    <div className="w-screen h-screen bg-background bg-no-repeat bg-cover bg-center bg-fixed bg-opacity-50 backdrop-filter backdrop-blur-lg flex flex-col justify-start items-center">
      <div className="w-full flex justify-start items-start">
        <img src={Nexum} alt="logo" className="w-20 h-20" />
      </div>
      <div className="w-11/12 flex flex-col justify-start items-center 2xl:mt-20 mt-5">
        <h2 className="text-nxm-darkGray font-light text-2xl 2xl:text-4xl font-K2D">
          Accès dossier de travail
        </h2>
        <div className="bg-white rounded-md flex flex-col justify-center items-center w-4/12 m-auto font-BarlowCondensed text-lg 2xl:mt-10 mt-5 text-nxm-darkGray">
          {validToken && !open && (
            <>
              <p className="w-4/6 m-auto text-center mt-5 font-bold text-2xl">
                Bienvenue sur Nexum !
              </p>
              <p className="w-4/6 m-auto text-center mt-2">
                {" "}
                <span className="font-bold">
                  {dataName?.nameManager} – {dataName?.namePartner}{" "}
                </span>
                <p className="mt-2">
                  souhaiterait vous communiquer l’accès au dossier de travail
                  dans le cadre de la mission : <br />
                </p>
                <p className="font-bold mt-5 text-xl ">
                  {dataName?.nameCategoryMission}-{dataName?.exercice}
                </p>
                <p>
                  <br />A des fins de sécurité, nous vous invitons à renseigner
                  le mot de passe que vous avez reçu par mail :{" "}
                </p>
              </p>
              <p className="w-4/6 m-auto text-center my-5">
                Veuillez saisir le mot de passe :
              </p>
              <input
                type="password"
                className="border-b-[1px] border-nxm-darkBlue w-2/6 h-10 2xl:mb-10 mb-5 bg-white focus:outline-none"
                pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$"
                onChange={(e) => setPassword(e.target.value)}
                minLength={10}
                maxLength={200}
                value={password}
                name="password"
                id="password"
                placeholder="password"
                required
              />
              <button
                className="border-[1px] border-nxm-blueDark bg-nxm-blueDark rounded-md text-white font-K2D py-2 px-6 mb-5"
                onClick={handlePassword}
              >
                Valider
              </button>
              <img
                src={arrow}
                alt="fleche"
                className="w-5 h-5 rotate-90 mb-2"
              />
            </>
          )}
          {!validToken && !open && (
            <p className="w-4/6 m-auto text-center mt-5 font-base text-xl mb-5">
              Cet accès à expiré, nous vous invitons à vous rapprocher de votre
              interlocuteur, évoqué dans le mail contenant ce lien.
            </p>
          )}
          {open && (
            <div className="flex flex-col justify-center items-center border-[1px] w-4/5 rounded-lg mb-10 mt-5 shadow-lg">
              <p className="text-nxm-darkBlue font-K2D text-lg my-5">
                Voici le dossier travail de {dataName?.nameManager}
              </p>
              <button
                onClick={handleDownload}
                className="border-[1px] border-nxm-blueDark bg-nxm-blueDark rounded-md text-white font-K2D py-2 px-6 mb-5 flex justify-center items-center "
              >
                <img src={download} alt="download" className="w-5 h-5 mr-2" />
                Télécharger
              </button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default CustomerWorkingFolder;
