import React, { useEffect } from "react";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { useServiceStore } from "../../../stores/admin/service";
import Users from "../../../assets/icons/users.svg";
import Folder from "../../../assets/icons/folder.svg";
import Categories from "../../../assets/icons/bookmark.svg";
import WhiteTriangle from "../../../assets/icons/whiteTriangle.svg";
import Layout from "../../../assets/icons/layout.svg";
import useModal from "../../../hooks/useModal";
import logout from "../../../assets/icons/logout.svg";
import ModalDeconnexion from "../../Layout/ModalDeconnexion";
import nexumX from "../../../assets/logos/NexumX.png";

const NavBarAdmin = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { navBarSelected, setNavBarSelected } = useServiceStore();
  const { modalContent, isModalOpen, openModal, closeModal } = useModal();
  const { idPartner } = useParams();

  useEffect(() => {
    if (location.pathname.includes("/users/partner"))
      setNavBarSelected("users");
  }, []);

  useEffect(() => {
    if (navBarSelected === "users" && !location.pathname.includes("customer"))
      navigate(`/partner/${idPartner}/admin/users/partner`);
    if (navBarSelected === "folders" && !location.pathname.includes("mission"))
      navigate(`/partner/${idPartner}/admin/clientfile`);
    if (
      navBarSelected === "categories" &&
      !location.pathname.includes("assignment")
    )
      navigate(`/partner/${idPartner}/admin/category`);
  }, [navBarSelected]);

  return (
    <div className="w-16 md:w-44 lg:w-52 2xl:w-80 h-full border-r-[1px] border-nxm-blueDark shadow-xl rounded-r-3xl rounded-tr-3xl flex flex-col justify-start items-center bg-nxm-blueLight z-50">
      {isModalOpen ? modalContent : null}
      <div className="w-full bg-nxm-blueLight h-24 mt-5 flex justify-center items-center 2xl:h-[184px] md:h-[182px] lg:h-[170px] 3xl:h-[240px]">
        <img src={nexumX} alt="logo Nexum" className="w-20 h-20 mb-2" />
      </div>
      <div className="w-full flex justify-center items-start rounded-tr-3xl">
        <ul className="w-[100px] bg-nxm-blueDark h-screen flex flex-col items-center justify-start text-sm font-K2D">
          <li
            className={`w-full h-16 flex justify-center items-center cursor-pointer ${
              navBarSelected === "users" ? "bg-nxm-blueLight" : ""
            }`}
            onClick={() => setNavBarSelected("users")}
          >
            <img src={Users} alt="icon utilisateurs" />
          </li>
          <li
            className={`w-full h-16 flex justify-center items-center cursor-pointer ${
              navBarSelected === "folders" ? "bg-nxm-blueLight" : ""
            }`}
            onClick={() => setNavBarSelected("folders")}
          >
            <img src={Folder} alt="icon fichiers" />
          </li>
          <li
            className={`w-full h-16 flex justify-center items-center cursor-pointer ${
              navBarSelected === "categories" ? "bg-nxm-blueLight" : ""
            }`}
            onClick={() => setNavBarSelected("categories")}
          >
            <img src={Categories} alt="icon categories" />
          </li>
          <li
            className={`w-full h-16 flex justify-center items-center cursor-pointer ${
              navBarSelected === "layout" ? "bg-nxm-blueLight" : ""
            }`}
          >
            <Link to={`/partner/${idPartner}/customers`}>
              <img src={Layout} alt="icon Layout" />
            </Link>
          </li>
          <li
            className={`w-full h-16 flex justify-center items-center cursor-pointer ${
              navBarSelected === "layout" ? "bg-nxm-blueLight" : ""
            }`}
            onClick={() => {
              openModal(<ModalDeconnexion closeModal={closeModal} />);
              setNavBarSelected("deconnexion");
            }}
          >
            <img src={logout} alt="deconnexion" className="w-6 h-6" />
          </li>
        </ul>
        <ul className="w-[300px] h-screen flex flex-col items-start justify-start text-sm font-K2D">
          {navBarSelected === "users" ? (
            <>
              <li className="py-2 px-1 cursor-pointer w-full text-start pt-4 h-14">
                <Link
                  to={`/partner/${idPartner}/admin/users/partner`}
                  className="flex justify-center items-center w-full"
                >
                  {location.pathname ===
                  `/partner/${idPartner}/admin/users/partner` ? (
                    <img
                      src={WhiteTriangle}
                      alt="flèche"
                      className="w-2 h-2 rotate-90 mr-2"
                    />
                  ) : null}
                  <p>Partenaires</p>
                  {location.pathname ===
                  `/partner/${idPartner}/admin/users/partner` ? (
                    <img
                      src={WhiteTriangle}
                      alt="flèche"
                      className="w-2 h-2 -rotate-90 ml-2"
                    />
                  ) : null}
                </Link>
              </li>
              <li className="py-2 px-2 cursor-pointer w-full pt-4 h-14">
                <Link
                  to={`/partner/${idPartner}/admin/users/customer`}
                  className="flex justify-center items-center"
                >
                  {location.pathname ===
                  `/partner/${idPartner}/admin/users/customer` ? (
                    <img
                      src={WhiteTriangle}
                      alt="flèche"
                      className="w-2 h-2 rotate-90 mr-2"
                    />
                  ) : null}
                  <p>Clients</p>
                  {location.pathname ===
                  `/partner/${idPartner}/admin/users/customer` ? (
                    <img
                      src={WhiteTriangle}
                      alt="flèche"
                      className="w-2 h-2 -rotate-90 ml-2"
                    />
                  ) : null}
                </Link>
              </li>
            </>
          ) : navBarSelected === "folders" ? (
            <>
              <li className="py-2 px-2 cursor-pointer w-full pt-4 h-14">
                <Link
                  to={`/partner/${idPartner}/admin/clientfile`}
                  className="flex justify-center items-center"
                >
                  {location.pathname.includes("clientfile") ? (
                    <img
                      src={WhiteTriangle}
                      alt="flèche"
                      className="w-2 h-2 rotate-90 mr-2"
                    />
                  ) : null}
                  <p>Dossiers clients</p>
                  {location.pathname.includes("clientfile") ? (
                    <img
                      src={WhiteTriangle}
                      alt="flèche"
                      className="w-2 h-2 -rotate-90 ml-2"
                    />
                  ) : null}
                </Link>
              </li>
              <li className="py-2 px-2 cursor-pointer w-full pt-4 h-14">
                <Link
                  to={`/partner/${idPartner}/admin/mission`}
                  className="flex justify-center items-center"
                >
                  {location.pathname.includes("mission") ? (
                    <img
                      src={WhiteTriangle}
                      alt="flèche"
                      className="w-2 h-2 rotate-90 mr-2"
                    />
                  ) : null}
                  <p>Missions</p>
                  {location.pathname.includes("mission") ? (
                    <img
                      src={WhiteTriangle}
                      alt="flèche"
                      className="w-2 h-2 -rotate-90 ml-2"
                    />
                  ) : null}
                </Link>
              </li>
            </>
          ) : navBarSelected === "categories" ? (
            <>
              <li className="py-2 px-2 cursor-pointer w-full pt-4 h-14">
                <Link
                  to={`/partner/${idPartner}/admin/category`}
                  className="flex justify-center items-center"
                >
                  {location.pathname.includes("category") ? (
                    <img
                      src={WhiteTriangle}
                      alt="flèche"
                      className="w-2 h-2 rotate-90 mr-2"
                    />
                  ) : null}
                  <p>Categories</p>
                  {location.pathname.includes("category") ? (
                    <img
                      src={WhiteTriangle}
                      alt="flèche"
                      className="w-2 h-2 -rotate-90 ml-2"
                    />
                  ) : null}
                </Link>
              </li>
            </>
          ) : null}
        </ul>
      </div>
    </div>
  );
};

export default React.memo(NavBarAdmin);
