import { NavLink, useParams } from "react-router-dom";

import Camembert from "./Camembert";
import { useRef, useState } from "react";
import { dateFormat, formatData, splitMissionName } from "../../../../../services/Utils/functionService";

const CardMission = ({ mission }: any) => {
  const divRef = useRef<HTMLDivElement>(null);
  const [isFocused, setIsFocused] = useState(false);
  const [position, setPosition] = useState({ x: 0, y: 0 });
  const [opacity, setOpacity] = useState(0);

  const { idPartner } = useParams();

  const handleMouseMove = (e: React.MouseEvent<HTMLDivElement>) => {
    if (!divRef.current || isFocused) return;

    const div = divRef.current;
    const rect = div.getBoundingClientRect();

    setPosition({ x: e.clientX - rect.left, y: e.clientY - rect.top });
  };

  const handleFocus = () => {
    setIsFocused(true);
    setOpacity(1);
  };

  const handleBlur = () => {
    setIsFocused(false);
    setOpacity(0);
  };

  const handleMouseEnter = () => {
    setOpacity(1);
  };

  const handleMouseLeave = () => {
    setOpacity(0);
  };

  let data: any[] = [];
  let formattedData;

  if (mission?.missions) {
    data = [mission.missions[0]?.value];
    formattedData = formatData(data);
  }

  const borderColor =
    mission?.color === "red"
      ? "bg-red-100"
      : mission?.color === "yellow"
      ? "bg-yellow-200"
      : mission?.color === "lightgreen"
      ? "bg-green-100"
      : mission?.color === "green"
      ? "bg-green-300"
      : "bg-gray-100";

  return (
    <div
      ref={divRef}
      onMouseMove={handleMouseMove}
      onFocus={handleFocus}
      onBlur={handleBlur}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      className={`relative flex h-[300px] w-10/12 my-2 text-center items-center justify-center overflow-hidden rounded-xl ${borderColor} shadow-xl`}
    >
      <div
        className="pointer-events-none absolute -inset-px opacity-0 transition duration-300 w-full h-full z-10"
        style={{
          opacity,
          background: `radial-gradient(400px circle at ${position.x}px ${position.y}px,rgba(255,255,255,0.8), transparent 40%)`,
        }}
      />
      <NavLink to={`/partner/${idPartner}/customers/${mission.idCustomer}/missions/${mission.id}/requests`} className="z-20">
        <div className="flex flex-col">
          <p
            className={
              mission?.color === "grey"
                ? "text-sm font-bold p-1 mt-3"
                : "text-sm font-bold p-1"
            }
          >
            {splitMissionName(mission?.name)}
          </p>
          <p className="text-xs xl:text-sm font-thin px-1">
            Fin de mission : {dateFormat(mission?.dueDate)}
          </p>
          <p className="text-xs xl:text-sm font-thin px-1 italic">
            {mission?.fullName}
          </p>
          <div className="flex flex-col justify-center items-center">
            {formattedData ? (
              <Camembert data={formattedData} />
            ) : (
              <p className="w-10/12 text-center text-xs xl:text-sm  m-auto mt-14 mb-32">
                Il n'y a pas encore de requêtes pour cette mission
              </p>
            )}
          </div>
          {formattedData ? (
            <p className="text-xs xl:text-sm font-bold px-1 pb-3 xl:mt-8">
              Taux de progression : {Math.floor(mission?.progress) || 0} %
            </p>
          ) : null}
        </div>
      </NavLink>
    </div>
  );
};

export default CardMission;
