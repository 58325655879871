import { axiosWithToken } from "./axiosService";

export const readNotification = async (idCategory: number) => {
    try {
        const response = await axiosWithToken().put(`api/notification/category/${idCategory}/read`);
        return response;
    } catch (err) {
        console.log(err);
        throw err;
    }
}