import NavBarAdmin from "../../components/Admin/Layout/NavBarAdmin";
import { useEffect } from "react";
import CustomerAdminTable from "../../components/Admin/Table/CustomerAdminTable";
import SearchBar from "../../components/Admin/Services/SearchBar";
import { useServiceStore } from "../../stores/admin/service";
import { usePartnerStore } from "../../stores/admin/users";
import { useParams } from "react-router-dom";
import { getUsersCustomersByIdPartner } from "../../services/Api/partnerService";

const AdminCustomer = () => {
  // Store
  const { edit, update, searchTerm } = useServiceStore();
  const { usersCustomer, setUsersCustomer } = usePartnerStore();
  const { idPartner } = useParams();

  // Function to display users partner filtered or not
  useEffect(() => {
    if (searchTerm !== "") {
      const filteredRequests = usersCustomer.filter(
        (item: any) =>
          item?.lastName.toLowerCase().includes(searchTerm.toLowerCase()) ||
          item?.firstName.toLowerCase().includes(searchTerm.toLowerCase()) ||
          item?.eMail.toLowerCase().includes(searchTerm.toLowerCase())
      );
      setUsersCustomer(filteredRequests);
    } else {
      getUsersCustomersByIdPartner(Number(idPartner))
        .then((data) => setUsersCustomer(data))
        .catch((error) => console.log(error));
    }
  }, [searchTerm, update]);

  return (
    <div className="w-screen h-screen bg-background bg-no-repeat bg-cover bg-center bg-fixed bg-opacity-50 backdrop-filter backdrop-blur-lg overflow-hidden flex flex-col">
      <div className="flex w-screen h-screen">
        <NavBarAdmin />
        <div className="flex flex-col justify-start items-center w-full h-full pt-5">
          <div className="w-10/12 h-full">
            <h2 className="text-nxm-darkGray font-light 2xl:text-3xl text-xl font-K2D 2xl:py-5 py-2">
              Liste des clients :
            </h2>
            <div className="flex justify-between w-full items-center">
              <div className="my-5 w-auto">
                <SearchBar />
              </div>
              {edit ? (
                <p className="mr-36 font-BarlowCondensed">
                  Vous êtes en mode édition{" "}
                </p>
              ) : (
                <p></p>
              )}
              <></>
            </div>
            <div className="flex 2xl:h-5/6 h-[70%] w-full">
              <CustomerAdminTable />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdminCustomer;
