import { useEffect } from "react";
import { useUtilsStore } from "../../../../stores/Utils/selects";
import { getStates } from "../../../../services/Api/stateService";

const SelectState = () => {
  const { states, setStates, selectedState, setSelectedState } =
    useUtilsStore();

  useEffect(() => {
    getStates()
      .then((data) => {
        const stateWithoutPending = data.filter((state: any) => state.id !== 4);
        setStates(stateWithoutPending);
      })
      .catch((error) => console.log(error));
  }, []);

  return (
    <select
      name="state"
      id="state"
      onChange={(e) => {
        setSelectedState(parseInt(e.target.value));
      }}
      value={selectedState}
      className="hidden sm:flex justify-center items-center sm:w-36 sm:h-6 mt-1 border-[1px] border-gray-300 rounded-sm mr-2 shadow-md"
    >
      {selectedState === 0 && <option value="">Choisir un état</option>}
      {states?.map((state) => (
        <option value={state?.id} key={state?.id}>
          {state?.state}
        </option>
      ))}
    </select>
  );
};

export default SelectState;
