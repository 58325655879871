import { create } from "zustand";
type Settingstate = {
    showSettings: boolean;
    setShowSettings: (showSettings: boolean | ((prevShowSettings: boolean) => boolean)) => void;
  };
  
  export const useSettingsStore = create<Settingstate>((set) => ({
    showSettings: false,
    setShowSettings: (showSettings: boolean | ((prevShowSettings: boolean) => boolean)) =>
      set((state) => ({
        showSettings: typeof showSettings === 'function' ? showSettings(state.showSettings) : showSettings,
      }))
  }));
  