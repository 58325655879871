import { useEffect } from "react";
import { useUtilsStore } from "../../../../stores/Utils/selects";
import { usePartnerRequestsStore2 } from "../../../../stores/Cac/Partner/partnerRequests";
import { getCategoriesDocRequestByIdPartner } from "../../../../services/Api/partnerService";
import { useParams } from "react-router-dom";

const SelectCategories = () => {
  const { choiceModal, missionData, dataRequest, setDataRequest } =
    usePartnerRequestsStore2();
  const { categories, setCategories, selectedCategory, setSelectedCategory } =
    useUtilsStore();
  const { idPartner } = useParams();

  useEffect(() => {
    if (idPartner)
      getCategoriesDocRequestByIdPartner(
        parseInt(idPartner),
        missionData?.categoryId
      )
        .then((data) => setCategories(data))
        .catch((error) => console.log(error));
  }, [choiceModal, missionData?.id]);

  return (
    <select
      name="categories"
      id="category"
      onChange={(e) => {
        setSelectedCategory(parseInt(e.target.value));
        setDataRequest({
          ...dataRequest,
          categoryId: parseInt(e.target.value),
        });
      }}
      value={selectedCategory}
      className="w-full h-10 p-1 border-[1px] border-gray-300 rounded-md mr-2 shadow-md font-BarlowCondensed"
    >
      {selectedCategory === 0 && (
        <option value="" className="text-slate-300">
          Choisir une catégorie
        </option>
      )}
      {categories?.map((category) => (
        <option value={category?.id} key={category?.id}>
          {category?.name}
        </option>
      ))}
    </select>
  );
};

export default SelectCategories;
