import { useLocation } from "react-router-dom";
import { PieChart, Pie, Cell, Tooltip } from "recharts";

const COLORS = ["#F9D923", "#EB5353", "#187498", "#36AE7C"];
const GREY = "#d3d3d3";

const renderLabel = ({
  cx,
  cy,
  midAngle,
  innerRadius,
  outerRadius,
  percent,
}: {
  cx: number;
  cy: number;
  midAngle: number;
  innerRadius: number;
  outerRadius: number;
  percent: number;
}) => {
  if (percent === 0) {
    return null;
  }
  const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
  const x = cx + radius * Math.cos(-midAngle * (Math.PI / 180));
  const y = cy + radius * Math.sin(-midAngle * (Math.PI / 180));

  return (
    <text
      x={x}
      y={y}
      fill="black"
      textAnchor={x > cx ? "start" : "end"}
      dominantBaseline="central"
      fontSize="20"
      fontWeight="bold"
    >
      {`${(percent * 100).toFixed(0)}%`}
    </text>
  );
};

const Camembert = ({ data }: any) => {
  const location = useLocation();
  const idMatch = location.pathname.match(/partner\/monitoring\/(\d+)/);
  const missionMonitoringMatch =
    location.pathname.includes("monitoring/mission");
  const id = idMatch ? idMatch[1] : null;
  const isMissionMonitoring = missionMonitoringMatch ? true : false;
  const allValuesZero = (data: any) => {
    return data?.every((item: any) => item.value === "NaN%");
  };
  const isAllValuesZero = allValuesZero(data);

  return (
    <div className="flex justify-center items-center">
      <PieChart
        width={isMissionMonitoring ? 150 : id ? 240 : 370}
        height={isMissionMonitoring ? 150 : id ? 220 : 370}
      >
        <Pie
          data={data}
          cx={isMissionMonitoring ? 75 : id ? 100 : 190}
          cy={isMissionMonitoring ? 75 : id ? 110 : 160}
          labelLine={false}
          label={renderLabel}
          outerRadius={isMissionMonitoring ? 60 : id ? 80 : 160}
          fill="#8884d8"
          dataKey="value"
        >
          {data?.map((_entry: any, index: number) => (
            <Cell
              key={`cell-${index}`}
              fill={
                isMissionMonitoring && isAllValuesZero
                  ? GREY
                  : COLORS[index % COLORS.length]
              }
            />
          ))}
        </Pie>
        <Tooltip />
      </PieChart>
    </div>
  );
};

export default Camembert;
