import useModal from "../../hooks/useModal";
import ModalDeconnexion from "../Layout/ModalDeconnexion";
import Logo from "../../assets/logos/NexumX.png";
import Deconnexion from "../../assets/icons/log-out.svg";
import { useSuperAdminCreatePartnerStore } from "../../stores/superadmin/createPartner";
import { useSuperAdminPartnerStore } from "../../stores/superadmin/superadminpartner";

const HeaderSuperAdmin = () => {
  const { modalContent, isModalOpen, openModal, closeModal } = useModal();
  const { openDetails } = useSuperAdminCreatePartnerStore();
  const { modePartner } = useSuperAdminPartnerStore();

  return (
    <div className="flex h-[10%] w-screen ">
      {isModalOpen ? modalContent : null}
      <div className="flex w-full items-center justify-between py-4 z-10">
        <img src={Logo} alt="logo NEXUM" className="w-12 h-12 ml-5" />
        <p className="text-2xl font-bold text-nxm-darkGray font-K2D">
          {" "}
          {modePartner === "edit"
            ? "Modification d'un partenaire :"
            : !openDetails
            ? "Partenaires :"
            : "Création d'un partenaire :"}
        </p>
        <div className="flex justify-center items-center">
          <button
            type="button"
            className="absolute right-10"
            onClick={() =>
              openModal(<ModalDeconnexion closeModal={closeModal} />)
            }
            title="Se déconnecter"
          >
            <img src={Deconnexion} alt="deconnexion" className="w-6 h-6" />
          </button>
        </div>
      </div>
    </div>
  );
};

export default HeaderSuperAdmin;
