import { create } from "zustand";

type ServiceState = {
    edit: boolean;
    setEdit: (edit: boolean) => void;

    update: boolean;
    setUpdate: (update: boolean) => void;

    searchTerm: string
    setSearchTerm: (searchTerm: string) => void;

    navBarSelected: string;
    setNavBarSelected: (navBarSelected: string) => void;
}   

export const useServiceStore = create<ServiceState>((set) => ({
    edit: false,
    setEdit: (edit) => set({ edit }),

    update: false,
    setUpdate: (update) => set({ update }),

    searchTerm: "",
    setSearchTerm: (searchTerm) => set({ searchTerm }),

    navBarSelected: "users",
    setNavBarSelected: (navBarSelected) => set({ navBarSelected }),
}));