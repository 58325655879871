import { useEffect } from "react";
import { getUserCustomer } from "../../../../services/Api/customerService";
import { usePartnerHomeCustomerStore } from "../../../../stores/Cac/Partner/partnerHomeCustomer";
import { getCustomerData } from "../../../../services/Cac/functionToUpdate";
import { ManagerObject } from "../../../../interfaces/modales";
import {
  showToastError,
  showToastSuccess,
} from "../../../../services/Toasts/toasts";
import {
  createCustomerByIdPartner,
  getUserPartnerByIdCollab,
  updateCustomerByIdPartner,
} from "../../../../services/Api/partnerService";
import { useParams } from "react-router-dom";
import { useTokenContext } from "../../../../context/tokenContext";

const PartnerModalCreateUpdateCustomer = () => {
  const { idPartner } = useParams();
  const { userId } = useTokenContext();
  // Store
  const {
    choiceModal,
    setChoiceModal,
    partners,
    setPartners,
    name,
    setName,
    checkboxValues,
    setCheckboxValues,
    idCustomer,
    setIdCustomer,
    setPartnersAssigned,
    customersAssigned,
    setCustomersAssigned,
  } = usePartnerHomeCustomerStore();

  const handleReset = () => {
    setName("");
    setCheckboxValues({});
    setPartnersAssigned([]);
    setCustomersAssigned([]);
    setIdCustomer(0);
    setChoiceModal("");
  };

  // Get all users partners
  useEffect(() => {
    if (idPartner) {
      getUserPartnerByIdCollab(Number(idPartner))
        .then((data) => {
          // Filter the user connected
          const users = data.filter((user: any) => user.id !== userId);
          setPartners(users);
        })
        .catch((error) => console.log(error));
    }
  }, []);

  const handleCheckboxChange = (value: number, checked: boolean) => {
    setCheckboxValues({ ...checkboxValues, [value]: checked });
  };

  const handleCreateOrUpdateClientFile = async () => {
    if (name === "") {
      showToastError("Votre client est mal renseigné !");
    } else if (name.length >= 30) {
      showToastError(
        "Le nom du client est trop long ne doit pas dépasser 30 caractères."
      );
    } else {
      try {
        if (idPartner) {
          if (choiceModal === "create") {
            const dataForSend = {
              name: name,
              partnerUsers: Object.entries(checkboxValues)
                .filter(([_, value]) => value)
                .map(([key]) => Number(key)),
              existingCustomerUsers: [],
            };
            const idClient = await createCustomerByIdPartner(
              dataForSend,
              Number(idPartner)
            );
            if (idClient) {
              handleReset();
              setCheckboxValues({});
            }
          } else {
            const dataForUpdate = {
              name: name,
              partnerUsers: Object.entries(checkboxValues)
                .filter(([_, value]) => value)
                .map(([key]) => Number(key)),
              existingCustomerUsers: customersAssigned?.map((user) => user.id),
            };

            const response = await updateCustomerByIdPartner(
              dataForUpdate,
              Number(idPartner),
              idCustomer
            );
            if (response) {
              handleReset();
              setCheckboxValues({});
              showToastSuccess("Votre modification à bien été effectuée !");
            }
          }
        }
      } catch (error) {
        console.error(error);
        showToastError("Une erreur est survenue !");
      }
    }
  };

  // Update //
  // Function to get the data of a client with collaborators assigned
  useEffect(() => {
    if (idCustomer === 0 || choiceModal === "create") return;
    const fetchData = async () => {
      try {
        const { clientName, collabsUserAssignList, checkboxValues } =
          await getCustomerData(idCustomer);
        setName(clientName);
        setPartnersAssigned(collabsUserAssignList);
        setCheckboxValues(checkboxValues);
      } catch (error) {
        console.log(error);
      }
    };

    const fetchCustomerAssigned = async () => {
      try {
        const usersCustomer = await getUserCustomer(idCustomer);
        setCustomersAssigned(usersCustomer);
      } catch (error) {
        console.log(error);
      }
    };
    fetchCustomerAssigned();
    fetchData();
  }, [idCustomer, choiceModal]);

  // Function to handle the checkbox change
  const handleCheckboxChangeWrapper = (user: ManagerObject) => {
    setCheckboxValues((prevState: { [key: number]: boolean }) => ({
      ...prevState,
      [user.id]: !prevState[user.id],
    }));
  };

  return (
    choiceModal !== "" && (
      <>
        <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none ">
          <div className="relative w-auto md:w-1/4 my-6 mx-auto max-w-3xl min-w-[55%]">
            {/*content*/}
            <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
              {/*header*/}
              <div className="flex items-center justify-center p-5 border-b border-solid border-slate-200 rounded-t font-K2D">
                {choiceModal === "create" ? (
                  <h3 className="text-xl sm:text-3xl font-semibold text-center text-nxm-darkGray">
                    Créer un dossier client
                  </h3>
                ) : (
                  <h3 className="text-xl sm:text-3xl font-semibold text-center text-nxm-darkGray">
                    Modifier le dossier client
                  </h3>
                )}
              </div>
              {/*body*/}
              <div className="w-full flex flex-col items-center justify-center bg-nxm-lightBlue bg-opacity-20 font-BarlowCondensed text-nxm-darkGray">
                <div className="flex w-11/12 items-center my-5 ">
                  <label
                    htmlFor="name"
                    className="text-md sm:text-xl mb-5 mr-5 pt-5 "
                  >
                    Nom du dossier client :
                  </label>
                  <input
                    type="text"
                    value={name}
                    className="border-b-[1px] border-nxm-darkGray w-5/12 h-auto p-1 bg-transparent focus:outline-none text-xl"
                    onChange={(e) => setName(e.target.value)}
                    minLength={10}
                    maxLength={200}
                    name="name"
                    id="name"
                    placeholder="Nom du client"
                    required
                  />
                </div>

                <div className="text-md sm:text-xl flex flex-col w-11/12 m-auto h-auto mb-10">
                  <label htmlFor="name" className="text-md sm:text-xl mb-4">
                    Rôle collaborateurs affectés :
                  </label>
                  <div className="w-11/12 m-auto grid grid-cols-2 sm:grid-cols-4">
                    {partners?.map((user) => (
                      <label
                        key={user?.id}
                        className="flex items-center justify-start mb-2 mt-2 text-xl font-light"
                      >
                        <input
                          type="checkbox"
                          name={user?.fullName}
                          checked={checkboxValues[user?.id] || false}
                          onChange={(event) => {
                            {
                              if (choiceModal === "create") {
                                handleCheckboxChange(
                                  user.id,
                                  event.target.checked
                                );
                              }
                            }

                            if (choiceModal === "update")
                              handleCheckboxChangeWrapper(user);
                          }}
                          className="mr-2 h-4 w-4"
                        />
                        {user?.fullName}
                      </label>
                    ))}
                  </div>
                </div>
                <p className="italic text-center text-thin text-sm">
                  Tous les managers et administrateurs sont affectés de base.
                </p>
                {choiceModal === "update" ? (
                  <div className="text-md sm:text-xl flex flex-col w-11/12 h-auto">
                    <label htmlFor="name" className="text-xl mb-2 my-10">
                      Clients affectés :
                    </label>

                    {customersAssigned?.length > 0 ? (
                      <div className="w-11/12 m-auto grid grid-cols-2 sm:grid-cols-4 mt-5 ">
                        {customersAssigned?.map((user) => (
                          <div
                            key={user?.id}
                            className="text-center text-white flex mb-5 border-[1px] bg-nxm-darkBlue border-nxm-darkBlue rounded-md w-10/12 p-2 h-10 items-center justify-center mr-5 text-lg"
                          >
                            <label>
                              {user?.firstName} {user?.lastName}{" "}
                            </label>
                          </div>
                        ))}
                      </div>
                    ) : (
                      <p className="w-full text-center text-sm py-4 italic">
                        Aucun client affecté
                      </p>
                    )}
                  </div>
                ) : null}
              </div>
              {/*footer*/}
              <div className="flex items-center justify-end p-5 border-t border-solid border-slate-200 rounded-b font-BarlowCondensed">
                <button
                  className="text-red-500 background-transparent font-bold uppercase px-5 text-lg outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
                  type="button"
                  onClick={() => {
                    handleReset();
                  }}
                >
                  Annuler
                </button>
                {choiceModal === "create" ? (
                  <button
                    className="text-white bg-gradient-to-r from-teal-400 via-teal-500 to-teal-600 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-teal-300 dark:focus:ring-teal-800 font-medium rounded-lg text-lg py-2 px-5 text-center me-2 uppercase  shadow hover:shadow-lg outline-none  ease-linear transition-all duration-150"
                    type="button"
                    onClick={() => handleCreateOrUpdateClientFile()}
                  >
                    Créer
                  </button>
                ) : (
                  <button
                    className="text-white bg-gradient-to-r from-teal-400 via-teal-500 to-teal-600 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-teal-300 dark:focus:ring-teal-800 font-medium rounded-lg text-lg py-2 px-5 text-center me-2 uppercase  shadow hover:shadow-lg outline-none  ease-linear transition-all duration-150"
                    type="button"
                    onClick={() => handleCreateOrUpdateClientFile()}
                  >
                    Modifier
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
      </>
    )
  );
};

export default PartnerModalCreateUpdateCustomer;
