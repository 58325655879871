import { showToastError } from "../Toasts/toasts";
import { normalizeFileName } from "../Utils/functionService";
import { axiosWithToken, axiosWithTokenForBlob, axiosWithTokenForFile } from "./axiosService";

export const createUserCustomer = async (idCustomer: any, data: any) => {
  try{
    const response = await axiosWithToken().post(`api/customer/${idCustomer}/user`, data);
    return response.data;
  } catch (error) {
    console.log(error);
    throw error;
  }
}

export const createMission = async (idCustomer: any, data: any) => {
  try {
    const response = await axiosWithToken().post(`api/customer/${idCustomer}/mission`, data);
    return response.data;
  } catch (error) {
    console.log(error);
    throw error;
  }
}

export const getCustomerName = async (idCustomer: number) => {
  const response = await axiosWithToken().get(`api/customer/${idCustomer}/name`);
  return response.data;
};

export const getPartnerAssociated = async (id: number ) => {
  const response = await axiosWithToken().get(`api/customer/${id}/users/partner`);
  return response.data;
};

export const getPartnerAssociatedWithAdmin = async (id: number ) => {
  const response = await axiosWithToken().get(`api/customer/${id}/users/partner/all`);
  return response.data;     
};

export const getMissions = async (idCustomer: number) => {
  const response = await axiosWithToken().get(`api/customer/${idCustomer}/missions`);
  return response.data;
};

export const getUserCustomer = async (id: any) => {
  const reponse = await axiosWithToken().get(`api/customer/${id}/users`);
  return reponse.data;
}

export const deleteClient = async (id: number) => {
  try {
    await axiosWithToken().post(`api/customer/${id}/close`);
    return true;
  } catch (error) {
    console.log(error);
    return false;
  }
};

export const getUsersPartnerToMission = async (
 idCustomer: number, idMission: number
) => {
  const response = await axiosWithToken().get(
    `api/customer/${idCustomer}/mission/${idMission}/user/partner`
  );
  return response;
};

export const getUsersCustomerToMission = async (
  idCustomer: number, idMission: any
) => {
  const response = await axiosWithToken().get(
    `api/customer/${idCustomer}/mission/${idMission}/user/customer`
  );
  return response.data;
};

export const getDocRequests = async (idCustomer: number, idMission: number) => {
  const response = await axiosWithToken().get(`api/customer/${idCustomer}/mission/${idMission}/docrequests`);
  return response.data;
};

export const getMissionDataByCustomer = async (idCustomer: number, idMission: number) => {
  const response = await axiosWithToken().get(`api/customer/${idCustomer}/mission/${idMission}/data`)
  return response.data;
};

export const getPercentageMission = async (idCustomer: number, idMission: any) => {
  const response = await axiosWithToken().get(`api/customer/${idCustomer}/mission/${idMission}/percentage`);
  return response.data;
};

export const createRequest = async (idCustomer: number, idMission: number, data: any) => {
  try {
    const result = await axiosWithToken().post(`api/customer/${idCustomer}/mission/${idMission}/docrequest`, data);
    return result.data.id;
  }
  catch (err) {
    console.log(err);
    throw err;
  }
}

export const requestReminderLate = async (idCustomer: number, idMission: number, dataIds: number[]) => {
  try {
    const ids = {idsDocRequests: dataIds}
    const response = await axiosWithToken().post(`api/customer/${idCustomer}/mission/${idMission}/reminder/late`, ids)
    return response
  }  catch (err) {
    console.log(err)
    throw err
  }
}

export const requestReminderWaiting = async (idCustomer: number, idMission: number, dataIds: number[]) => {
  try {
    const ids = {idsDocRequests: dataIds}
    const response = await axiosWithToken().post(`api/customer/${idCustomer}/mission/${idMission}/reminder/waiting`, ids)
    return response
  }  catch (err) {
    console.log(err)
    throw err
  }
}

export const handleExportCSV = (idCustomer: number, idMission: number) => {
  axiosWithToken()
    .get(`api/customer/${idCustomer}/mission/${idMission}/csv`, {
      responseType: "blob",
    })
    .then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "doc-request.xlsx");
      document.body.appendChild(link);
      link.click();
    })
    .catch((error) => console.log(error));
};

export const handleExportTemplate = (idCustomer: number, idMission: number) => {
  axiosWithToken()
    .get(`api/customer/${idCustomer}/mission/${idMission}/template`, {
      responseType: "blob",
    })
    .then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "template.xlsx");
      document.body.appendChild(link);
      link.click();
    })
    .catch((error) => {
      showToastError("Une erreur est survenue.")
      console.log(error)
    });
};

export const importService = async (idCustomer: number,idMission: number, file: any) => {
  const formData = new FormData();
  formData.append("file", file);
  const downloadFile = (blob: Blob, fileName: string) => {
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.download = fileName;
    link.click();
    window.URL.revokeObjectURL(url);
  };
  
  try {
    const response = await axiosWithTokenForBlob().post(`api/customer/${idCustomer}/mission/${idMission}/csv`, formData);
  
    if (response.status === 200) {
      return 'success';
    } else {
      return 'error';
    }
  } catch (error: any) {
    if (error.response) {
      const contentType = error.response.headers["content-type"];
      if (contentType && contentType.includes("application/vnd.openxmlformats-officedocument.spreadsheetml.sheet")) {
        const blob = new Blob([error.response.data], { type: contentType });
        downloadFile(blob, "fichier_erreurs.xlsx");
      }
    }
    console.log(error);
    throw error;
  }
};

export const getLateRequests = async (idCustomer: number, idMission: number) => {
  const response = await axiosWithToken().get(`api/customer/${idCustomer}/mission/${idMission}/overdues`);
  return response.data;
};

export const getUpMissionComing = async (idCustomer: number, idMission: number) => {
  const response = await axiosWithToken().get(`api/customer/${idCustomer}/mission/${idMission}/upcoming`);
  return response.data;
};

export const getMissionDelivered = async (idCustomer: number, idMission: number) => {
  const response = await axiosWithToken().get(`api/customer/${idCustomer}/mission/${idMission}/delivered`);
  return response.data;
};

export const getMissionRejected = async (idCustomer: number, idMission: number) => {
  const response = await axiosWithToken().get(`api/customer/${idCustomer}/mission/${idMission}/rejected`);
  return response.data;
}

export const getMissionAccepted = async (idCustomer: number, idMission: number) => {
  const response = await axiosWithToken().get(`api/customer/${idCustomer}/mission/${idMission}/accepted`);
  return response.data;
}

export const archiveMission = async (idCustomer: number, idMission: number) => {
  try {
    const response = await axiosWithToken().post(`api/customer/${idCustomer}/mission/${idMission}/close`)
    return response
  } catch (err) {
    console.log(err)
  }
}

export const updateMission = async ( idCustomer: number, idMission: number, data: any) => {
  try {
    const response = await axiosWithToken().patch(`api/customer/${idCustomer}/mission/${idMission}`, data);
    return response.data;
  } catch (error) {
    console.log(error);
    throw error;
  }
}

export const postMissionFile = async (idCustomer: number, file: any, idMission: number) => {
  const formData = new FormData();
  formData.append("file", file);
  try {
    const response = await axiosWithTokenForFile().post(`api/customer/${idCustomer}/mission/${idMission}/file`, formData);
    return response.data;
  } catch (error) {
    console.log(error);
    throw error;
  }
}

export const postMissionRenew = async (idCustomer: number, idMission: number) => {
  try {
    const response = await axiosWithToken().post(`api/customer/${idCustomer}/mission/${idMission}/period/renew`);
    return response;
  } catch (error) {
    console.log(error);
    throw error;
  }
}

export const createWorkingFolder = async (idCustomer: number, data: any) => {
  try {
    const response = await axiosWithToken().post(`api/customer/${idCustomer}/workingfolder`, data);
    return response.data;
  } catch (error) {
    console.log(error);
    throw error;
  }
}

export const postWorkingFolderFile = async (idCustomer: number, idWorkingFolder: number, file: any, onUploadProgress: (progressEvent: any) => void) => {

  const uploaded = file.map((file: any) => ({ name: file.name, file }));
  
  const formData = new FormData();
  
  uploaded.forEach((file: any) => {
    const normalizedFileName = normalizeFileName(file.name);
    formData.append('file', file.file, normalizedFileName);
  });

  try {
    const response = await axiosWithTokenForBlob().post(`api/customer/${idCustomer}/workingfolder/${idWorkingFolder}/upload`, formData, 
      {
        onUploadProgress,
      });
    return response.data;
  } catch (error) {
    console.log(error);
    throw error;
  }
}

export const getWorkingFolders = async (idCustomer: number) => {
  try {
    const response = await axiosWithToken().get(`api/customer/${idCustomer}/workingfolders`);
    return response.data;
  } catch (error) {
    console.log(error);
    throw error;
  }
}

export const deleteWorkingFolder = async (idCustomer: number, idWorkingFolder: number) => {
  try {
    const response = await axiosWithToken().delete(`api/customer/${idCustomer}/workingfolder/${idWorkingFolder}`);
    return response;
  } catch (error) {
    console.log(error);
    throw error;
  }
}

export const getWorkingFolderData = async (idCustomer: number, idWorkingFolder: number) => {
  try {
    const response = await axiosWithToken().get(`api/customer/${idCustomer}/workingfolder/${idWorkingFolder}`);
    return response.data;
  } catch (error) {
    console.log(error);
    throw error;
  }
}

export const deleteFileWorkingFolder = async (idCustomer: number, idWorkingFolder: number) => {
  try {
    const response = await axiosWithToken().delete(`api/customer/${idCustomer}/workingfolder/${idWorkingFolder}/zipfile`);
    return response;
  } catch (error) {
    console.log(error);
    throw error;
  }
}

export const postAccessWorkingFolder = async (idCustomer: number, idWorkingFolder: number) => {
  try {
    const response = await axiosWithToken().post(`api/customer/${idCustomer}/workingfolder/${idWorkingFolder}/sendaccess`);
    return response;
  } catch (error) {
    console.log(error);
    throw error;
  }
}

export const revokeAccessWorkingFolder = async (idCustomer: number, idWorkingFolder: number, data: any) => {
  try {
    const response = await axiosWithToken().post(`api/customer/${idCustomer}/workingfolder/${idWorkingFolder}/access/revoke`, data);
    return response;
  } catch (error) {
    console.log(error);
    throw error;
  }
}

export const sendNewAccessWorkingFolder = async (idCustomer: number, idWorkingFolder: number, data: any) => {
  try {
    const response = await axiosWithToken().post(`api/customer/${idCustomer}/workingfolder/${idWorkingFolder}/access/send`, data);
    return response;
  } catch (error) {
    console.log(error);
    throw error;
  }
}

export const createNewUserWorkingFolder = async (idCustomer: number, idWorkingFolder: number, data: any) => {
  try {
    const response = await axiosWithToken().post(`api/customer/${idCustomer}/workingfolder/${idWorkingFolder}/access`, data);
    return response;
  } catch (error) {
    console.log(error);
    throw error;
  }
}

export const userAssignToWorkingFolder = async (idCustomer: number, idWorkingFolder: number, data: any) => {

  try {
    const response = await axiosWithToken().post(`api/customer/${idCustomer}/workingfolder/${idWorkingFolder}/users/assign`, data);
    return response;
  } catch (error) {
    console.log(error);
    throw error;
  }
}

export const userUnassignToWorkingFolder = async (idCustomer: number, idWorkingFolder: number, data: any) => {

  try {
    const response = await axiosWithToken().post(`api/customer/${idCustomer}/workingfolder/${idWorkingFolder}/users/unassign`, data);
    return response;
  } catch (error) {
    console.log(error);
    throw error;
  }
}

export const updateRequestState = async (idCustomer: number, idMission: number, data: any) => {
  try {
    const response = await axiosWithToken().patch(`api/customer/${idCustomer}/mission/${idMission}/states`, data);
    return response;
  } catch (error) {
    console.log(error);
    throw error;
  }
}

export const downloadMissionLetter = (idCustomer: number, idMission: number) => {
  try {
    const response = axiosWithTokenForBlob().get(`api/customer/${idCustomer}/mission/${idMission}/file`, {
      responseType: 'blob'
    });
    return response;
  } catch (error) {
    console.log(error);
    throw error;
  }
};

export const getUserAssignToDocRequest = async (idCustomer: number, idMission: number, idUser: number) => {
  try {
    const response = await axiosWithToken().get(`api/customer/${idCustomer}/mission/${idMission}/user/${idUser}/docrequests`);
    return response.data;
  } catch (error) {
    console.log(error);
    throw error;
  }
}

export const assignUserToDocRequest = async (idCustomer: number, idMission: number, idUser: number, assignDC: any) => {
  const data = {"docRequestIds": assignDC}
  try {
    const response = await axiosWithToken().post(`api/customer/${idCustomer}/mission/${idMission}/user/${idUser}/docrequests`, data);
    return response;
  } catch (error) {
    console.log(error);
    throw error;
  }
}