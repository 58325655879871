import { useParams } from "react-router-dom";
import { usePartnerMissionStore } from "../../../../stores/Cac/Partner/partnerMission";
import { useUtilsStore } from "../../../../stores/Utils/selects";
import { useEffect } from "react";
import { getPeriods } from "../../../../services/Api/periodsType";

const SelectPeriodMission = () => {
  const { choiceModal } = usePartnerMissionStore();
  const { periods, setPeriods, selectedPeriod, setSelectedPeriod } =
    useUtilsStore();
  const { idPartner } = useParams();
  const { dataMission, setDataMission } = usePartnerMissionStore();

  useEffect(() => {
    if (idPartner) {
      getPeriods()
        .then((data) => setPeriods(data))
        .catch((error) => console.log(error));
    }
  }, [choiceModal]);

  return (
    <select
      name="services"
      id="services"
      onChange={(e) => {
        setSelectedPeriod(parseInt(e.target.value));
        setDataMission({
          ...dataMission,
          periodType: parseInt(e.target.value),
        });
      }}
      value={selectedPeriod}
      className="w-1/4 h-10 p-1 border-[1px] border-gray-300 rounded-md mr-2 shadow-md"
    >
      {selectedPeriod === 0 && (
        <option value="" className="text-slate-300">
          Choisir une période
        </option>
      )}
      {periods?.map((period) => (
        <option value={period?.id} key={period?.id}>
          {period?.name}
        </option>
      ))}
    </select>
  );
};

export default SelectPeriodMission;
