import { nameFileWithoutPath } from "../Utils/functionService";
import { axiosWithToken, axiosWithTokenForFile } from "./axiosService";

export const isValidToken = async ( token: string ) => {
    try {
        const response = await axiosWithToken().post(`api/workingFolder/checkToken?token=${token}`);
        return response
    } catch (error) {
        console.log(error);
        throw error;
    }
};

export const getFileData = async ( token: string, password: string ) => {
    try {
        const response = await axiosWithToken().get(`api/workingFolder?token=${token}&password=${password}`);
        return response.data;
    } catch (error) {
        console.log(error);
        throw error;
    }
}

export const getFile = async (token: string, password: string, fileName: string) => {
    try {
      const response = await axiosWithTokenForFile().get(
        `api/workingFolder/zip?token=${token}&password=${password}`,
        {
          responseType: 'blob',
        }
      );
  
      if (response.status === 200) {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        // Set the download attribute with a filename

        link.setAttribute('download', nameFileWithoutPath(fileName));
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      } else {
        console.error(`Error: Received status code ${response.status}`);
      }
    } catch (error) {
      console.error('Error downloading the file:', error);
      throw error;
    }
  };