import { create } from "zustand";

type LoaderFileState = {
  isLoading: boolean;
  setIsLoading: (isLoading: boolean) => void;

  percent: number;
  setPercent: (percent: number) => void;
};

export const useLoaderFile = create<LoaderFileState>((set) => ({
  isLoading: false,
  setIsLoading: (isLoading) => set({ isLoading }),

  percent: 0,
  setPercent: (percent) => set({ percent }),
}));