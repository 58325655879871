import React from "react";
import Logo from "../../assets/logos/logoNexum.png";

const Error: React.FC = () => {
  return (
    <div className="w-screen h-screen">
      <div className="h-2/4 bg-white relative flex justify-center">
        <img
          className="absolute w-36 h-36 left-5 top-5"
          src={Logo}
          alt="logo NEXUM"
        />
        <h2 className="text-9xl font-extrabold absolute bottom-[-15px] text-nxm-lightBlue">
          404
        </h2>
      </div>
      <div className="h-2/4 bg-nxm-lightBlue">
        <p className="text-center pt-10 text-white text-6xl font-bold">
          Désolé, page introuvable
        </p>
      </div>
    </div>
  );
};

export default Error;
