import { useEffect } from "react";
import { usePartnerWorkingFolderStore } from "../../../../stores/Cac/Partner/partnerWorkingFolder";
import { useParams } from "react-router-dom";
import { useTokenContext } from "../../../../context/tokenContext";
import {
  createWorkingFolder,
  getPartnerAssociated,
} from "../../../../services/Api/customerService";
import { IUserManagerMission } from "../../../../interfaces/user";
import { useUtilsStore } from "../../../../stores/Utils/selects";
import {
  showToastError,
  showToastSuccess,
} from "../../../../services/Toasts/toasts";
import SelectManager from "../Utils/SelectManager";
import SelectServiceMission from "../Utils/SelectServiceMission";
import SelectYear from "../Utils/SelectYear";
import { verifyEmail } from "../../../../services/Utils/functionService";
import { usePartnerMissionStore } from "../../../../stores/Cac/Partner/partnerMission";

const CreateWorkingFolder = () => {
  const {
    choiceModalWF,
    setChoiceModalWF,
    collabsAssignedToClient,
    setCollabsAssignedToClient,
    checkboxValuesCollabs,
    setCheckboxValuesCollabs,
    checkboxValuesClients,
    setCheckboxValuesClients,
    dataWorkingFolder,
    resetWorkingFolder,
    mails,
    setMails,
    email,
    setEmail,
  } = usePartnerWorkingFolderStore();
  const { idCustomer } = useParams();
  const { userId } = useTokenContext();
  const { resetSelects, selectedManager } = useUtilsStore();
  const { updateMission, setUpdateMission } = usePartnerMissionStore();

  const handleResetAll = () => {
    resetWorkingFolder();
    resetSelects();
    setChoiceModalWF("");
    setCheckboxValuesClients({});
    setMails([]);
    setCheckboxValuesCollabs({});
  };

  // Function to get collaborateurs assign to a client
  useEffect(() => {
    if (idCustomer)
      // This is for collabs
      getPartnerAssociated(Number(idCustomer))
        .then((data) => {
          // Supprimer le manager de la liste
          const filteredData = data.filter(
            (collab: any) => collab.id !== selectedManager
          );

          const mappedData: { [key: number]: IUserManagerMission } = {};

          filteredData.forEach((assignUser: IUserManagerMission) => {
            mappedData[assignUser.id] = assignUser;
          });

          if (choiceModalWF === "create") {
            const checkboxValues: { [key: number]: boolean } = {};
            Object.values(mappedData).forEach(
              (assignUser: IUserManagerMission) => {
                if (assignUser.id === userId) {
                  checkboxValues[assignUser.id] = true;
                } else {
                  checkboxValues[assignUser.id] = false;
                }
              }
            );

            setCollabsAssignedToClient(Object.values(mappedData));
            setCheckboxValuesCollabs(checkboxValues);
          }
        })
        .catch((error) => console.log(error));
  }, [idCustomer, selectedManager, choiceModalWF]);

  const handleCheckboxChangeCollabs = (value: number, checked: boolean) => {
    setCheckboxValuesCollabs({ ...checkboxValuesCollabs, [value]: checked });
  };

  const handleCheckboxChangeClients = (value: string, checked: boolean) => {
    setCheckboxValuesClients((prevValues) => ({
      ...prevValues,
      [value]: checked,
    }));
  };

  const handleCreateOrUpdateMission = async () => {
    if (dataWorkingFolder.dateExercice === null) {
      showToastError("Veuillez renseigner une date d'exercice");
      return;
    } else if (dataWorkingFolder.categoryId === 0) {
      showToastError("Veuillez renseigner une catégorie de mission");
      return;
    } else if (dataWorkingFolder.managerId === 0) {
      showToastError("Veuillez sélectionner un responsable");
      return;
    }

    try {
      if (idCustomer !== "0") {
        let dataForSend;
        dataForSend = {
          idCategoryMission: dataWorkingFolder.categoryId,
          exercice: String(dataWorkingFolder.dateExercice),
          idManager: dataWorkingFolder.managerId,
          operatorIds: Object.entries(checkboxValuesCollabs)
            .filter(([_, value]) => value)
            .map(([key]) => Number(key))
            .join(","),
          emails: Object.entries(checkboxValuesClients)
            .filter(([_, value]) => value)
            .map(([key]) => key)
            .join(","),
        };

        let workingFolderResponse;

        // Création de la mission avec les utilisateurs assignés par le serveur
        workingFolderResponse = await createWorkingFolder(
          Number(idCustomer),
          dataForSend
        );

        if (workingFolderResponse) {
          handleResetAll();
          showToastSuccess("Le dossier client a bien été créé");
          setUpdateMission(!updateMission);
        } else {
          showToastError("Une erreur est survenue");
        }
      }
    } catch (error) {
      handleResetAll();
      showToastError("Une erreur est survenue");
    }
  };

  const handleCreateNewClient = () => {
    if (verifyEmail(email)) {
      mails.push(email);
      setEmail("");
      setCheckboxValuesClients((prevValues) => {
        const updatedValues = { ...prevValues };
        mails.forEach((user) => {
          if (!(user in updatedValues)) {
            updatedValues[user] = true;
          }
        });
        return updatedValues;
      });
    }
  };

  return (
    choiceModalWF !== "" && (
      <>
        <div className="justify-center items-center flex overflow-x-hidden fixed inset-0 z-50 outline-none focus:outline-none h-screen font-BarlowCondensed ">
          <div className="relative w-auto md:w-1/4 my-6 mx-auto max-w-3xl min-w-[90%] 2xl:min-w-[55%]">
            {/*content*/}
            <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
              {/*header*/}
              <div className="flex items-center justify-center p-4 border-b border-solid border-slate-200 rounded-t">
                <h3 className="text-xl sm:text-3xl font-semibold font-K2D text-center text-nxm-darkGray">
                  Création d'un dossier travail
                </h3>
              </div>
              {/*body*/}
              <div className="w-full m-auto flex flex-col items-center justify-start bg-nxm-lightBlue bg-opacity-20 overflow-y-auto">
                {/* Part for create a mission */}
                <div className="flex flex-col justify-center items-center w-10/12 mt-4 m-auto">
                  <div className="flex justify-center items-center w-full text-lg">
                    <SelectManager />
                    <SelectServiceMission />
                    <SelectYear />
                  </div>
                </div>

                <hr className="my-4 border-[1px] w-1/2" />

                {/* Part for collabs */}
                <div className="text-md flex w-11/12 m-auto h-auto ">
                  <div className="w-1/3 flex flex-col items-center justify-start mb-2 text-xs sm:text-base">
                    <p className="text-lg mb-4">Collaborateurs affectés :</p>

                    <div className="w-full bg-white border-[1px] rounded-md m-auto h-64 overflow-y-scroll">
                      {collabsAssignedToClient?.map((user) => (
                        <div
                          key={user?.id}
                          className="flex flex-col items-start justify-start text-lg  border-b-[1px] border-slate-200 "
                        >
                          <div className="flex justify-center items-center p-4 ">
                            <input
                              type="checkbox"
                              checked={checkboxValuesCollabs[user?.id] || false}
                              onChange={() => {
                                handleCheckboxChangeCollabs(
                                  user?.id,
                                  !checkboxValuesCollabs[user?.id]
                                );
                              }}
                              className="mr-2 "
                            />
                            <label>
                              {user?.firstName} {user?.lastName}
                            </label>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                  <hr className="border-[1px] w-20 rotate-90 m-auto" />
                  <div className="w-1/3 flex flex-col items-center justify-start mb-2 text-lg">
                    <p className="text-lg mb-4">Création client :</p>

                    <div className="w-full bg-white border-[1px] rounded-md m-auto h-64 overflow-y-scroll relative">
                      <div className="w-full">
                        <div className="w-full flex flex-col justify-start items-center">
                          <input
                            type="email"
                            className="border-b-[1px] border-nxm-darkGray w-2/3 mt-5 focus:outline-none"
                            minLength={10}
                            maxLength={200}
                            value={email}
                            placeholder="Email"
                            onChange={(e) => setEmail(e.target.value)}
                            required
                          />
                        </div>
                        <div className="w-full flex justify-end items-center">
                          <button
                            onClick={handleCreateNewClient}
                            className=" absolute bottom-4 right-5 text-white bg-gradient-to-r from-teal-400 via-teal-500 to-teal-600 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-teal-300 dark:focus:ring-teal-800 font-medium rounded-lg text-sm px-5 py-1 text-center me-2 mb-2 uppercase  shadow hover:shadow-lg outline-none  ease-linear transition-all duration-150"
                          >
                            <p>Ajouter</p>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <hr className="border-[1px] w-20 rotate-90 m-auto" />
                  <div className="w-1/3 flex flex-col items-center justify-start  mb-2 text-xs sm:text-base">
                    <div className=" flex justify-center items-center mb-2">
                      <p className="text-lg mr-4 ">Clients affectés :</p>
                    </div>

                    <div className="w-full bg-white border-[1px] rounded-md m-auto h-64 overflow-y-scroll">
                      {mails?.map((user, index) => (
                        <div
                          key={index}
                          className=" flex flex-col items-start justify-start text-lg border-b-[1px] border-slate-200 "
                        >
                          <div className="flex justify-center items-center p-4 ">
                            <input
                              type="checkbox"
                              checked={checkboxValuesClients[user] ?? true}
                              onChange={() => {
                                handleCheckboxChangeClients(
                                  user,
                                  !checkboxValuesClients[user]
                                );
                              }}
                              className="mr-2 "
                            />
                            <p>
                              <span className="italic font-thin ml-4">
                                {user}
                              </span>
                            </p>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </div>

              {/*footer*/}
              <div className="flex items-center justify-end p-4 border-t border-solid border-slate-200 rounded-b">
                <button
                  className="text-red-500 background-transparent font-bold uppercase px-6 py-2 text-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                  type="button"
                  onClick={() => handleResetAll()}
                >
                  Annuler
                </button>
                <button
                  className="text-white bg-gradient-to-r from-teal-400 via-teal-500 to-teal-600 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-teal-300 dark:focus:ring-teal-800 font-medium rounded-lg text-lg px-5 py-2.5 text-center me-2 mb-2 uppercase  shadow hover:shadow-lg outline-none  ease-linear transition-all duration-150"
                  type="button"
                  onClick={handleCreateOrUpdateMission}
                >
                  Créer
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
      </>
    )
  );
};

export default CreateWorkingFolder;
