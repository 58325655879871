import { useEffect, useState } from "react";
import { getAllManagers } from "../../../services/Api/adminService";
import Down from "../../../assets/icons/chevron-down.svg";
import { useParams } from "react-router-dom";

export const SelectorResponsable = ({
  id,
  setConvertResponsable,
  fullName,
}: {
  id: any;
  setConvertResponsable: any;
  fullName: any;
}) => {
  const { idPartner } = useParams();
  const [inputValue, setInputValue] = useState("");
  const [open, setOpen] = useState(false);
  const [responsables, setResponsables] = useState([]);
  const [selectedResponsable, setSelectedResponsable] = useState("");

  useEffect(() => {
    getAllManagers(Number(idPartner))
      .then((data) => setResponsables(data))
      .catch((error) => console.log(error));
  }, []);

  return (
    <div className="w-36 sm:w-60 font-medium h-auto flex flex-col relative">
      <div
        onClick={() => setOpen(!open)}
        className={`bg-white w-36 sm:w-60 h-[28px] flex items-center justify-between rounded border-[1px] border-gray-300 z-10 pl-1 ${
          !selectedResponsable && "text-gray-700 "
        }`}
      >
        {selectedResponsable
          ? selectedResponsable?.length > 25
            ? selectedResponsable?.substring(0, 25) + "..."
            : selectedResponsable
          : `Responsable`}
        <img src={Down} alt="down" className={`${open && "rotate-180"}`} />
      </div>
      {
        <ul
          className={`bg-white overflow-y-auto absolute  ${
            open ? "max-h-32 w-36 sm:w-60 z-50 top-[30px]" : "max-h-0"
          } z-10`}
        >
          {responsables?.map((responsable: any, index: number) => (
            <li
              key={responsable?.id || index}
              className={`p-2 text-sm hover:bg-sky-600 hover:text-white`}
              onClick={() => {
                setSelectedResponsable(responsable?.fullName);
                setConvertResponsable(responsable?.id);
                setOpen(false);
                setInputValue("");
              }}
            >
              {responsable?.fullName}
            </li>
          ))}
        </ul>
      }
    </div>
  );
};
